import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { SORT_ASC, SORT_DESC } from 'constants/common';
import { COLUMN_TICKET_ID, PERIOD_TICKET_ANY, STATE_TICKET_ANY } from 'constants/support';
import { defaultRowsPerPage } from 'constants/table';
import { getTicketDetails, getTickets } from 'store/actions/ticket';
import { IPagination, SortDirection } from 'types/table';
import { Ticket, TicketDataStateType, TicketFilters, TicketResponse } from 'types/ticket';

export const initialState: TicketDataStateType = {
  tickets: null,
  ticketsLoading: false,
  filters: { company_id: 0, period: PERIOD_TICKET_ANY, stage: STATE_TICKET_ANY },
  pagination: { rowsPerPage: defaultRowsPerPage, page: 0 },
  sort: { sort_column: COLUMN_TICKET_ID, sort_direction: SORT_DESC },
  ticketDetails: null,
  ticketLoading: false,
};

export const ticketDataSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getTickets.fulfilled, (state: TicketDataStateType, action: PayloadAction<TicketResponse>) => ({
      ...state,
      tickets: action.payload,
      ticketsLoading: false,
    }));
    builder.addCase(getTickets.pending, (state: TicketDataStateType) => ({
      ...state,
      tickets: null,
      ticketsLoading: true,
    }));
    builder.addCase(getTicketDetails.fulfilled, (state: TicketDataStateType, action: PayloadAction<Ticket>) => ({
      ...state,
      ticketDetails: action.payload,
      ticketLoading: false,
    }));
    builder.addCase(getTicketDetails.pending, (state: TicketDataStateType) => ({
      ...state,
      ticketLoading: true,
    }));
    builder.addCase(getTicketDetails.rejected, (state: TicketDataStateType) => ({
      ...state,
      ticketLoading: false,
    }));
  },
  initialState,
  name: '@ticket',
  reducers: {
    setFilters: (state: TicketDataStateType, action: PayloadAction<TicketFilters>) => ({
      ...state,
      filters: action.payload,
    }),
    resetFilters: (state: TicketDataStateType) => ({ ...state, filters: initialState.filters }),
    setPagination: (state: TicketDataStateType, action: PayloadAction<IPagination>) => ({
      ...state,
      pagination: action.payload,
    }),
    updateSort: (state: TicketDataStateType, action: PayloadAction<string>) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const sort_direction = (
        state.sort.sort_column === action.payload && state.sort.sort_direction === SORT_ASC ? SORT_DESC : SORT_ASC
      ) as SortDirection;
      return { ...state, sort: { sort_column: action.payload, sort_direction } };
    },
  },
});
