import { combineReducers } from '@reduxjs/toolkit';

import { authSlice } from './auth/auth';
import { billSlice } from './bill/bill';
import { breadcrumbSlice } from './breadcrumb/breadcrumb';
import { cartSlice } from './cart/cart';
import { categorySlice } from './category/category';
import { commentSlice } from './comment/comment';
import { dispatchSlice } from './dispatch/dispatch';
import { orderSlice } from './order/order';
import { productSlice } from './product/product';
import { ticketDataSlice } from './ticket/ticket';
import { verifySlice } from './verify/verify';

export const rootReducer = combineReducers({
  auth: authSlice.reducer,
  breadcrumb: breadcrumbSlice.reducer,
  cart: cartSlice.reducer,
  category: categorySlice.reducer,
  comment: commentSlice.reducer,
  order: orderSlice.reducer,
  product: productSlice.reducer,
  ticketData: ticketDataSlice.reducer,
  verifyToken: verifySlice.reducer,
  bill: billSlice.reducer,
  dispatch: dispatchSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
