import React from 'react';

import { Container, List, ListItem, ListItemText } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Field, Form } from 'react-final-form';

import styles from './PasswordConfirmationForm.module.less';

import { PasswordField } from 'components/common/uncontrolledComponents/PasswordField';
import { trimFormatter } from 'services/formatters';
import { IPasswordForm } from 'types/auth';
import { composeValidators, passwordValidator, requiredValidator, validatePasswordsForm } from 'validators';
import { TEXT_FIELD_NORMAL_MAX_LENGTH } from 'validators/constants';

interface IPasswordConfirmationProps {
  handleFormSubmit: (formValues: IPasswordForm) => Promise<void>;
  title: string;
  buttonText: string;
  email: string;
}

export const PasswordConfirmationForm: React.FC<IPasswordConfirmationProps> = ({
  handleFormSubmit,
  title,
  buttonText,
  email,
}) => {
  const firstPasswordRequirements = ['One lowercase character', 'One uppercase character', 'One number'];
  const secondPasswordRequirements = ['One special character', '8 characters minimum', `Not equal to email (${email})`];

  return (
    <div className={styles.authPageContainer}>
      <div className={styles.authPageContent}>
        <div className={styles.authPageTitle}>{title}</div>
        <Form onSubmit={handleFormSubmit} className={styles.authForm} validate={validatePasswordsForm}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Field
                id="new_password1"
                name="new_password1"
                label="Password"
                component={PasswordField}
                maxLength={TEXT_FIELD_NORMAL_MAX_LENGTH}
                formatOnBlur
                format={trimFormatter}
                validate={composeValidators(requiredValidator('Password'), passwordValidator(email))}
              />
              <Container style={{ marginBottom: '32px', display: 'flex' }}>
                <List style={{ width: '50%' }} disablePadding dense>
                  {firstPasswordRequirements.map((item) => (
                    <ListItem key={item}>
                      <ListItemText style={{ margin: 0 }} disableTypography>
                        <span className={styles.tinyText}>
                          <span style={{ fontSize: '16px' }}>● </span>
                          {item}
                        </span>
                      </ListItemText>
                    </ListItem>
                  ))}
                </List>
                <List style={{ width: '50%' }} disablePadding dense>
                  {secondPasswordRequirements.map((item) => (
                    <ListItem key={item}>
                      <ListItemText style={{ margin: 0 }} disableTypography>
                        <span className={styles.tinyText}>
                          <span style={{ fontSize: '16px' }}>● </span>
                          {item}
                        </span>
                      </ListItemText>
                    </ListItem>
                  ))}
                </List>
              </Container>
              <Field
                id="new_password2"
                name="new_password2"
                label="Confirm Password"
                component={PasswordField}
                maxLength={TEXT_FIELD_NORMAL_MAX_LENGTH}
                formatOnBlur
                format={trimFormatter}
                validate={composeValidators(requiredValidator('Password'))}
              />
              <div className={styles.actions}>
                <Button color="primary" variant="contained" type="submit" className={styles.submitButton}>
                  {buttonText}
                </Button>
              </div>
            </form>
          )}
        </Form>
      </div>
    </div>
  );
};
