import { createAsyncThunk } from '@reduxjs/toolkit';

import { categoryApi } from 'api/categoryApi';

export const getCategories = createAsyncThunk('@category/getCategories', async () => {
  const { data } = await categoryApi.getCategories();
  return data;
});

export const getCategory = createAsyncThunk('@category/getCategory', async (id: string) => {
  const { data } = await categoryApi.getCategory(id);
  return data;
});
