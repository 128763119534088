export const AUTH_BASE_ROUTE = '/auth';
export const AUTH_SUCCESS_INDEX_ROUTE = '/success';

export const ROUTER_PATHS = {
  index: '/',
  signIn: `${AUTH_BASE_ROUTE}/sign-in`,
  signUp: `${AUTH_BASE_ROUTE}/sign-up`,
  signUpSuccess: `${AUTH_BASE_ROUTE}/sign-up/success`,
  forgotPassword: `${AUTH_BASE_ROUTE}/forgot-password`,
  forgotPasswordSuccess: `${AUTH_BASE_ROUTE}/forgot-password/success`,
  aboutUs: `/about`,
  partnership: `/partnership`,
  partners: `/partners`,
  terms: `/terms`,
  privacy: `/privacy`,
  cookies: `/cookies`,
  profile: `/profile`,
  shopByProduct: `/shop/`,
  category: `/shop/category/:id`,
  orders: `/orders`,
  orderDetails: `/order/details/:id`,
  acceptAndSign: `/order/:id/accept/sign`,
  bills: `/bills`,
  dispatchRoot: `/dispatch`,
  supportTickets: `/support`,
  supportTicketDetailsPage: `/support/:id`,
  productPage: `/shop/product/:id`,
  newQuotePage: `/shop/quote/`,
  addToCartPage: `/shop/buy/`,
  createPasswordSuccess: `${AUTH_BASE_ROUTE}/invite/success`,
  resetPasswordSuccess: `${AUTH_BASE_ROUTE}/reset/success`,
  createPassword: `/invite/confirm/:uid/:token`,
  resetPassword: `/reset/confirm/:uid/:token`,
  mainPage: `/main`,
  shoppingCart: `/order/cart`,
  notFoundPage: '/404',
};
