import { AxiosResponse } from 'axios';

import { privateApi } from 'api/api';
import { ICartResponse } from 'types/cart';

const CartApi = {
  getCart: (): Promise<AxiosResponse<ICartResponse[]>> => privateApi.get('/cart', {}),

  cartSubmit: (): Promise<AxiosResponse> => privateApi.post('/cart', {}),

  cartDelete: (): Promise<AxiosResponse> => privateApi.delete('/cart'),

  cartUpdate: (location: number, data: any): Promise<AxiosResponse<ICartResponse[]>> =>
    privateApi.patch(`/cart/location/${location}`, data),

  cartDeleteItem: (id: number): Promise<AxiosResponse<ICartResponse[]>> => privateApi.delete(`/cart/location/${id}`),
};

export default CartApi;
