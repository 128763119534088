import { AxiosResponse } from 'axios';

import { privateApi } from 'api/api';
import { IAddress, IPriceAddress, IPriceRequest, IProductTemplate } from 'types/product';

const ProductApi = {
  getProduct: (product_id: number): Promise<AxiosResponse<IProductTemplate>> =>
    privateApi.get(`/product/${product_id}`),

  searchAddress: (search: string): Promise<AxiosResponse<IAddress[]>> =>
    privateApi.get('/accounts/typeahead/', { params: { search } }),

  newPriceRequest: (
    variantId: number,
    extraValues: number[],
    durationTermValue: number,
    locations: IPriceAddress[],
  ): Promise<AxiosResponse<IPriceRequest>> => {
    const payload: any = {
      locations,
      variant_id: variantId,
      duration_term: durationTermValue,
    };
    if (extraValues.length > 0) {
      payload.extra_values = extraValues;
    }
    return privateApi.post('/price/', payload, { timeout: 10000 });
  },

  getPriceRequest: (request_id: number): Promise<AxiosResponse<IPriceRequest>> =>
    privateApi.get(`/price/${request_id}/`),

  addToCart: (request_id: number): Promise<AxiosResponse> => privateApi.patch(`/cart/add/${request_id}`),
};

export default ProductApi;
