import React, { FC, useEffect } from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import InnerHTML from 'dangerously-set-html-content';
import { withWhiteLabelContext } from 'react-whitelabel';

import { getPortalData } from './store/actions/auth';
import { useAppSelector } from './store/store';

import { Navigation } from 'navigation';
import { useAppDispatch } from 'store';

const App: FC<{ label: any }> = (props) => {
  const dispatch = useAppDispatch();
  const { label } = props;

  const { portalData, portalDataLoading } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (!portalData && !portalDataLoading) dispatch(getPortalData());
  }, [dispatch, portalData, portalDataLoading]);

  return (
    <ThemeProvider theme={label.applicationTheme}>
      <CssBaseline />
      <Navigation />
      {portalData && <InnerHTML html={portalData.live_chat_block} />}
    </ThemeProvider>
  );
};

export default withWhiteLabelContext(App);
