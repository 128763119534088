import { CookieService } from './cookieService';

import { authApi } from 'api/authApi';
import { ACCESS_TOKEN_KEY } from 'constants/common';
import storeRegistry from 'storeRegistry';
import { AccessTokenResponse } from 'types/auth';

export class AuthService {
  static updateAccessToken({ access, access_token_expiration }: AccessTokenResponse): void {
    CookieService.setCookie(ACCESS_TOKEN_KEY, access, {
      expires: access_token_expiration,
      path: '/',
    });
  }

  static getAccessToken(): string | undefined {
    const accessToken = CookieService.getCookie(ACCESS_TOKEN_KEY);
    return accessToken && accessToken.length > 0 ? accessToken : undefined;
  }

  static getBearer(token: string): string {
    return `Bearer ${token}`;
  }

  static deleteAccessToken(): void {
    CookieService.deleteCookie(ACCESS_TOKEN_KEY);
    storeRegistry.getStore()?.dispatch({ type: '@auth/clearAuth' });
  }

  static async refreshTokens(): Promise<any> {
    const { data } = await authApi.refreshToken();

    AuthService.updateAccessToken(data);
  }

  static async logout(): Promise<any> {
    CookieService.deleteCookie(ACCESS_TOKEN_KEY);
    await authApi.logout();
  }
}
