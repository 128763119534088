import React from 'react';

import { SelectField } from '../controlledComponents/SelectField';

import Placeholder from 'components/common/Placeholder/Placeholder';
import { DEFAULT_COMPANY_KEY } from 'constants/common';
import { useAppSelector } from 'store/store';

interface ICompanyFieldProps {
  value: number;
  onChange: (value: number) => void;
}

export const CompanyField: React.FC<ICompanyFieldProps> = ({ value, onChange }) => {
  const { user } = useAppSelector((state) => state.auth);

  const companies = (user && user.companies) || [];
  const companyOptions = [
    { key: DEFAULT_COMPANY_KEY, label: 'Any Company' },
    ...companies.map((company) => ({ key: company[0], label: company[1] })),
  ];

  return (
    <SelectField
      value={value}
      options={companyOptions}
      onChange={onChange}
      renderValue={value !== DEFAULT_COMPANY_KEY ? undefined : () => <Placeholder>Company</Placeholder>}
    />
  );
};
