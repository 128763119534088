import React, { FC, useEffect } from 'react';

import { Box, Button, Card, CardContent, Divider, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CommentIcon from '@material-ui/icons/Comment';
import Pagination from '@material-ui/lab/Pagination';

import { getComments, sendComment } from '../../../store/actions/comment';
import { useAppSelector } from '../../../store/store';

import Spinner from 'components/common/Spinner/Spinner';
import { AttachUpload } from 'components/index';
import { colors } from 'constants/ui';
import styles from 'pages/orders/OrderDetailPage/OrderDetailsPage.module.less';
import { useAppDispatch } from 'store';
import { IComment, NewCommentApiData } from 'types/comment';
import { IAttachFile } from 'types/common';
import { getDate } from 'utils/utils';

const useStyles = makeStyles({
  userName: {
    fontSize: '16px',
    lineHeight: '28px',
    fontWeight: 700,
    paddingRight: '5px',
  },
  companyName: {
    fontSize: '16px',
    lineHeight: '28px',
    fontWeight: 500,
  },
  publishedDate: {
    fontSize: '12px',
    lineHeight: '20px',
    fontWeight: 500,
    color: colors.MIDDLE_GREY,
  },
  commentText: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 500,
    wordBreak: 'break-all',
  },
  noCommentsText: {
    fontSize: '16px',
    lineHeight: '28px',
    fontWeight: 500,
    color: colors.MIDDLE_GREY,
    textAlign: 'center',
  },
  paginator: {
    width: 'max-content',
    margin: 'auto',
  },
  attachment: {
    marginTop: '8px',
    marginRight: '8px',
    height: '48px',
    backgroundColor: colors.WHITE_GREY,
  },
  sendButton: {
    marginTop: '16px',
  },
  commentTextField: {
    marginBottom: '12px',
  },
});

export const CommentsDetails: FC<{
  id: string;
  path: string;
}> = ({ id, path }) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { commentsPage, commentsLoading, selectedComments } = useAppSelector((state) => state.comment);
  const [commentsCount, setCommentsCount] = React.useState(0);
  const [comments, setComments] = React.useState<IComment[] | null>(null);
  const [page, setPage] = React.useState(1);
  const [commentText, setCommentText] = React.useState('');
  const [attachments, setAttachments] = React.useState<IAttachFile[]>([]);

  useEffect(() => {
    dispatch(getComments({ path, id, page }));
  }, [dispatch, path, id, page]);

  useEffect(() => {
    if (selectedComments && !commentsLoading) {
      setComments(selectedComments.comments);
      setCommentsCount(selectedComments.count);
    }
  }, [dispatch, commentsLoading, commentsPage, selectedComments]);

  if (commentsLoading && !comments) return <Spinner />;

  const changeCommentPage = (event: unknown, value: number) => {
    setPage(value);
  };

  const changeCommentText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCommentText(event.target.value);
  };

  async function sendNewComment() {
    if (attachments.some((attach) => !attach.isValid) || (attachments.length === 0 && !commentText)) return;
    const data: NewCommentApiData = { path, id, text: commentText, attachments };
    await dispatch(sendComment(data)).then(() => {
      setCommentText('');
      setAttachments([]);
      dispatch(getComments({ path, id, page }));
    });
  }

  return (
    <Grid item xs={12}>
      <Card>
        <CardContent style={{ padding: '25px' }}>
          <Box display="flex">
            <CommentIcon color="primary" style={{ marginRight: '15px' }} />
            <span className={styles.mediumTextBold}>Comments</span>
          </Box>
          <Divider />
          <div style={{ padding: '32px 24% 0' }}>
            <TextField
              multiline
              rows={4}
              value={commentText}
              id="outlined-basic"
              label="Write a comment"
              variant="outlined"
              onChange={changeCommentText}
              classes={{ root: classes.commentTextField }}
            />
            <AttachUpload attachments={attachments} setAttachments={setAttachments} minimalized />
            <Button classes={{ root: classes.sendButton }} variant="contained" color="primary" onClick={sendNewComment}>
              Send
            </Button>
            <Divider style={{ marginTop: '32px' }} />
            <Box paddingTop="24px">
              {!commentsLoading && comments && comments.length > 0 && (
                <>
                  {comments.map((comment) => (
                    <Box paddingBottom="36px" key={comment.date}>
                      <Box display="flex">
                        <Typography classes={{ root: classes.userName }}>{comment.author},</Typography>
                        <Typography classes={{ root: classes.companyName }}>{comment.company}</Typography>
                      </Box>
                      <Typography classes={{ root: classes.publishedDate }}>
                        Published on {getDate(comment.date, false)}
                      </Typography>
                      <Typography
                        classes={{ root: classes.commentText }}
                        dangerouslySetInnerHTML={{ __html: comment.body }}
                      />
                      {comment.attachments &&
                        comment.attachments.map((item) => (
                          <Button
                            classes={{ root: classes.attachment }}
                            key={item.id}
                            variant="outlined"
                            color="primary"
                            href={item.download_link}
                          >
                            <span className={styles.tinyMedium}>{item.name}</span>
                          </Button>
                        ))}
                    </Box>
                  ))}
                  {commentsCount > 5 && (
                    <Pagination
                      color="primary"
                      page={page}
                      count={Math.ceil(commentsCount / 5)}
                      onChange={changeCommentPage}
                      classes={{ root: classes.paginator }}
                    />
                  )}
                </>
              )}
              {!commentsLoading && comments && comments.length === 0 && (
                <Typography classes={{ root: classes.noCommentsText }}>There are no messages yet</Typography>
              )}
            </Box>
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
};
