import React, { useState } from 'react';

import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker } from '@material-ui/pickers';

import { SearchField } from 'components';
import { CompanyField } from 'components/common/CompanyField/CompanyField';
import { SelectField } from 'components/common/controlledComponents/SelectField';
import Placeholder from 'components/common/Placeholder/Placeholder';
import {
  PERIOD_BILL_ANY,
  PERIOD_BILL_CUSTOMER,
  PERIOD_BILL_FILTERS,
  PERIOD_BILL_LAST_MONTH,
  PERIOD_BILL_LAST_YEAR,
  STATE_BILL_FILTERS,
} from 'constants/bill';
import { useAppDispatch } from 'store';
import { billSlice } from 'store/reducers/bill/bill';
import { useAppSelector } from 'store/store';
import { IBillFilers } from 'types/bill';
import { getDateRangeString } from 'utils/utils';

const useStyles = makeStyles({
  filtersContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '12px 20px',
  },
  stageFilters: {
    marginLeft: '24px',
  },
  spacer: {
    marginLeft: '12px',
  },
  dialogTitle: {
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '28px',
  },
  dialogContent: {
    display: 'flex',
  },
});

export const BillFilters: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { filters } = useAppSelector((state) => state.bill);
  const [openDialog, setOpenDialog] = useState(false);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const updateFilter = (newFilter: IBillFilers) => {
    dispatch(billSlice.actions.setFilters({ ...filters, ...newFilter }));
  };

  const handlePeriodChange = (period: 'any' | 'last_month' | 'last_year' | 'customer') => {
    if (period === PERIOD_BILL_CUSTOMER) {
      setOpenDialog(true);
    } else {
      const newFilters: IBillFilers = { ...filters, to_date: undefined, from_date: undefined, period };
      const today = new Date();
      if (period === PERIOD_BILL_LAST_MONTH) {
        newFilters.to_date = today.toISOString();
        today.setMonth(today.getMonth() - 1);
        newFilters.from_date = today.toISOString();
      } else if (period === PERIOD_BILL_LAST_YEAR) {
        newFilters.to_date = today.toISOString();
        today.setFullYear(today.getFullYear() - 1);
        newFilters.from_date = today.toISOString();
      }
      updateFilter(newFilters);
      setFromDate(null);
      setToDate(null);
    }
  };

  const updateCustomRange = (periodFrom: Date | null, periodTo: Date | null) => {
    if (periodFrom) {
      periodFrom.setDate(1);
    }
    updateFilter({
      ...filters,
      period: PERIOD_BILL_CUSTOMER,
      from_date: (periodFrom && periodFrom.toISOString()) || undefined,
      to_date: (periodTo && new Date(periodTo.getFullYear(), periodTo.getMonth() + 1, 0).toISOString()) || undefined,
    });
    setOpenDialog(false);
  };

  const periodOptions = PERIOD_BILL_FILTERS.map((value) =>
    filters.period === PERIOD_BILL_CUSTOMER && value.key === PERIOD_BILL_CUSTOMER
      ? {
          ...value,
          label: getDateRangeString(filters.from_date, filters.to_date, true),
          onClick: () => setOpenDialog(true),
        }
      : value,
  );

  return (
    <>
      <div className={classes.filtersContainer}>
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
          <DialogTitle>Custom range</DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <KeyboardDatePicker
              variant="inline"
              openTo="year"
              views={['year', 'month']}
              label="From"
              value={fromDate}
              onChange={setFromDate}
            />
            <KeyboardDatePicker
              variant="inline"
              openTo="year"
              views={['year', 'month']}
              label="To"
              value={toDate}
              onChange={setToDate}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)} variant="contained" color="secondary">
              Cancel
            </Button>
            <Button onClick={() => updateCustomRange(fromDate, toDate)} variant="contained" color="primary">
              Apply
            </Button>
          </DialogActions>
        </Dialog>
        <SearchField value={filters.search} onChange={(value: string) => updateFilter({ ...filters, search: value })} />
        <div className={classes.spacer} />
        <CompanyField
          value={filters.company_id}
          onChange={(value: number) => updateFilter({ ...filters, company_id: value })}
        />
        <div className={classes.spacer} />
        <SelectField
          value={filters.period}
          options={periodOptions}
          onChange={handlePeriodChange}
          renderValue={filters.period !== PERIOD_BILL_ANY ? undefined : () => <Placeholder>Any Period</Placeholder>}
        />
        <div className={classes.stageFilters}>
          {STATE_BILL_FILTERS.map((item) => (
            <Chip
              color={item.key === filters.stage ? 'primary' : 'default'}
              key={item.key}
              label={item.label}
              onClick={() => updateFilter({ ...filters, stage: item.key })}
            />
          ))}
        </div>
      </div>
      <Divider />
    </>
  );
};
