import React from 'react';

import {
  Box,
  Collapse,
  IconButton,
  Table as MaterialTable,
  Paper,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableBody,
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { withWhiteLabelContext } from 'react-whitelabel';

import { Spinner } from 'components/index';
import { rowsPerPageOptions } from 'constants/table';
import { IPagination, ISort, ITableColumn } from 'types/table';

interface ITableProps<T extends { id: number }> {
  name: string;
  columns: ITableColumn<T>[];
  data: T[];
  loading: boolean;
  pagination?: IPagination;
  updatePagination: (data: { rowsPerPage?: number; page?: number }) => void;
  sort: ISort;
  updateSort: (column: string) => void;
  count: number;
  FilterComponent?: React.FC;
  renderExpand?: (item: T) => React.ReactNode;
  renderActions?: (item: T) => React.ReactNode;
  openItemIds?: Set<number>;
  openItemUpdate?: (set: Set<number>) => void;
  onRowClick?: (id: number) => void;
  label?: any;
}

const Table: React.FC<ITableProps<any>> = ({
  name,
  columns,
  data,
  loading,
  updatePagination,
  pagination,
  sort,
  updateSort,
  count,
  FilterComponent,
  renderExpand,
  renderActions,
  openItemIds,
  openItemUpdate,
  onRowClick,
  label,
}) => {
  const updateOpened = (id: number) => {
    if (openItemIds.has(id)) {
      openItemIds.delete(id);
      openItemUpdate(new Set(openItemIds));
    } else {
      openItemUpdate(new Set(openItemIds.add(id)));
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    updatePagination({ page: newPage });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    updatePagination({ rowsPerPage: +event.target.value, page: 0 });
  };

  const getFieldValue = (item: any, field: string) => {
    return item[field as keyof any];
  };

  let columnsCount = columns.length;
  if (renderExpand) {
    columnsCount += 1;
  }
  if (renderActions) {
    columnsCount += 1;
  }

  return (
    <>
      <TableContainer component={Paper}>
        {FilterComponent && <FilterComponent />}
        <MaterialTable>
          {(loading || data.length === 0) && (
            <TableBody>
              <TableRow>
                <TableCell>
                  <Box marginTop={1} textAlign="center">
                    {loading && <Spinner />}
                    {!loading && data.length === 0 && (
                      <>
                        <img src={label.noItemsLogo} alt="" />
                        <p>There are no {name} here yet</p>
                      </>
                    )}
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
          {!loading && data.length > 0 && (
            <TableHead>
              <TableRow>
                {renderExpand && <TableCell />}
                {columns.map((col) => (
                  <TableCell
                    key={col.field}
                    align={col.align}
                    onClick={col.isSortable ? () => updateSort(col.field) : undefined}
                  >
                    <Box display="flex">
                      {col.displayName}
                      {col.isSortable && col.field === sort.sort_column && sort.sort_direction === 'DESC' && (
                        <ArrowDownwardIcon />
                      )}
                      {col.isSortable && col.field === sort.sort_column && sort.sort_direction === 'ASC' && (
                        <ArrowUpwardIcon />
                      )}
                    </Box>
                  </TableCell>
                ))}
                {renderActions && <TableCell />}
              </TableRow>
            </TableHead>
          )}
          {!loading && data.length > 0 && (
            <TableBody>
              {data.map((item) => (
                <React.Fragment key={item.id}>
                  <TableRow
                    style={onRowClick ? { cursor: 'pointer' } : undefined}
                    onClick={onRowClick ? () => onRowClick(item.id) : undefined}
                    hover={Boolean(onRowClick)}
                  >
                    {renderExpand && (
                      <TableCell>
                        <IconButton aria-label="expand row" onClick={() => updateOpened(item.id)}>
                          {openItemIds.has(item.id) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                      </TableCell>
                    )}
                    {columns.map((col) => (
                      <TableCell key={col.field + item.id} align={col.align}>
                        {col.render ? col.render(item) : getFieldValue(item, col.field)}
                      </TableCell>
                    ))}
                    {renderActions && <TableCell>{renderActions(item)}</TableCell>}
                  </TableRow>
                  {renderExpand && (
                    <TableRow>
                      <TableCell style={{ padding: 0 }} colSpan={columnsCount}>
                        <Collapse in={openItemIds.has(item.id)} timeout="auto" unmountOnExit>
                          {renderExpand(item)}
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              ))}
            </TableBody>
          )}
        </MaterialTable>
      </TableContainer>
      {pagination && !loading && count > 0 && (
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component={Paper}
          count={count}
          rowsPerPage={pagination.rowsPerPage}
          page={pagination.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </>
  );
};

Table.defaultProps = {
  openItemIds: new Set(),
  openItemUpdate() {},
};

export default withWhiteLabelContext(Table);
