import React, { useEffect } from 'react';

import { Step, StepContent, StepLabel, Stepper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AttachMoney, LocationOn, Settings } from '@material-ui/icons';
import { Location } from 'history';

import { Contract } from './Contract';
import { ServiceLocations } from './ServiceLocation';
import { ServiceParameters } from './ServiceParameters';
import styles from './ShoppingWizard.module.less';
import { ShopStepIcon } from './ShopStepIcon';
import { StepButtons } from './StepButtons';
import { Summary } from './Summary';

import { RouteLeaving, Spinner } from 'components/index';
import { ROUTER_PATHS } from 'constants/routerPaths';
import { colors } from 'constants/ui';
import { useAppDispatch } from 'store';
import { fetchProduct } from 'store/actions/product';
import { productSlice } from 'store/reducers/product/product';
import { useAppSelector } from 'store/store';

export const useStyles = makeStyles({
  stepperRoot: {
    width: '1112px',
    borderRadius: '4px',
    marginTop: '28px',
    padding: '70px 56px 76px',
  },
  stepLabel: {
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '28px',
  },
  locationRoot: {
    color: colors.GREY,
  },
});

interface IShoppingWizardProps {
  productId: number;
}

export const ShoppingWizard: React.FC<IShoppingWizardProps> = ({ productId }) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(fetchProduct(productId));
  }, [productId, dispatch]);

  const { productTemplate, productVariant, locations, loading } = useAppSelector((state) => state.product);
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleBackFromPrices = () => {
    dispatch(productSlice.actions.resetPricing());
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  if (loading || !productTemplate) return <Spinner />;

  const skipPageLeaveConfirmation = (location: Location) => {
    // Skip confirmation for quote and cart pages
    return [ROUTER_PATHS.newQuotePage, ROUTER_PATHS.addToCartPage].indexOf(location.pathname) === -1;
  };

  return (
    <div>
      <RouteLeaving shouldBlockNavigation={skipPageLeaveConfirmation} />
      <div>
        <Typography variant="h1">{productTemplate.name}</Typography>
      </div>
      <Stepper classes={{ root: classes.stepperRoot }} activeStep={activeStep} orientation="vertical">
        {productTemplate.variants.length > 1 && (
          <Step>
            <StepLabel classes={{ root: classes.stepLabel }} StepIconComponent={ShopStepIcon} icon={<Settings />}>
              Select Parameters
              {productVariant && productTemplate.variants.length > 1 && activeStep > 0 && (
                <div className={styles.item}>
                  {productTemplate.attributes.map((attr) => (
                    <div key={attr.id} className={styles.locationData}>
                      <span className={`${styles.pricePrefix} ${styles.tinyText}`}>{attr.name}:</span>
                      <span className={`${styles.priceValue} ${styles.tinyText}`}>
                        {productVariant.values.find((v) => v.attribute_id === attr.id).name}
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </StepLabel>
            <StepContent>
              <ServiceParameters />
              <StepButtons
                activeStep={activeStep}
                onBackClick={handleBack}
                onNextClick={handleNext}
                nextDisabled={!productVariant}
              />
            </StepContent>
          </Step>
        )}
        <Step>
          <StepLabel StepIconComponent={ShopStepIcon} icon={<LocationOn />}>
            Select Service Location
            {((productTemplate.variants.length > 1 && activeStep === 2) ||
              (productTemplate.variants.length === 1 && activeStep === 1)) &&
              locations && (
                <div className={styles.item}>
                  {locations.map((location) => (
                    <div key={location.id} className={styles.locationData}>
                      <LocationOn classes={{ root: classes.locationRoot }} />
                      <span className={`${styles.address} ${styles.tinyText}`}>
                        {location.address.complete_address}
                      </span>
                      {!productTemplate.is_non_quantitative && (
                        <div className={`${styles.quantity} ${styles.textMedium}`}>{location.quantity}</div>
                      )}
                    </div>
                  ))}
                </div>
              )}
          </StepLabel>
          <StepContent>
            <ServiceLocations />
            <StepButtons
              activeStep={activeStep}
              onBackClick={handleBack}
              onNextClick={handleNext}
              nextDisabled={
                locations.filter(
                  (location) =>
                    !location.address ||
                    !location.quantity ||
                    location.quantity < 1 ||
                    !Number.isInteger(location.quantity),
                ).length > 0
              }
            />
          </StepContent>
        </Step>
        {productTemplate.duration_terms.length === 0 ? (
          <Step>
            <StepLabel StepIconComponent={ShopStepIcon} icon={<AttachMoney />}>
              Summary
            </StepLabel>
            <StepContent>
              <Summary />
              <StepButtons activeStep={activeStep} onBackClick={handleBackFromPrices} />
            </StepContent>
          </Step>
        ) : (
          <Step>
            <StepLabel StepIconComponent={ShopStepIcon} icon={<AttachMoney />}>
              Contract
            </StepLabel>
            <StepContent>
              <Contract />
              <StepButtons activeStep={activeStep} onBackClick={handleBackFromPrices} />
            </StepContent>
          </Step>
        )}
      </Stepper>
    </div>
  );
};
