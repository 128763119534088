import React from 'react';

import { StepIconProps, Theme, withStyles } from '@material-ui/core';
import { Done } from '@material-ui/icons';
import clsx from 'clsx';

import { colors } from 'constants/ui';

const styles = (theme: Theme) => ({
  root: {
    backgroundColor: colors.WHITE_GREY,
    zIndex: 0,
    width: 40,
    height: 40,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    color: colors.DARK_40,
    '&$completed': {
      color: colors.DARK,
    },
    '&$active': {
      color: colors.WHITE,
      backgroundColor: process.env.REACT_APP_WHITELABEL === 'cybernet' ? colors.BRIGHT_BLUE : colors.VIOLET,
    },
    '&$error': {
      color: theme.palette.error.main,
    },
  },
  active: {},
  completed: {},
  error: {},
});

const ShopStepIcon: React.FC<StepIconProps> = (props) => {
  const { completed = false, icon, active = false, error = false, classes } = props;

  const className = clsx(classes.root, {
    [classes.active]: active,
    [classes.error]: error,
    [classes.completed]: completed,
  });

  return <div className={className}>{completed ? <Done /> : icon}</div>;
};

export default withStyles(styles)(ShopStepIcon);
