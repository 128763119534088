import React, { FC } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { withWhiteLabelContext } from 'react-whitelabel';

const AuthLogo: FC<{ label: any }> = (props) => {
  const { label } = props;
  const { authLogo } = label;
  const useStyles = makeStyles({
    authLogo,
  });
  const classes = useStyles();

  return <div className={classes.authLogo} />;
};

export default withWhiteLabelContext(AuthLogo);
