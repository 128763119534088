// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".B6wGUsleD6m4uyXjpLw8 {\n  padding-top: 88px;\n  padding-bottom: 90px;\n  display: flex;\n  justify-content: center;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/shop/ProductPage/ProductPage.module.less"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,oBAAA;EACA,aAAA;EACA,uBAAA;AACF","sourcesContent":[".pageContainer {\n  padding-top: 88px;\n  padding-bottom: 90px;\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageContainer": "B6wGUsleD6m4uyXjpLw8"
};
export default ___CSS_LOADER_EXPORT___;
