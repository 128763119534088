import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { getCart } from 'store/actions/cart';
import { cartDeleteItem, cartUpdate } from 'store/actions/cart/cart';
import { CartStateType, ICartResponse } from 'types/cart';

export const initialState: CartStateType = {
  cartData: [],
  cartInvalid: false,
  updateQuantity: undefined,
  updateLocation: undefined,
};

export const cartSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getCart.fulfilled, (state: CartStateType, action: PayloadAction<ICartResponse[]>) => ({
      ...state,
      cartData: action.payload,
    }));
    builder.addCase(cartUpdate.pending, (state: CartStateType) => ({
      ...state,
      updateQuantity: undefined,
      updateLocation: undefined,
    }));
    builder.addCase(cartUpdate.fulfilled, (state: CartStateType, action: PayloadAction<ICartResponse[]>) => ({
      ...state,
      cartData: action.payload,
    }));
    builder.addCase(cartDeleteItem.fulfilled, (state: CartStateType, action: PayloadAction<ICartResponse[]>) => ({
      ...state,
      cartData: action.payload,
    }));
  },
  initialState,
  name: '@cart',
  reducers: {
    updateQuantity: (state: CartStateType, action: PayloadAction<{ locationId: number; quantity: number }>) => {
      let cartInvalid = false;
      let currentValid = true;
      const newCartData = state.cartData.map((cartProduct) => {
        const newLocations = cartProduct.locations.map((location) => {
          const newLocation =
            location.id === action.payload.locationId ? { ...location, quantity: action.payload.quantity } : location;
          if (location.quantity < 1 || location.quantity % 1 !== 0) {
            cartInvalid = true;
            if (location.id === action.payload.locationId) {
              currentValid = false;
            }
          }
          return newLocation;
        });
        return { ...cartProduct, locations: newLocations };
      });
      if (currentValid) {
        return {
          updateQuantity: action.payload.quantity,
          updateLocation: action.payload.locationId,
          cartData: newCartData,
          cartInvalid,
        };
      }
      return { ...state, cartData: newCartData, cartInvalid };
    },
  },
});
