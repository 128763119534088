export function getDate(sDate: string, offsetNeeded = true): string {
  const date = new Date(sDate);
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  };
  if (offsetNeeded) return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toLocaleString('en-US', options);
  return date.toLocaleString('en-US', options);
}

export function getNumberWithComma(value: number): string {
  const parts = value.toString().split('.');
  parts[0] = parts[0].replace(/\B(?<!\.\d)(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}

export function getTableDate(date: Date) {
  if (!date) return undefined;
  const options: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };
  const sTime = date.toLocaleString([], options);
  const outDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getUTCDate()} ${sTime}`;
  return outDate;
}

export function isValidDate(date: Date | string) {
  return date && date.toString() !== 'Invalid Date';
}

export function extractDateFromIso(date: string) {
  return date.slice(0, date.indexOf('T'));
}

export function extractTime(sDate: string): string {
  const date = new Date(sDate);
  return date.toLocaleTimeString([], { timeStyle: 'short' });
}

export function getDateRangeString(
  dateFrom: string | undefined,
  dateTo: string | undefined,
  skipDay: boolean = false,
): string {
  let result = '';
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  };
  if (skipDay) {
    options.day = undefined;
  }
  if (dateFrom) {
    const date1 = new Date(dateFrom);
    result = date1.toLocaleDateString('en-US', options);
  } else {
    result = '...';
  }
  if (dateTo) {
    const date2 = new Date(dateTo);
    result = `${result} - ${date2.toLocaleDateString('en-US', options)}`;
  } else {
    result = `${result} - ...`;
  }

  return result;
}

export function getValueByKeyInObject(obj: any, key: string): any {
  return obj[key];
}
