import React, { FC, SyntheticEvent, useCallback } from 'react';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { makeStyles } from '@material-ui/core/styles';
import InputMask from 'react-input-mask';

import { checkFieldError } from './helper';

import { ICommonInputFieldProps } from 'types/inputField';

interface ITextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

const PHONE_MASK = '+1 (999) 999-9999';

function TextMaskCustom(props: ITextMaskCustomProps) {
  const { inputRef, ...other } = props;

  return (
    <InputMask
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={PHONE_MASK}
    />
  );
}

const useStyles = makeStyles({
  inputRoot: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 500,
  },
  inputRootLabel: {
    fontSize: '12px',
    lineHeight: '20px',
    fontWeight: 500,
  },
});

export const PhoneNumberFiled: FC<ICommonInputFieldProps> = ({ input, meta, id, label, onChange, maxLength }) => {
  const isErrorField = checkFieldError(meta);

  const handleChange = useCallback(
    (event: SyntheticEvent) => {
      input.onChange(event);
      if (onChange) {
        onChange(event);
      }
    },
    [onChange, input],
  );
  const classes = useStyles();

  return (
    <FormControl variant="outlined">
      <InputLabel classes={{ root: classes.inputRootLabel }} htmlFor={id} className={isErrorField ? 'Mui-error' : ''}>
        {label}
      </InputLabel>
      <OutlinedInput
        {...input}
        id={id}
        label={label}
        error={isErrorField}
        onChange={handleChange}
        inputProps={{ maxLength }}
        inputComponent={TextMaskCustom as any}
        classes={{ root: classes.inputRoot }}
      />
      {isErrorField && (
        <FormHelperText id={`${id}-helper-text`} className="MuiFormHelperText-contained Mui-error">
          {meta.error}
        </FormHelperText>
      )}
    </FormControl>
  );
};
