import { AxiosResponse } from 'axios';

import { privateApi } from 'api/api';
import { ICategory } from 'types/category';

const CategoryApi = {
  getCategories: (): Promise<AxiosResponse<ICategory[]>> => privateApi.get('/category/', {}),

  getCategory: (id: string): Promise<AxiosResponse<ICategory>> => privateApi.get(`/category/${id}`, {}),
};

export default CategoryApi;
