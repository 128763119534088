import React from 'react';

import { Box, Container, Typography, makeStyles } from '@material-ui/core';

const useTablePageContainerStyles = makeStyles({
  rootContainer: {
    paddingTop: '88px',
    paddingLeft: '120px',
    paddingRight: '120px',
    paddingBottom: '44px',
  },
  headerContainer: {
    alignItems: 'center',
    marginBottom: '16px',
  },
});

interface ITablePageContainer {
  title: string;
  children: React.ReactNode;
  controls?: React.ReactNode;
}

export const TablePageContainer: React.FC<ITablePageContainer> = ({ title, children, controls }) => {
  const classes = useTablePageContainerStyles();
  return (
    <Container classes={{ root: classes.rootContainer }} maxWidth={false}>
      <Box display="flex" className={classes.headerContainer}>
        <Typography variant="h1">{title}</Typography>
        {controls && (
          <>
            <Box flexGrow={1} />
            {controls}
          </>
        )}
      </Box>
      {children}
    </Container>
  );
};
