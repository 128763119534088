import React from 'react';

import { FormControl, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  inputForm: {
    width: '256px',
    marginBottom: '0px',
  },
  outlinedInput: {
    padding: '8px 16px',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
  },
  item: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
  },
});

interface ISelectOption<T> {
  key: T;
  label: string;
  onClick?: () => void;
}

interface ISelectFieldProps<T> {
  options: ISelectOption<T>[];
  value: T;
  onChange: (value: T) => void;
  renderValue?: () => React.ReactNode;
}

type SelectFieldType<T = any> = React.FC<ISelectFieldProps<T>>;

export const SelectField: SelectFieldType = ({ value, options, onChange, renderValue }) => {
  const classes = useStyles();

  const handleSelectInput = (event: React.ChangeEvent<{ value: unknown }>) => {
    onChange(event.target.value as any);
  };

  return (
    <FormControl className={classes.inputForm} variant="outlined">
      <Select
        value={value}
        onChange={handleSelectInput}
        renderValue={renderValue}
        classes={{ select: classes.outlinedInput }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.key} value={option.key} classes={{ root: classes.item }} onClick={option.onClick}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
