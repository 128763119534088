import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AuthService } from 'services/authService';
import { checkAuth, getPortalData, getUserAction, login } from 'store/actions/auth';
import { ILoginError, ILoginResponse, PortalData, AuthStateType } from 'types/auth';
import { IUserResponse } from 'types/user';

export const initialState: AuthStateType = {
  user: null,
  userHasAccess: Boolean(AuthService.getAccessToken()),
  error: null,
  authLoading: false,
  portalData: null,
  portalDataLoading: false,
};

export const authSlice = createSlice({
  extraReducers: (builder) => {
    // Login
    builder.addCase(login.fulfilled, (state: AuthStateType, action: PayloadAction<ILoginResponse>) => ({
      ...state,
      user: action.payload.user,
      userHasAccess: Boolean(AuthService.getAccessToken()),
    }));
    builder.addCase(login.rejected, (state: AuthStateType, action) => ({
      ...state,
      error: action.payload ? (action.payload as ILoginError) : action.error,
    }));
    // Load user details
    builder.addCase(getUserAction.fulfilled, (state: AuthStateType, action: PayloadAction<IUserResponse>) => ({
      ...state,
      user: action.payload,
      authLoading: false,
    }));
    builder.addCase(getUserAction.pending, (state: AuthStateType) => ({
      ...state,
      authLoading: true,
    }));
    builder.addCase(getUserAction.rejected, (state: AuthStateType) => ({
      ...state,
      authLoading: false,
    }));
    builder.addCase(checkAuth.fulfilled, (state: AuthStateType, action) => ({
      ...state,
      userHasAccess: action.payload,
      authLoading: false,
    }));
    builder.addCase(checkAuth.pending, (state: AuthStateType) => ({
      ...state,
      authLoading: true,
    }));
    builder.addCase(checkAuth.rejected, (state: AuthStateType) => ({
      ...state,
      userHasAccess: false,
      authLoading: false,
    }));
    builder.addCase(getPortalData.fulfilled, (state: AuthStateType, action: PayloadAction<PortalData>) => ({
      ...state,
      portalData: action.payload,
      portalDataLoading: false,
    }));
    builder.addCase(getPortalData.pending, (state: AuthStateType) => ({
      ...state,
      portalDataLoading: true,
    }));
  },
  initialState,
  name: '@auth',
  reducers: {
    clearUser: (state: AuthStateType) => ({ ...state, user: null, error: null }),
    clearAuth: (state: AuthStateType) => ({ ...state, userHasAccess: false }),
  },
});
