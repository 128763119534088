import React, { FC, useCallback } from 'react';

import Button from '@material-ui/core/Button';
import { Field, Form } from 'react-final-form';

import styles from './ForgotPassword.module.less';

import { NavigationLink } from 'components';
import { TextFieldInput as TextField } from 'components/common/uncontrolledComponents/TextField';
import { ROUTER_PATHS } from 'constants/routerPaths';
import { AuthSuccessPageType } from 'enums/auth';
import { trimFormatter } from 'services/formatters';
import { NavigationService } from 'services/navigationService';
import { useAppDispatch } from 'store';
import { resetPassword } from 'store/actions/auth/auth';
import { composeValidators, emailValidator, requiredValidator } from 'validators';
import { TEXT_FIELD_NORMAL_MAX_LENGTH } from 'validators/constants';

export const ForgotPassword: FC = () => {
  const dispatch = useAppDispatch();

  const handleFormSubmit = useCallback(
    async ({ email }) => {
      await dispatch(resetPassword(email as string));
      NavigationService.navigateToSuccessAuthPage(ROUTER_PATHS.forgotPasswordSuccess, {
        type: AuthSuccessPageType.ForgotPassword,
        email,
      });
    },
    [dispatch],
  );

  return (
    <div className={styles.authPageContainer}>
      <div className={styles.authPageContent}>
        <div className={styles.authPageTitle}>Forgot your password?</div>
        <div className={styles.message}>Enter your email and we will send you instructions to reset your password</div>
        <Form onSubmit={handleFormSubmit} className={styles.authForm}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Field
                id="email"
                name="email"
                label="Email"
                variant="outlined"
                component={TextField}
                maxLength={TEXT_FIELD_NORMAL_MAX_LENGTH}
                formatOnBlur
                format={trimFormatter}
                validate={composeValidators(requiredValidator('Email'), emailValidator)}
              />
              <div className={styles.actions}>
                <Button color="primary" variant="contained" type="submit" className={styles.submitButton}>
                  Reset password
                </Button>
              </div>
            </form>
          )}
        </Form>
        <NavigationLink navigateTo={ROUTER_PATHS.signIn} linkText="Return to login" />
      </div>
    </div>
  );
};
