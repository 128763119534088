import React, { useEffect } from 'react';

import { Chip, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { SearchField } from 'components';
import { CompanyField } from 'components/common/CompanyField/CompanyField';
import { STATE_ORDER_FILTERS } from 'constants/order';
import { useAppDispatch } from 'store';
import { orderSlice } from 'store/reducers/order/order';
import { useAppSelector } from 'store/store';
import { IOrderFilters } from 'types/order';

const useStyles = makeStyles({
  filtersContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '12px 20px',
  },
  inputForm: {
    width: '256px',
    marginBottom: '0px',
  },
  outlinedInput: {
    padding: '8px 16px',
  },
  stateFilters: {
    marginLeft: '24px',
  },
  spacer: {
    marginLeft: '12px',
  },
});

export const OrderFilters: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { filters } = useAppSelector((state) => state.order);

  useEffect(() => {
    return () => {
      dispatch(orderSlice.actions.resetFilters);
    };
  }, [dispatch]);

  const updateFilter = (newFilter: IOrderFilters) => {
    dispatch(orderSlice.actions.setFilters({ ...filters, ...newFilter }));
  };

  return (
    <>
      <div className={classes.filtersContainer}>
        <SearchField value={filters.search} onChange={(value: string) => updateFilter({ ...filters, search: value })} />
        <div className={classes.spacer} />
        <CompanyField
          value={filters.company_id}
          onChange={(value: number) => updateFilter({ ...filters, company_id: value })}
        />
        <div className={classes.stateFilters}>
          {STATE_ORDER_FILTERS.map((item) => (
            <Chip
              color={item.key === filters.state ? 'primary' : 'default'}
              key={item.key}
              label={item.label}
              onClick={() => updateFilter({ ...filters, state: item.key })}
            />
          ))}
        </div>
      </div>
      <Divider />
    </>
  );
};
