import { createAsyncThunk } from '@reduxjs/toolkit';

import { orderApi } from 'api/orderApi';
import { IOrderFilters, SignOrderDataType } from 'types/order';
import { IPagination, ISort } from 'types/table';

type OrderApiParams = {
  filters: IOrderFilters;
  pagination: IPagination;
  sort: ISort;
};

export const getOrders = createAsyncThunk('@order/getOrders', async ({ filters, pagination, sort }: OrderApiParams) => {
  const { data } = await orderApi.getOrders(filters, pagination, sort);
  return data;
});

export const getAcceptAndSignData = createAsyncThunk('@order/getAcceptAndSignData', async (id: string) => {
  const { data } = await orderApi.getAcceptAndSignData(id);
  return data;
});

export const acceptAndSign = createAsyncThunk('@order/acceptAndSign', async (postData: SignOrderDataType) => {
  const { data } = await orderApi.acceptAndSign(postData.id, postData.acceptAndSignData);
  return data;
});

export const cancelOrder = createAsyncThunk('@order/cancelOrder', async (id: string) => {
  const { data } = await orderApi.orderCancel(id);
  return data;
});

export const getOrderDetails = createAsyncThunk('@order/getOrderDetails', async (id: string) => {
  const { data } = await orderApi.getOrderDetails(id);
  return data;
});
