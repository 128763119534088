import React, { FC, useCallback } from 'react';

import { Checkbox, FormControlLabel } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Field, Form } from 'react-final-form';
import { withWhiteLabelContext } from 'react-whitelabel';

import styles from './SignUp.module.less';

import { AuthSwitcher } from 'components';
import { PopupForm } from 'components/common/PopupForm/PopupForm';
import { PhoneNumberFiled } from 'components/common/uncontrolledComponents/PhoneNumberField';
import { TextFieldInput as TextField } from 'components/common/uncontrolledComponents/TextField';
import { ROUTER_PATHS } from 'constants/routerPaths';
import { AuthSuccessPageType } from 'enums/auth';
import { phoneNumberFormatter, trimFormatter } from 'services/formatters';
import { NavigationService } from 'services/navigationService';
import { useAppDispatch } from 'store';
import { requestForSignUp } from 'store/actions/auth';
import { useAppSelector } from 'store/store';
import { SignUpData } from 'types/auth';
import {
  composeValidators,
  emailValidator,
  minLengthValidator,
  onlyLettersValidator,
  phoneNumberValidator,
  requiredValidator,
} from 'validators';
import { TEXT_FIELD_HUGE_MAX_LENGTH, TEXT_FIELD_NORMAL_MAX_LENGTH } from 'validators/constants';

const useStyles = makeStyles({
  privacyPolicy: {
    padding: 0,
  },
});

const SignUp: FC<{ label: any }> = (props) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const { portalData } = useAppSelector((state) => state.auth);
  const [termsChecked, setTermsChecked] = React.useState(false);
  const [termsOpened, setTermsOpened] = React.useState(false);

  const handleChangeTerms = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTermsChecked(event.target.checked);
  };

  const handleFormSubmit = useCallback(
    async (formValues: SignUpData) => {
      await dispatch(requestForSignUp(formValues));
      NavigationService.navigateToSuccessAuthPage(ROUTER_PATHS.signUpSuccess, { type: AuthSuccessPageType.SignUp });
    },
    [dispatch],
  );

  const { label } = props;
  const { name } = label;

  return (
    <div className={styles.authPageContainer}>
      {portalData && <PopupForm url={portalData.privacy_link} opened={termsOpened} setOpened={setTermsOpened} />}
      <AuthSwitcher />
      <div className={styles.authPageContent}>
        <div className={styles.authPageTitle}>Request Account</div>
        <Form onSubmit={handleFormSubmit} className={styles.authForm}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Field
                id="fullName"
                name="full_name"
                label="Full Name"
                component={TextField}
                maxLength={TEXT_FIELD_NORMAL_MAX_LENGTH}
                formatOnBlur
                format={trimFormatter}
                validate={composeValidators(
                  requiredValidator('Full Name'),
                  minLengthValidator('Full Name'),
                  onlyLettersValidator('Full Name'),
                )}
              />
              <Field
                id="address"
                name="address"
                label="Address"
                component={TextField}
                maxLength={TEXT_FIELD_HUGE_MAX_LENGTH}
                formatOnBlur
                format={trimFormatter}
                validate={composeValidators(requiredValidator('Address'), minLengthValidator('Address'))}
              />
              <Field
                id="companyName"
                name="company_name"
                label="Company Name"
                component={TextField}
                maxLength={TEXT_FIELD_NORMAL_MAX_LENGTH}
                formatOnBlur
                format={trimFormatter}
                validate={composeValidators(requiredValidator('Company Name'), minLengthValidator('Address'))}
              />
              <Field
                id="email"
                name="email"
                label="Email"
                component={TextField}
                maxLength={TEXT_FIELD_NORMAL_MAX_LENGTH}
                formatOnBlur
                format={trimFormatter}
                validate={composeValidators(requiredValidator('Email'), emailValidator)}
              />
              <Field
                id="phone"
                name="phone"
                label="Phone number"
                component={PhoneNumberFiled}
                formatOnBlur
                format={phoneNumberFormatter}
                validate={composeValidators(requiredValidator('Phone number'), phoneNumberValidator)}
              />
              <FormControlLabel
                control={<Checkbox checked={termsChecked} onChange={handleChangeTerms} color="primary" />}
                label={
                  <span className={styles.text}>
                    I agree to {name}{' '}
                    <Button onClick={() => setTermsOpened(true)} classes={{ root: classes.privacyPolicy }}>
                      Terms of Service
                    </Button>{' '}
                    and{' '}
                    <Button onClick={() => setTermsOpened(true)} classes={{ root: classes.privacyPolicy }}>
                      Privacy Policy
                    </Button>
                    .
                  </span>
                }
              />
              <div className={styles.signUpHint}>* All fields are required</div>
              <div className={styles.actions}>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  className={styles.submitButton}
                  disabled={!termsChecked}
                >
                  Create account
                </Button>
              </div>
            </form>
          )}
        </Form>
      </div>
    </div>
  );
};

export default withWhiteLabelContext(SignUp);
