export { emailValidator } from './email';
export { passwordValidator, validatePasswordsForm } from './password';
export { requiredValidator } from './required';
export { onlyLettersValidator, onlyDigitsValidator } from './oneTypeInput';
export { minLengthValidator } from './minLength';
export { phoneNumberValidator } from './phone';

export const composeValidators =
  (...validators: Array<(value: any) => string | undefined>) =>
  (value: unknown): string | undefined => {
    // eslint-disable-next-line no-restricted-syntax
    for (const validator of validators) {
      const error = validator(value);

      if (error) {
        return error;
      }
    }

    return undefined;
  };
