import React from 'react';

import { Button, Card, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowForward } from '@material-ui/icons';
import { Link } from 'react-router-dom';

import { colors } from 'constants/ui';
import { IProduct } from 'types/category';

const useStyles = makeStyles((theme) => ({
  productTitle: {
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '28px',
  },
  productDescription: {
    color: colors.MIDDLE_GREY,
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
  },
  descriptionTooltip: {
    backgroundColor: colors.WHITE,
    padding: '16px',
    color: colors.MIDDLE_GREY,
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    maxWidth: '400px',
    boxShadow:
      '0px 5px 8px rgba(100, 111, 206, 0.07), 0px 6px 16px rgba(100, 111, 206, 0.12), 0px 0px 4px rgba(100, 111, 206, 0.2)',
  },
  productCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '36px',
    height: '100%',
    [theme.breakpoints.down(1360)]: {
      padding: '24px',
    },
    position: 'relative',
  },
}));

const maxVisibleDescriptionLen = 190;

interface IProductCardProps {
  product: IProduct;
}

const truncDescription = (description: string): React.ReactNode => {
  let result = description;
  if (description.length > maxVisibleDescriptionLen) {
    result = description.substr(0, maxVisibleDescriptionLen - 1);
    return <>{result}&hellip;</>;
  }
  return result;
};

export const ProductCard: React.FC<IProductCardProps> = ({ product }) => {
  const classes = useStyles();
  return (
    <Card className={classes.productCard}>
      <Typography classes={{ body1: classes.productTitle }} paragraph>
        {product.name}
      </Typography>
      {product.description_sale.length > maxVisibleDescriptionLen ? (
        <Tooltip
          title={product.description_sale}
          placement="right-end"
          classes={{ tooltip: classes.descriptionTooltip }}
        >
          <Typography classes={{ body1: classes.productDescription }} paragraph>
            {truncDescription(product.description_sale)}
          </Typography>
        </Tooltip>
      ) : (
        <Typography classes={{ body1: classes.productDescription }} paragraph>
          {truncDescription(product.description_sale)}
        </Typography>
      )}
      <Button
        variant="contained"
        endIcon={<ArrowForward />}
        component={Link}
        color="secondary"
        size="medium"
        to={`/shop/product/${product.id}`}
        disableElevation
      >
        Get price
      </Button>
    </Card>
  );
};
