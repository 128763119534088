import React, { useEffect } from 'react';

import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import pricePlaceholder from 'assets/svg/price-placeholder.svg';
import Spinner from 'components/common/Spinner/Spinner';
import { ROUTER_PATHS } from 'constants/routerPaths';
import { colors } from 'constants/ui';
import { useAppDispatch } from 'store';
import { createPriceRequest, updatePriceRequest } from 'store/actions/product';
import { orderSlice } from 'store/reducers/order/order';
import { productSlice } from 'store/reducers/product/product';
import { useAppSelector } from 'store/store';
import { IPriceRequest } from 'types/product';

const useStyles = makeStyles({
  head: {
    backgroundColor: colors.WHITE_GREY,
    padding: '16px 24px',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '24px',
  },
  cell: {
    padding: '14px 24px',
    color: colors.DARK_GREY,
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
  },
  cellWithoutBorder: {
    padding: '14px 24px',
    borderBottom: 0,
  },
});

export const Summary: React.FC = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const history = useHistory();
  const { productVariant, extraAttributeValues, locations, pricing, priceRequested } = useAppSelector(
    (state) => state.product,
  );

  // Create price requests
  useEffect(() => {
    if (!priceRequested) {
      dispatch(
        createPriceRequest({
          variantId: productVariant.id,
          extraAttributeValues,
          durationTerms: [],
          locations: locations.map((loc) => ({ quantity: loc.quantity, ...loc.address })),
        }),
      );
    }
  }, [dispatch, priceRequested, productVariant, extraAttributeValues, locations]);

  // Update price requests
  useEffect(() => {
    let pricingCheckDone = false;

    const interval = setInterval(() => {
      const requested = pricing.filter((price) => price.status !== 'DN');
      if (pricing.length > 0 && requested.length === 0) {
        pricingCheckDone = true;
        clearInterval(interval);
      } else {
        dispatch(updatePriceRequest(requested));
      }
    }, 2000);
    return () => {
      if (!pricingCheckDone) clearInterval(interval);
    };
  }, [dispatch, pricing]);

  if (pricing.length === 0 || pricing.some((price) => price.status === 'RQ')) return <Spinner />;

  const newQuote = () => {
    dispatch(orderSlice.actions.addNewOrder({ locations, variant: productVariant, duration_term: 0 }));
    history.push(ROUTER_PATHS.newQuotePage);
  };

  const addToCart = (selectedPricing: IPriceRequest) => {
    dispatch(productSlice.actions.setPricing(selectedPricing));
    history.push(ROUTER_PATHS.addToCartPage);
  };

  if (!pricing[0].price_total) {
    return (
      <>
        <Box mt="20px" display="flex" alignItems="center">
          <img src={pricePlaceholder} alt="" />
          <Box ml="32px" color={colors.MIDDLE_GREY} fontWeight={500} fontSize={16} lineHeight="28px">
            Wee need more info in order to show prices
          </Box>
        </Box>
        <Button color="primary" variant="contained" onClick={newQuote}>
          Get quote
        </Button>
      </>
    );
  }
  const locationPrices = pricing[0];

  return (
    <Box>
      <TableContainer component={Paper} elevation={0}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell classes={{ head: classes.head }}>Locations</TableCell>
              <TableCell classes={{ head: classes.head }} align="right">
                Subtotal
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {locationPrices.locations.map((location) => (
              <TableRow key={location.id}>
                <TableCell classes={{ root: classes.cell }}>{location.complete_address}</TableCell>
                <TableCell classes={{ root: classes.cell }} align="right">
                  $ {location.price_total}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell classes={{ root: classes.cellWithoutBorder }} colSpan={2} align="right">
                <Box display="inline" fontWeight={500} fontSize={14} lineHeight="24px" color={colors.DARK_GREY} mr={2}>
                  Total
                </Box>
                <Box display="inline" fontWeight={700} fontSize={18} lineHeight="28px" color={colors.DARK}>
                  $ {locationPrices.price_total}
                </Box>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <Button
        color="primary"
        variant="contained"
        onClick={() => {
          addToCart(locationPrices);
        }}
      >
        Add to cart
      </Button>
    </Box>
  );
};
