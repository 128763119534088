import React, { useEffect } from 'react';

import { Button, FormControl, IconButton, TextField } from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';

import styles from './ServiceLocation.module.less';

import { AddressAutocompleteField } from 'components/common/AddressAutocompleteField';
import { useAppDispatch } from 'store';
import { productSlice } from 'store/reducers/product/product';
import { useAppSelector } from 'store/store';
import { IAddress } from 'types/product';

export const ServiceLocations: React.FC = () => {
  const dispatch = useAppDispatch();
  const { productTemplate, locations } = useAppSelector((state) => state.product);
  // Reset quantity for incorrect entries while leave step
  useEffect(
    () => () => {
      dispatch(productSlice.actions.resetLocationQty());
    },
    [dispatch],
  );
  return (
    <div className={styles.container}>
      {locations.map((locationData) => (
        <div key={locationData.id}>
          <div className={styles.locationInput}>
            <AddressAutocompleteField
              address={locationData.address}
              setAddress={(address: IAddress | null) =>
                dispatch(productSlice.actions.updateLocation({ ...locationData, address }))
              }
            />
          </div>
          {!productTemplate.is_non_quantitative && (
            <div className={styles.quantityInput}>
              <TextField
                label="Quantity"
                type="number"
                value={locationData.quantity}
                error={locationData.quantity < 1 || locationData.quantity % 1 !== 0}
                variant="outlined"
                helperText={
                  locationData.quantity < 1 || locationData.quantity % 1 !== 0
                    ? 'Quantity should be positive and integer number'
                    : undefined
                }
                onChange={(e) =>
                  dispatch(productSlice.actions.updateLocation({ ...locationData, quantity: Number(e.target.value) }))
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          )}
          {locationData.deletable && (
            <div className={styles.deleteIcon}>
              <FormControl>
                <IconButton>
                  <Delete onClick={() => dispatch(productSlice.actions.deleteLocation(locationData.id))} />
                </IconButton>
              </FormControl>
            </div>
          )}
        </div>
      ))}
      <Button variant="text" startIcon={<Add />} onClick={() => dispatch(productSlice.actions.addLocation())}>
        Add Location
      </Button>
    </div>
  );
};
