import React from 'react';

import { useParams } from 'react-router-dom';

import styles from './ProductPage.module.less';

import { ShoppingWizard } from 'components/shop/ShoppingWizard';

interface IProductPageParams {
  id: string;
}

export const ProductPage: React.FC = () => {
  const { id } = useParams<IProductPageParams>();

  return (
    <div className={styles.pageContainer}>
      <ShoppingWizard productId={Number(id)} />
    </div>
  );
};
