import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import logoOrderSubmitted from '../../../assets/svg/submitted_order.svg';

import styles from './SubmitDialog.module.less';

import { ROUTER_PATHS } from 'constants/routerPaths';
import { useAppDispatch } from 'store';
import { getCart } from 'store/actions/cart';
import { getAcceptAndSignData } from 'store/actions/order';

interface ISubmitDialogProps {
  visible: boolean;
  cartReload?: boolean;
  setOpenParent: React.Dispatch<React.SetStateAction<boolean>>;
  id: string;
}

export const SubmitDialog: React.FC<ISubmitDialogProps> = ({ visible, cartReload, setOpenParent, id }) => {
  const [opened, setOpened] = React.useState(visible);
  const dispatch = useAppDispatch();

  function close() {
    dispatch(getAcceptAndSignData(id));
    setOpened(false);
    setOpenParent(false);
    if (cartReload) dispatch(getCart());
  }

  return (
    <Dialog
      open={opened}
      onClose={() => close()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <img src={logoOrderSubmitted} alt="" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography style={{ width: '432px' }}>
            <span className={styles.largeTextBold}>
              Your order has been successfully sent over to our Provisioning Team
            </span>
          </Typography>
          <Typography style={{ width: '432px' }}>
            <span className={styles.text}>
              Once they complete the initial account creation and order processing, the team will contact you with an
              update on your order and next steps for installation.
            </span>
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" component={Link} to={ROUTER_PATHS.orders}>
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};
