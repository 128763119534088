import React, { useEffect, useState } from 'react';

import { Chip, Typography } from '@material-ui/core';

import styles from './ServiceParameters.module.less';

import { useAppDispatch } from 'store';
import { productSlice } from 'store/reducers/product/product';
import { useAppSelector } from 'store/store';
import { IProductAttribute, IProductAttributeValue, IProductTemplate, IProductVariant } from 'types/product';

interface IAttributeState {
  attribute: IProductAttribute;
  selectedValueId: number | null;
}

function getVariant(productTemplate: IProductTemplate, attributeValues: (number | null)[]): IProductVariant | null {
  const notSelected = attributeValues.filter((item) => typeof item !== 'number');
  if (notSelected.length === 0) {
    const newVariant = productTemplate.variants.find((variant) => {
      let flag = true;
      variant.values.forEach((attrValue) => {
        if (!attributeValues.includes(attrValue.id)) {
          flag = false;
        }
      });
      return flag;
    });
    return newVariant || null;
  }
  return null;
}

function getInitialState(productTemplate: IProductTemplate, productVariant: IProductVariant | null): IAttributeState[] {
  if (productVariant) {
    return productTemplate.attributes.map((attr) => {
      const value = productVariant.values.find((v) => v.attribute_id === attr.id);
      return { attribute: attr, selectedValueId: value ? value.id : null };
    });
  }
  return productTemplate.attributes.map((attr): IAttributeState => ({ attribute: attr, selectedValueId: null }));
}

export const ServiceParameters: React.FC = () => {
  const dispatch = useAppDispatch();
  const { productTemplate, productVariant } = useAppSelector((state) => state.product);
  const [attributeState, setAttributeState] = useState<IAttributeState[]>(
    getInitialState(productTemplate, productVariant),
  );
  const [exclusions, setExclusions] = useState<Set<number>>(new Set());

  useEffect(() => {
    const newExclusions: Set<number> = new Set();
    attributeState.forEach((state) => {
      if (state.selectedValueId) {
        const valueExclusions = productTemplate.exclusions.exclusions[state.selectedValueId];
        valueExclusions.forEach((valueId) => {
          newExclusions.add(valueId);
        });
      }
    });
    setExclusions(newExclusions);
  }, [productTemplate, attributeState, setExclusions]);

  const handleValue = (attributeId: number, value: IProductAttributeValue) => {
    const newState = attributeState.map((state): IAttributeState => {
      return state.attribute.id === attributeId ? { ...state, selectedValueId: value.id } : state;
    });

    // Check if all attributes selected
    const selectedAttributes = newState.map((attrState) => attrState.selectedValueId);
    const selectedVariant = getVariant(productTemplate, selectedAttributes);
    const extraValues: number[] = [];
    if (selectedVariant) {
      newState.forEach((attr) => {
        if (attr.attribute.create_variant === 'no_variant') {
          extraValues.push(attr.selectedValueId);
        }
      });
      dispatch(
        productSlice.actions.setVariant({
          variant: selectedVariant,
          extraValues,
        }),
      );
    }
    setAttributeState(newState);
  };

  return (
    <div className={styles.container}>
      {attributeState.map(({ attribute, selectedValueId }) => (
        <div key={attribute.id} className={styles.attribute}>
          <Typography>{attribute.name}</Typography>
          <div>
            {attribute.values.map((value) => (
              <Chip
                key={value.id}
                label={value.name}
                color={value.id === selectedValueId ? 'primary' : 'default'}
                onClick={() => handleValue(attribute.id, value)}
                disabled={exclusions.has(value.id)}
                size="small"
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
