import { PayloadAction, createSlice, nanoid } from '@reduxjs/toolkit';

import { createPriceRequest, fetchProduct, updatePriceRequest } from 'store/actions/product';
import { ILocationData, IPriceRequest, IProductTemplate, IProductVariant, ProductStateType } from 'types/product';

export const initialState: ProductStateType = {
  productTemplate: null,
  productVariant: null,
  extraAttributeValues: [],
  locations: [],
  pricing: [],
  selectedPricing: null,
  loading: false,
  priceRequested: false,
};

const generateLocation = (): ILocationData => ({
  id: nanoid(),
  deletable: false,
  address: null,
  quantity: 1,
});

export const productSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(fetchProduct.pending, (state: ProductStateType) => ({ ...state, loading: true }));
    builder.addCase(fetchProduct.fulfilled, (state: ProductStateType, action: PayloadAction<IProductTemplate>) => {
      const productTemplate = action.payload;
      const minLocations = productTemplate.locations_count[0] === 'multiple' ? 2 : 1;
      const locations: ILocationData[] = Array.from({ length: minLocations }, () => generateLocation());
      const productVariant = productTemplate.variants.length === 1 ? productTemplate.variants[0] : null;
      return { ...state, productTemplate, productVariant, locations, pricing: [], loading: false };
    });
    builder.addCase(createPriceRequest.pending, (state: ProductStateType) => ({ ...state, priceRequested: true }));
    builder.addCase(
      createPriceRequest.fulfilled,
      (state: ProductStateType, action: PayloadAction<IPriceRequest[]>) => ({
        ...state,
        pricing: action.payload,
      }),
    );
    builder.addCase(updatePriceRequest.fulfilled, (state: ProductStateType, action: PayloadAction<IPriceRequest[]>) => {
      const newPricing = state.pricing.map((price) => {
        const index = action.payload.findIndex((x) => x.id === price.id);
        if (index !== -1) return action.payload[index];
        return price;
      });
      return { ...state, pricing: newPricing };
    });
  },
  initialState,
  name: '@product',
  reducers: {
    resetProduct: (state: ProductStateType) => ({ ...state, ...initialState }),
    setVariant: (
      state: ProductStateType,
      action: PayloadAction<{ variant: IProductVariant; extraValues: number[] }>,
    ) => ({
      ...state,
      productVariant: action.payload.variant,
      extraAttributeValues: action.payload.extraValues,
      pricing: [],
    }),
    addLocation: {
      reducer: (state: ProductStateType, action: PayloadAction<ILocationData>) => {
        state.locations.push(action.payload);
      },
      prepare: () => ({ payload: { ...generateLocation(), deletable: true } }),
    },
    updateLocation: (state: ProductStateType, action: PayloadAction<ILocationData>) => {
      return { ...state, locations: state.locations.map((l) => (l.id === action.payload.id ? action.payload : l)) };
    },
    deleteLocation: (state: ProductStateType, action: PayloadAction<string>) => {
      return { ...state, locations: state.locations.filter((l) => l.id !== action.payload) };
    },
    resetLocationQty: (state: ProductStateType) => {
      return { ...state, locations: state.locations.map((l) => (l.quantity < 1 ? { ...l, quantity: 1 } : l)) };
    },
    setPricing: (state: ProductStateType, action: PayloadAction<IPriceRequest>) => {
      return { ...state, selectedPricing: action.payload };
    },
    resetPricing: (state: ProductStateType) => {
      return { ...state, pricing: [], selectedPricing: null, priceRequested: false };
    },
  },
});
