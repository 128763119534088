export const STATE_BILL_ALL = 'all';
export const STATE_BILL_PAID = 'paid';
export const STATE_BILL_DUE = 'due';
export const STATE_BILL_OVERDUE = 'overdue';
export const STATE_BILL_IN_PAYMENT = 'in_payment';

export const COLUMN_BILL_NAME = 'name';
export const COLUMN_BILL_PERIOD = 'period';
export const COLUMN_BILL_AMOUNT_TOTAL = 'amount_total';
export const COLUMN_BILL_COMPANY_NAME = 'company_name';
export const COLUMN_BILL_PAYMENT_STATE = 'payment_state';
export const COLUMN_BILL_AMOUNT_DUE = 'amount_due';

export const PERIOD_BILL_ANY = 'any';
export const PERIOD_BILL_LAST_MONTH = 'last_month';
export const PERIOD_BILL_LAST_YEAR = 'last_year';
export const PERIOD_BILL_CUSTOMER = 'customer';

export const PERIOD_BILL_FILTERS = [
  { key: PERIOD_BILL_ANY, label: 'Any period' },
  { key: PERIOD_BILL_LAST_MONTH, label: 'Last month' },
  { key: PERIOD_BILL_LAST_YEAR, label: 'Last year' },
  { key: PERIOD_BILL_CUSTOMER, label: 'Custom range...' },
];

export const STATE_BILL_FILTERS: { key: 'all' | 'paid' | 'due' | 'overdue' | 'in_payment'; label: string }[] = [
  { key: STATE_BILL_ALL, label: 'All' },
  { key: STATE_BILL_PAID, label: 'Paid' },
  { key: STATE_BILL_DUE, label: 'Current due' },
  { key: STATE_BILL_OVERDUE, label: 'Overdue' },
  { key: STATE_BILL_IN_PAYMENT, label: 'In Payment' },
];
