import React, { FC, useState } from 'react';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { makeStyles } from '@material-ui/core/styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { checkFieldError } from './helper';

import { ICommonInputFieldProps } from 'types/inputField';

const useStyles = makeStyles({
  inputRoot: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 500,
  },
  inputRootLabel: {
    fontSize: '12px',
    lineHeight: '20px',
    fontWeight: 500,
  },
});

export const PasswordField: FC<ICommonInputFieldProps> = ({ input, meta, id, label }) => {
  const [isShowPassword, setShowPasswordState] = useState<boolean>(false);
  const isErrorField = checkFieldError(meta);
  const classes = useStyles();

  const handleClickShowPassword = () => {
    setShowPasswordState((prevState) => {
      return !prevState;
    });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <FormControl variant="outlined">
      <InputLabel htmlFor={id} classes={{ root: classes.inputRootLabel }} className={isErrorField ? 'Mui-error' : ''}>
        {label}
      </InputLabel>
      <OutlinedInput
        {...input}
        id={id}
        label={label}
        type={isShowPassword ? 'text' : 'password'}
        error={isErrorField}
        classes={{ root: classes.inputRoot }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {isShowPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
      {isErrorField && (
        <FormHelperText id={`${id}-helper-text`} className="MuiFormHelperText-contained Mui-error">
          {meta.error}
        </FormHelperText>
      )}
    </FormControl>
  );
};
