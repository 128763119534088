import { CHARACTERS_MIN_LENGTH } from './constants';

export const minLengthValidator =
  (label = 'Field', minLength = CHARACTERS_MIN_LENGTH) =>
  (value: string | undefined): string | undefined => {
    if (value && value.length < minLength) {
      return `${label} should contain at least ${minLength} characters`;
    }
    return undefined;
  };
