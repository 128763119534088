import React, { FC, ReactNode } from 'react';

import Grid from '@material-ui/core/Grid';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';

import styles from './AuthLayout.module.less';

import AuthLogo from 'components/common/WhilteLabelComponents/AuthLogo/AuthLogo';

export const AuthLayout: FC<{ children: ReactNode }> = ({ children }): JSX.Element => (
  <div className={styles.container}>
    <Grid container className={styles.container}>
      <Grid item xs={6} lg={7}>
        <AuthLogo />
      </Grid>
      <Grid item xs={6} lg={5} className={styles.authPage}>
        <div className={styles.content}>{children}</div>
        <footer className={styles.footer}>
          <div className={styles.footerSection}>
            <EmailIcon className={styles.footerIcon} />
            <span>Email us</span>
          </div>
          <div className={styles.footerSection}>
            <PhoneIcon className={styles.footerIcon} />
            <span>866-364-6033</span>
          </div>
        </footer>
      </Grid>
    </Grid>
  </div>
);
