import React, { useCallback, useEffect, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import ReplayIcon from '@material-ui/icons/Replay';
import { Link } from 'react-router-dom';
import { withWhiteLabelContext } from 'react-whitelabel';

import styles from './ShoppingCartPage.module.less';

import logoDeleteAllProducts from 'assets/svg/delete_all_products.svg';
import logoDeleteAllLocations from 'assets/svg/warning_dialog_title.svg';
import HelpBillingIcon from 'components/shop/HelpBillingIcon/HelpBillingIcon';
import { ROUTER_PATHS } from 'constants/routerPaths';
import { colors } from 'constants/ui';
import { history } from 'navigation/history';
import { useAppDispatch } from 'store';
import {
  cartDelete,
  cartDeleteItem,
  cartSubmit as cartSubmitAction,
  cartUpdate,
  getCart,
} from 'store/actions/cart/cart';
import { cartSlice } from 'store/reducers/cart/cart';
import { useAppSelector } from 'store/store';
import { ICartResponse } from 'types/cart';
import { IPriceResultAddress, IProductAttribute } from 'types/product';

const useStyles = makeStyles({
  dividerRoot: {
    margin: '0 12px',
    border: '1px solid rgba(28, 31, 37, 0.14)',
  },
  confirmDeleteButton: {
    background: colors.RED,
    color: colors.WHITE,
  },
  quantityInput: {
    width: '124px',
    margin: '0',
  },
});

interface IDisplayItem {
  productData: ICartResponse;
  locationData: IPriceResultAddress;
}

const getDisplayData = (cart: ICartResponse[]): { displayData: Map<string, IDisplayItem[]>; sum: number } => {
  let sum = 0.0;
  const displayData = new Map<string, IDisplayItem[]>();
  cart.forEach((product) => {
    product.locations.forEach((location) => {
      if (displayData.has(location.complete_address)) {
        const value = displayData.get(location.complete_address);
        value.push({
          productData: product,
          locationData: location,
        });
      } else {
        displayData.set(location.complete_address, [
          {
            productData: product,
            locationData: location,
          },
        ]);
      }
      sum += location.price_total;
    });
  });
  return { displayData, sum };
};

const getMultipleLocationsCount = (cart: ICartResponse[], variantId: number, cartStatus: string): number => {
  let lCount = 0;
  cart
    .filter((cartItem) => cartItem.variant_id === variantId)
    .forEach((item) => {
      lCount += item.locations.filter((location) => location.cart_status === cartStatus).length;
    });
  return lCount;
};

const ShoppingCartPage: React.FC<{ label: any }> = (props): JSX.Element => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { label } = props;
  const { cartData, cartInvalid, updateQuantity, updateLocation } = useAppSelector((state) => state.cart);
  const { user } = useAppSelector((state) => state.auth);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [multiDeleteProduct, setMultiDeleteProduct] = useState<IProductAttribute | null>(null);

  const { displayData, sum } = getDisplayData(cartData);

  useEffect(() => {
    // Cart loading while open page
    if (cartData.length === 0) dispatch(getCart());
  }, [dispatch, cartData.length]);

  useEffect(() => {
    if (updateQuantity && updateLocation) {
      dispatch(cartUpdate({ location: updateLocation, params: { quantity: updateQuantity } }));
    }
  }, [dispatch, cartData, updateQuantity, updateLocation]);

  const multiLocationUpdate = useCallback(
    async (variantId: number, deleteMode = true) => {
      let step = 0;
      const promises = cartData
        .filter((item) => item.variant_id === variantId)
        .map(async (cart) => {
          if (step < 2) {
            const innerPromises = cart.locations
              .filter((locItem) => locItem.cart_status === (deleteMode ? 'active' : 'delete'))
              .map((location) => {
                if (step < 2) {
                  step += 1;
                  return dispatch(
                    cartUpdate({
                      location: location.id,
                      params: { quantity: location.quantity, cart_status: deleteMode ? 'delete' : 'active' },
                    }),
                  );
                }
                return new Promise(Promise.resolve);
              });
            return Promise.all(innerPromises);
          }
          return new Promise(Promise.resolve);
        });
      await Promise.all(promises);
      setDeleteVisible(false);
    },
    [dispatch, cartData],
  );

  const updateRowStatus = useCallback(
    async (locationId: number, quantity: number, variant: IProductAttribute | null = null, deleted = true) => {
      if (variant && variant.locations_count === 'multiple') {
        const lCount = getMultipleLocationsCount(cartData, variant.id, 'active');
        if ((lCount <= 2 && deleted) || (lCount <= 1 && !deleted)) {
          if (deleted) {
            setMultiDeleteProduct(variant);
            setDeleteVisible(true);
          } else await multiLocationUpdate(variant.id, false);
          return;
        }
      }
      await dispatch(
        cartUpdate({ location: locationId, params: { quantity, cart_status: deleted ? 'delete' : 'active' } }),
      );
    },
    [dispatch, cartData, multiLocationUpdate],
  );

  const updateRowQuantity = async (locationId: number, quantity: number) => {
    await dispatch(cartSlice.actions.updateQuantity({ locationId, quantity }));
  };

  const deleteLocationRow = useCallback(
    async (locationId: number, variant: IProductAttribute) => {
      const toDeleteLocationIds: number[] = [locationId];
      if (variant && variant.locations_count === 'multiple') {
        const lCount = getMultipleLocationsCount(cartData, variant.id, 'delete');
        if (lCount <= 2) {
          toDeleteLocationIds.pop();
          cartData
            .filter((cartItem) => cartItem.variant_id === variant.id)
            .forEach((item) =>
              item.locations
                .filter((location) => location.cart_status === 'delete')
                .forEach((location) => {
                  if (toDeleteLocationIds.length < 2) toDeleteLocationIds.push(location.id);
                }),
            );
        }
      }
      await Promise.all(toDeleteLocationIds.map((id) => dispatch(cartDeleteItem(id))));
    },
    [dispatch, cartData],
  );

  const cartClear = async () => {
    await dispatch(cartDelete());
    setDeleteVisible(false);
    await dispatch(getCart());
  };

  const cartSubmit = () => {
    dispatch(cartSubmitAction()).then(async (value: any) => {
      await dispatch(getCart());
      history.push(`/order/${value.payload.id}/accept/sign`);
    });
  };

  return (
    <div className={styles.pageContainer}>
      <Dialog
        open={deleteVisible}
        onClose={() => setDeleteVisible(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <img src={multiDeleteProduct ? logoDeleteAllLocations : logoDeleteAllProducts} alt="" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography>
              <span className={styles.largeTextBold}>
                {multiDeleteProduct
                  ? `${multiDeleteProduct.name} ordering requires at least 2 locations`
                  : 'Delete all products?'}
              </span>
            </Typography>
            <Typography>
              <span className={styles.text}>
                {multiDeleteProduct
                  ? `Do you want to delete ${multiDeleteProduct.name} from both locations?`
                  : 'All products in your order will be dismissed.'}
              </span>
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteVisible(false)} color="primary" autoFocus>
            Cancel
          </Button>
          <Button
            className={classes.confirmDeleteButton}
            onClick={() => (multiDeleteProduct ? multiLocationUpdate(multiDeleteProduct.id) : cartClear())}
            color="primary"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {displayData.size > 0 ? (
        <div>
          <Typography variant="h1">Order Check Out</Typography>
          <Box display="flex" alignItems="center" marginTop="36px">
            <span className={styles.textMedium}>{user && user.company_name}</span>
            <Divider classes={{ root: classes.dividerRoot }} orientation="vertical" flexItem />
            <span className={styles.smallText}>Billing Address: {user && user.invoice_address}</span>
            <HelpBillingIcon />
          </Box>
          <TableContainer style={{ marginTop: '16px' }} component={Paper}>
            <Table aria-label="spanning table">
              <TableHead>
                <TableRow>
                  <TableCell className={styles.textMedium} align="left">
                    Delivery Address
                  </TableCell>
                  <TableCell className={styles.textMedium} align="left">
                    Product
                  </TableCell>
                  <TableCell className={styles.textMedium} align="left">
                    Contract Term
                  </TableCell>
                  <TableCell className={styles.textMedium} align="left">
                    Quantity
                  </TableCell>
                  <TableCell className={styles.textMedium} align="center">
                    Total
                  </TableCell>
                  <TableCell className={styles.tinyMedium} align="right">
                    <Button
                      onClick={() => {
                        setMultiDeleteProduct(null);
                        setDeleteVisible(true);
                      }}
                      style={{ paddingRight: '12px', color: colors.DARK_GREY }}
                    >
                      Delete all
                      <DeleteIcon style={{ marginLeft: '10px' }} />
                    </Button>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.from(displayData.entries()).map((entry) => {
                  const [key, value] = entry;
                  return (
                    <>
                      {value.map(({ productData, locationData }, subIndex) => (
                        <TableRow key={`${key}${productData.variant_id}`}>
                          {subIndex === 0 && (
                            <TableCell style={{ verticalAlign: 'baseline' }} rowSpan={value.length}>
                              <span className={styles.text}>{key}</span>
                            </TableCell>
                          )}
                          <TableCell>
                            <div>
                              <span className={styles.text}>{productData.variant_data.name}</span>
                              <Typography color="textSecondary">
                                {productData.variant_data.values.map((attribute) => (
                                  <span className={styles.tinyText} key={attribute.attribute_id}>
                                    {attribute.name};{' '}
                                  </span>
                                ))}
                              </Typography>
                            </div>
                          </TableCell>
                          <TableCell>
                            <span className={styles.text}>
                              {productData.duration_term ? productData.duration_term : '-'}
                            </span>
                          </TableCell>
                          <TableCell>
                            <TextField
                              classes={{ root: classes.quantityInput }}
                              label="Quantity"
                              type="number"
                              value={locationData.quantity}
                              error={locationData.quantity < 1 || locationData.quantity % 1 !== 0}
                              variant="outlined"
                              helperText={
                                locationData.quantity < 1 || locationData.quantity % 1 !== 0
                                  ? 'Quantity should be positive and integer number'
                                  : undefined
                              }
                              onChange={(e) => updateRowQuantity(locationData.id, Number(e.target.value))}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <span className={styles.text}>
                              {locationData.cart_status !== 'active' ? (
                                <span style={{ color: colors.DARK_GREY }}>Product is deleted</span>
                              ) : (
                                <span>
                                  {'$ '}
                                  {locationData.price_total}{' '}
                                  {productData.duration_term ? <span color="textSecondary"> /mo</span> : ''}
                                </span>
                              )}
                            </span>
                          </TableCell>
                          <TableCell align="right">
                            {locationData.cart_status === 'active' ? (
                              <IconButton
                                onClick={() =>
                                  updateRowStatus(
                                    locationData.id,
                                    locationData.quantity,
                                    productData.variant_data,
                                    true,
                                  )
                                }
                                style={{ color: colors.DARK_GREY }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            ) : (
                              <div>
                                <Button
                                  onClick={() =>
                                    updateRowStatus(
                                      locationData.id,
                                      locationData.quantity,
                                      productData.variant_data,
                                      false,
                                    )
                                  }
                                  className={styles.undo}
                                  startIcon={<ReplayIcon />}
                                  style={{ marginLeft: '24px' }}
                                >
                                  Undo
                                </Button>
                                <IconButton
                                  onClick={() => deleteLocationRow(locationData.id, productData.variant_data)}
                                >
                                  <ClearIcon />
                                </IconButton>
                              </div>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  );
                })}
                <TableRow>
                  <TableCell colSpan={6}>
                    <Box display="flex" padding="12px 8px">
                      <Button
                        component={Link}
                        to={ROUTER_PATHS.shopByProduct}
                        color="primary"
                        endIcon={<ArrowForwardIcon />}
                      >
                        <span className={styles.textMedium}>Continue shopping</span>
                      </Button>
                      <Box display="flex" marginLeft="auto">
                        <Typography variant="h3" style={{ marginRight: '44px' }}>
                          <span className={styles.text}>Grand Total:</span> $ {sum.toFixed(2)}
                        </Typography>
                        <Button onClick={cartSubmit} color="primary" variant="contained" disabled={cartInvalid}>
                          <span className={styles.largeText}>Submit order</span>
                        </Button>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : (
        <Paper style={{ width: '1112px', height: '628px', margin: 'auto', padding: '70px', display: '-webkit-box' }}>
          <img src={label.ordersEmptyLogo} alt="" />
          <Box width="371px" height="60px" position="relative" top="140px" right="110px">
            <Typography style={{ marginBottom: '44px' }} variant="h1">
              Your order is empty
            </Typography>
            <Button
              color="primary"
              variant="contained"
              startIcon={<AddIcon />}
              component={Link}
              to={ROUTER_PATHS.shopByProduct}
            >
              <span className={styles.largeText}>New order</span>
            </Button>
          </Box>
        </Paper>
      )}
    </div>
  );
};

export default withWhiteLabelContext(ShoppingCartPage);
