export const BRAND = process.env.REACT_APP_WHITELABEL;
export const isProductionMode = process.env.NODE_ENV === 'production';
export const ACCESS_TOKEN_KEY = 'accessToken';
export const API_TIMEOUT = 10000;
export const BILL_DOWNLOAD_TIMEOUT = 10000;
// Max file size 50 MB
export const ATTACHMENT_SIZE_LIMIT = 1024 * 1024 * 50;

export const SORT_ASC = 'ASC';
export const SORT_DESC = 'DESC';

export const DEFAULT_COMPANY_KEY = 0;
