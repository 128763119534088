import { makeStyles } from '@material-ui/core/styles';

import { FONT_SIZE_LG } from 'constants/ui';

export const useTabsStyles = makeStyles({
  root: {
    'font-size': FONT_SIZE_LG,
    'text-transform': 'none',
  },
});
