import React from 'react';

import { Box, Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Check, ExpandMore } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { withWhiteLabelContext } from 'react-whitelabel';

import styles from './BreadCrumbItem.module.less';

import { IBreadCrumbItemBasic } from 'types/breadcrumbs';

interface IBreadCrumbItemProps {
  title: string;
  url: string;
  expandable: boolean;
  expandedItems?: IBreadCrumbItemBasic[];
  label?: any;
}

const BreadCrumbItem: React.FC<IBreadCrumbItemProps> = ({
  title,
  url,
  expandable,
  expandedItems,
  label,
}): JSX.Element => {
  const useStyles = makeStyles({
    breadCrumb: {
      '&:hover': {
        color: label.breadCrumbs.hoverColor,
        textDecoration: 'none',
        backgroundColor: 'transparent',
      },
      paddingLeft: '0',
      paddingRight: '0',
      backgroundColor: 'transparent',
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: 500,
      color: label.breadCrumbs.defaultColor,
    },
    breadCrumbExpandedItem: {
      '&:hover': {
        color: label.breadCrumbs.hoverColor,
        textDecoration: 'none',
        backgroundColor: 'transparent',
      },
      padding: '0',
      backgroundColor: 'transparent',
    },
    icon: {
      verticalAlign: 'sub',
    },
    expandedItems: {
      marginTop: '8px',
    },
  });
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false && anchorRef.current) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return expandable ? (
    <>
      <Button
        classes={{ root: classes.breadCrumb }}
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <span className={styles.text}>{title}</span>
        <ExpandMore classes={{ root: classes.icon }} />
      </Button>
      <Popper
        className={styles.popper}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper classes={{ root: classes.expandedItems }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow">
                  {expandedItems &&
                    expandedItems.map((item) => (
                      <MenuItem key={item.title} component={Link} to={item.url}>
                        <Box marginRight="15px" visibility={item.title === title ? 'visible' : 'hidden'}>
                          <Check classes={{ root: classes.icon }} />
                        </Box>
                        <span className={styles.text}>{item.title}</span>
                      </MenuItem>
                    ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  ) : (
    <Link to={url}>
      <Typography classes={{ root: classes.breadCrumb }}>{title}</Typography>
    </Link>
  );
};

export default withWhiteLabelContext(BreadCrumbItem);
