import React from 'react';

import {
  Button,
  ButtonProps,
  ClickAwayListener,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Person, ShoppingCart } from '@material-ui/icons';
import { Link } from 'react-router-dom';

import { LogoutIcon, Spinner } from 'components/index';
import { ROUTER_PATHS } from 'constants/routerPaths';
import { colors } from 'constants/ui';
import { AuthService } from 'services/authService';
import { useAppDispatch } from 'store';
import { authSlice } from 'store/reducers/auth/auth';
import { useAppSelector } from 'store/store';

const useStyles = makeStyles({
  root: {
    color: colors.DARK,
    textAlign: 'left',
    fontSize: 14,
    lineHeight: '24px',
  },
  text: {
    padding: '2px 12px',
    '&:hover': {
      backgroundColor: colors.WHITE_GREY,
    },
    '&:focus': {
      backgroundColor: colors.LIGHT_GREY,
    },
    '& .MuiTouchRipple-root': {
      color: colors.WHITE_GREY,
      mixBlendMode: 'normal',
    },
  },
  company: {
    color: colors.MIDDLE_GREY,
    fontSize: 12,
    lineHeight: '20px',
  },
  menuContainer: {
    minWidth: '200px',
  },
  menuIcon: {
    color: colors.GREY,
    minWidth: '40px',
  },
  cartButton: {
    fontWeight: 600,
    marginRight: '16px',
  },
});

export const UserDetailsButton: React.FC<ButtonProps> = (props) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [open, setOpen] = React.useState(false);
  const { user } = useAppSelector((state) => state.auth);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const handleLogout = () => {
    AuthService.logout().then(() => {
      dispatch(authSlice.actions.clearAuth());
    });
  };

  if (!user) {
    return <Spinner />;
  }
  return (
    <>
      <Button
        className={classes.cartButton}
        variant="contained"
        color="secondary"
        startIcon={<ShoppingCart />}
        component={Link}
        to={ROUTER_PATHS.shoppingCart}
      >
        ${user.cart_price_total}
      </Button>
      <Button {...props} ref={anchorRef} classes={{ root: classes.root, text: classes.text }} onClick={handleToggle}>
        <div>
          <div>{user.first_name}</div>
          <div className={classes.company}>{user.company_name}</div>
        </div>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          <Paper className={classes.menuContainer} elevation={7}>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList autoFocusItem={open} id="menu-list-grow">
                <MenuItem onClick={handleClose} component={Link} to={ROUTER_PATHS.profile}>
                  <ListItemIcon className={classes.menuIcon}>
                    <Person />
                  </ListItemIcon>
                  <Typography variant="inherit">Account details</Typography>
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon className={classes.menuIcon}>
                    <LogoutIcon />
                  </ListItemIcon>
                  <Typography variant="inherit">Logout</Typography>
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Popper>
      </Button>
    </>
  );
};
