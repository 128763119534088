import { createAsyncThunk } from '@reduxjs/toolkit';

import { cartApi } from 'api/cartApi';

export const getCart = createAsyncThunk('@cart', async () => {
  const { data } = await cartApi.getCart();
  return data;
});

export const cartSubmit = createAsyncThunk('@cart/submit', async () => {
  const { data } = await cartApi.cartSubmit();
  return data;
});

export const cartDelete = createAsyncThunk('@cart/delete', async () => {
  const { data } = await cartApi.cartDelete();
  return data;
});

export const cartUpdate = createAsyncThunk('@cart/delete', async ({ location, params }: any) => {
  const { data } = await cartApi.cartUpdate(location, params);
  return data;
});

export const cartDeleteItem = createAsyncThunk('@cart/deleteItem', async (id: number) => {
  const { data } = await cartApi.cartDeleteItem(id);
  return data;
});
