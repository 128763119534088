import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { getComments } from 'store/actions/comment';
import { CommentStateType, ICommentsResponse } from 'types/comment';

export const initialState: CommentStateType = {
  selectedComments: null,
  commentsLoading: false,
  commentsPage: 0,
};

export const commentSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getComments.fulfilled, (state: CommentStateType, action: PayloadAction<ICommentsResponse>) => ({
      ...state,
      selectedComments: action.payload,
      commentsLoading: false,
    }));
    builder.addCase(getComments.pending, (state: CommentStateType) => ({
      ...state,
      commentsLoading: true,
    }));
    builder.addCase(getComments.rejected, (state: CommentStateType) => ({
      ...state,
      selectedComments: null,
      commentsLoading: true,
    }));
  },
  initialState,
  name: '@comment',
  reducers: null,
});
