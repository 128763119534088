import React from 'react';

import { Box } from '@material-ui/core';

import Spinner from '../Spinner/Spinner';

export const FullScreenLoader = () => {
  return (
    <Box sx={{ display: 'flex', minHeight: '60vh', height: '100%' }}>
      <Spinner />
    </Box>
  );
};
