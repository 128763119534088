import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { verifyResetToken } from 'store/actions/auth';
import { VerifyStateType, VerifyTokenResponse } from 'types/auth';

export const initialState: VerifyStateType = {
  email: null,
};

export const verifySlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(
      verifyResetToken.fulfilled,
      (state: VerifyStateType, action: PayloadAction<VerifyTokenResponse>) => ({
        ...state,
        email: action.payload.email,
      }),
    );
  },
  initialState,
  name: '@verify',
  reducers: {},
});
