import React from 'react';

import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import HelpBillingIcon from 'components/shop/HelpBillingIcon/HelpBillingIcon';
import { colors } from 'constants/ui';
import { IBill } from 'types/bill';

const useStyles = makeStyles({
  container: {
    padding: '32px 24px 24px 60px',
    borderTop: '1px solid',
    borderColor: colors.LIGHT_GREY,
  },
  billingAddress: {
    backgroundColor: colors.WHITE_GREY,
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '20px',
    padding: '4px 12px 4px 24px',
    marginBottom: '8px',
    display: 'inline-flex',
    alignItems: 'center',
    color: colors.DARK_GREY,
  },
  attrsDescription: {
    color: colors.MIDDLE_GREY,
    fontSize: '12px',
    lineHeight: '20px',
  },
});

interface IBillExpandProps {
  item: IBill;
}

export const BillExpand: React.FC<IBillExpandProps> = ({ item }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Paper className={classes.billingAddress} elevation={0}>
        <span>Billing address:</span>
        <Box component="span" ml={1}>
          {item.partner_invoice_address}
        </Box>
        <HelpBillingIcon />
      </Paper>
      <TableContainer component={Paper} elevation={1}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Product</TableCell>
              <TableCell align="right">Total amount</TableCell>
              <TableCell align="right">Taxes & Fees</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {item.invoice_lines.map((line) => (
              <TableRow key={line.id}>
                <TableCell>
                  <div>{line.product_name}</div>
                  {line.attrs && <div className={classes.attrsDescription}>{line.attrs}</div>}
                </TableCell>
                <TableCell align="right">${line.subtotal}</TableCell>
                <TableCell align="right">${line.taxes}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
