import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { COLUMN_BILL_PERIOD, PERIOD_BILL_ANY, STATE_BILL_ALL } from 'constants/bill';
import { DEFAULT_COMPANY_KEY, SORT_ASC, SORT_DESC } from 'constants/common';
import { defaultRowsPerPage } from 'constants/table';
import { getBills } from 'store/actions/bill';
import { IBillFilers, IBillResponse, BillStateType } from 'types/bill';
import { IPagination, SortDirection } from 'types/table';

export const initialState: BillStateType = {
  billData: null,
  filters: { period: PERIOD_BILL_ANY, company_id: DEFAULT_COMPANY_KEY, stage: STATE_BILL_ALL },
  pagination: { rowsPerPage: defaultRowsPerPage, page: 0 },
  sort: { sort_column: COLUMN_BILL_PERIOD, sort_direction: SORT_DESC },
  loading: false,
};

export const billSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getBills.fulfilled, (state: BillStateType, action: PayloadAction<IBillResponse>) => ({
      ...state,
      billData: action.payload,
      loading: false,
    }));
    builder.addCase(getBills.pending, (state: BillStateType) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(getBills.rejected, (state: BillStateType) => ({
      ...state,
      billData: null,
      loading: false,
    }));
  },
  initialState,
  name: '@bill',
  reducers: {
    setFilters: (state: BillStateType, action: PayloadAction<IBillFilers>) => ({ ...state, filters: action.payload }),
    resetFilters: (state: BillStateType) => ({ ...state, filters: initialState.filters }),
    setPagination: (state: BillStateType, action: PayloadAction<IPagination>) => ({
      ...state,
      pagination: action.payload,
    }),
    updateSort: (state: BillStateType, action: PayloadAction<string>) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const sort_direction = (
        state.sort.sort_column === action.payload && state.sort.sort_direction === SORT_ASC ? SORT_DESC : SORT_ASC
      ) as SortDirection;
      return { ...state, sort: { sort_column: action.payload, sort_direction } };
    },
  },
});
