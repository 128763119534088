import React, { FC, useEffect } from 'react';

import { Redirect, Route, Router, Switch } from 'react-router-dom';

import { history } from './history';

import { AuthLayout, FullScreenLoader, MainLayout, RouterItem } from 'components';
import { AUTH_BASE_ROUTE, ROUTER_PATHS } from 'constants/routerPaths';
import { createPasswordRoute, mainRoutes, publicRoutes, resetPasswordRoute } from 'constants/routes';
import { useAppDispatch } from 'store';
import { checkAuth, getUserAction } from 'store/actions/auth';
import { useAppSelector } from 'store/store';

const Navigation: FC = () => {
  const dispatch = useAppDispatch();

  const { authLoading, userHasAccess, user } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (userHasAccess) {
      dispatch(checkAuth());
      if (!user) {
        dispatch(getUserAction());
      }
    }
  }, [dispatch, userHasAccess, user]);

  if (authLoading) {
    return <FullScreenLoader />;
  }

  if (!userHasAccess) {
    return (
      <Router history={history}>
        <AuthLayout>
          <Switch>
            <Route path={AUTH_BASE_ROUTE}>
              {publicRoutes.map(({ component, path, exact, pageTitle }) => (
                <RouterItem component={component} path={path} exact={exact} key={path} pageTitle={pageTitle} />
              ))}
            </Route>
            <RouterItem {...resetPasswordRoute} />
            <RouterItem {...createPasswordRoute} />
            <Redirect to={ROUTER_PATHS.signIn} />
          </Switch>
        </AuthLayout>
      </Router>
    );
  }

  return (
    <Router history={history}>
      <MainLayout>
        <Switch>
          {mainRoutes.map(({ component, path, exact, pageTitle }) => (
            <RouterItem component={component} path={path} exact={exact} key={path} pageTitle={pageTitle} isPrivate />
          ))}
          <Redirect exact from={resetPasswordRoute.path} to={ROUTER_PATHS.shopByProduct} />
          <Redirect exact from={createPasswordRoute.path} to={ROUTER_PATHS.shopByProduct} />
          <Redirect exact from="/" to={ROUTER_PATHS.shopByProduct} />
          <Redirect exact from="*" to={ROUTER_PATHS.notFoundPage} />
        </Switch>
      </MainLayout>
    </Router>
  );
};

export default Navigation;
