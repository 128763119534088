import { createAsyncThunk } from '@reduxjs/toolkit';

import { billApi } from 'api/billApi';
import { IBillFilers } from 'types/bill';
import { IPagination, ISort } from 'types/table';

type BillApiParams = {
  filters: IBillFilers;
  pagination: IPagination;
  sort: ISort;
};

export const getBills = createAsyncThunk('@bill/getBills', async ({ filters, pagination, sort }: BillApiParams) => {
  const { data } = await billApi.getBills(filters, pagination, sort);
  return data;
});
