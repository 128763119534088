import { createAsyncThunk } from '@reduxjs/toolkit';

import { dispatchApi } from 'api/dispatchApi';

type DispatchSlotSearchType = {
  dispatchId: number;
  date: string;
};

type DispatchSlotReserveType = {
  dispatchId: number;
  slotId: number;
};

export const getDispatches = createAsyncThunk('@dispatch/getDispatches', async () => {
  const { data } = await dispatchApi.getDispatches();
  return data;
});

export const getDispatchSlots = createAsyncThunk(
  '@dispatch/getDispatchSlots',
  async ({ dispatchId, date }: DispatchSlotSearchType) => {
    const { data } = await dispatchApi.getDispatchSlots(dispatchId, date);
    return data;
  },
);

export const reserveDispatchSlot = createAsyncThunk(
  '@dispatch/reserveDispatchSlots',
  async ({ dispatchId, slotId }: DispatchSlotReserveType) => {
    const { data } = await dispatchApi.reserveSlot(dispatchId, slotId);
    return data;
  },
);
