import React, { useCallback, useEffect } from 'react';

import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { useParams } from 'react-router-dom';

import styles from './SupportTicketDetailsPage.module.less';

import { BreadCrumbs, CommentsDetails, FullScreenLoader, TicketCloseDialog } from 'components';
import { PopupForm } from 'components/common/PopupForm/PopupForm';
import { StateIndicator } from 'components/common/StateIndicator/StateIndicator';
import { colors } from 'constants/ui';
import { useAppDispatch } from 'store';
import { getTicketDetails, setTicketAsClosed } from 'store/actions/ticket';
import { breadcrumbSlice } from 'store/reducers/breadcrumb/breadcrumb';
import { useAppSelector } from 'store/store';
import { getDate } from 'utils/utils';

const useStyles = makeStyles({
  attachment: {
    marginRight: '8px',
    height: '48px',
    backgroundColor: colors.WHITE_GREY,
  },
});

export const SupportTicketDetailsPage: React.FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const { ticketDetails, ticketLoading } = useAppSelector((state) => state.ticketData);
  const [closeDialogOpen, setCloseDialogOpen] = React.useState(false);
  const [surveyOpened, setSurveyOpened] = React.useState(false);

  useEffect(() => {
    if (!ticketLoading && !ticketDetails) {
      dispatch(getTicketDetails(id));
    }
  }, [dispatch, ticketLoading, ticketDetails, id]);

  useEffect(() => {
    dispatch(getTicketDetails(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (ticketDetails) {
      dispatch(breadcrumbSlice.actions.addBreadcrumb({ title: `Ticket #${id}`, url: `/support/${id}` }));
    }
  }, [dispatch, id, ticketDetails]);

  const closeTicket = useCallback(async () => {
    setCloseDialogOpen(false);
    await dispatch(setTicketAsClosed(id));
    await dispatch(getTicketDetails(id));
  }, [dispatch, id]);

  if (ticketLoading || !ticketDetails) return <FullScreenLoader />;

  return (
    <>
      <BreadCrumbs />
      <Box className={styles.pageContainer}>
        <TicketCloseDialog open={closeDialogOpen} onConfirm={closeTicket} onCancel={() => setCloseDialogOpen(false)} />
        <Typography variant="h2">{ticketDetails.subject}</Typography>
        <Paper style={{ padding: '24px 24px 36px 24px', marginTop: '28px', marginBottom: '20px' }}>
          <Box display="flex" marginBottom="30px">
            <AssignmentIcon style={{ marginRight: '15px' }} color="primary" />
            <span className={styles.mediumTextBold}>Ticket Details</span>
            <Box marginLeft="auto">
              <StateIndicator state={ticketDetails.status} />
              {!ticketDetails.is_closed && (
                <Button
                  onClick={() => setCloseDialogOpen(true)}
                  style={{ marginLeft: '28px' }}
                  variant="contained"
                  color="primary"
                >
                  Close Ticket
                </Button>
              )}
            </Box>
          </Box>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <span className={styles.tinyText}>Type</span>
                  </TableCell>
                  <TableCell>
                    <span className={styles.text}>{ticketDetails.ticket_type}</span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <span className={styles.tinyText}>Reported by</span>
                  </TableCell>
                  <TableCell>
                    <span className={styles.text}>{ticketDetails.reported_by}</span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <span className={styles.tinyText}>Reported on</span>
                  </TableCell>
                  <TableCell>
                    <span className={styles.text}>{getDate(ticketDetails.reported_on)}</span>
                  </TableCell>
                </TableRow>
                {ticketDetails.intrusive && (
                  <TableRow>
                    <TableCell>
                      <span className={styles.tinyText}>Intrusive testing allowed</span>
                    </TableCell>
                    <TableCell>
                      <span className={styles.text}>{ticketDetails.intrusive}</span>
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell colSpan={2}>
                    <p className={styles.tinyText}>Description</p>
                    <span className={styles.text}>{ticketDetails.description}</span>
                  </TableCell>
                </TableRow>
                {ticketDetails.survey && (
                  <TableRow>
                    <TableCell colSpan={2}>
                      <p className={styles.tinyText}>Technical details</p>
                      <PopupForm
                        url={ticketDetails.survey.survey_start_url}
                        opened={surveyOpened}
                        setOpened={setSurveyOpened}
                        actionAfterClosing={() => {
                          dispatch(getTicketDetails(id));
                        }}
                      />
                      <Button
                        style={{ padding: 0 }}
                        color="primary"
                        endIcon={<ArrowForwardIcon />}
                        onClick={() => setSurveyOpened(true)}
                      >
                        <span className={styles.smallText}>
                          {ticketDetails.survey.helpdesk_answer_state === 'done' ? 'View details' : 'Provide details'}
                        </span>
                      </Button>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        {ticketDetails.attachments && (
          <Paper style={{ padding: '24px 24px 36px 24px', marginBottom: '20px' }}>
            <Box display="flex" marginBottom="30px">
              <AttachFileIcon style={{ marginRight: '15px' }} color="primary" />
              <span className={styles.mediumTextBold}>Attachments</span>
            </Box>
            <Box display="inline-block">
              {ticketDetails.attachments.map((item) => (
                <Button
                  classes={{ root: classes.attachment }}
                  key={item.id}
                  variant="outlined"
                  color="primary"
                  href={item.download_link}
                >
                  <span className={styles.tinyMedium}>{item.name}</span>
                </Button>
              ))}
            </Box>
          </Paper>
        )}
        <CommentsDetails id={id} path="ticket" />
      </Box>
    </>
  );
};
