import React, { FC } from 'react';

import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { Link, useLocation } from 'react-router-dom';
import { withWhiteLabelContext } from 'react-whitelabel';

import { useTabsStyles } from './classes';

import { ROUTER_PATHS } from 'constants/routerPaths';

const AuthSwitcher: FC<{ label: any }> = (props) => {
  const location = useLocation();
  const tabsClasses = useTabsStyles();

  const { label } = props;
  const { name } = label;

  const authNavigationTabs = [
    { labelTitle: 'Sign in', value: ROUTER_PATHS.signIn, navigateTo: ROUTER_PATHS.signIn },
    { labelTitle: `New to ${name}?`, value: ROUTER_PATHS.signUp, navigateTo: ROUTER_PATHS.signUp },
  ];

  return (
    <Tabs value={location.pathname} variant="fullWidth">
      {authNavigationTabs.map(({ labelTitle, value, navigateTo }) => (
        <Tab label={labelTitle} value={value} key={value} component={Link} to={navigateTo} classes={tabsClasses} />
      ))}
    </Tabs>
  );
};

export default withWhiteLabelContext(AuthSwitcher);
