import React from 'react';

import { colors } from 'constants/ui';
import { IBill } from 'types/bill';

interface IBillStateIndicatorProps {
  item: IBill;
}

export const BillAmount: React.FC<IBillStateIndicatorProps> = ({ item }) => {
  let color = '';
  switch (item.stage) {
    case 'overdue': {
      color = colors.RED;
      break;
    }
    case 'due': {
      color = colors.PURPLE_HEART;
      break;
    }
    case 'credit': {
      color = colors.DODGER_BLUE;
      break;
    }
    default: {
      color = colors.GREEN;
      break;
    }
  }
  return <div style={{ color }}>${item.amount_due}</div>;
};
