import { ONLY_DIGITS_REGEXP, ONLY_LETTERS_REGEXP } from './constants';

export const onlyLettersValidator =
  (label = 'Field') =>
  (value: string | undefined): string | undefined => {
    let error;

    if (value && !ONLY_LETTERS_REGEXP.test(value)) {
      error = `${label} should contain only letters`;
    }

    return error;
  };

export const onlyDigitsValidator =
  (label = 'Field') =>
  (value: string | undefined): string | undefined => {
    let error;

    if (value && !ONLY_DIGITS_REGEXP.test(value)) {
      error = `${label} should contain only numbers`;
    }

    return error;
  };
