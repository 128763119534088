import { createAsyncThunk } from '@reduxjs/toolkit';

import { CommentsApiParams, NewCommentApiData } from '../../../types/comment';
import { IAttachFile } from '../../../types/common';

import { commentApi } from 'api/commentApi';

export const getComments = createAsyncThunk('@comment/getComments', async ({ path, id, page }: CommentsApiParams) => {
  const { data } = await commentApi.getComments(path, id, page);
  return data;
});

export const sendComment = createAsyncThunk(
  '@comment/sendComment',
  async ({ path, id, text, attachments }: NewCommentApiData) => {
    const { data } = await commentApi.sendComment(path, id, { text });

    attachments.forEach((attachment: IAttachFile) => {
      const formData = new FormData();
      formData.append('files', attachment.file);
      formData.append('message_id', data.message_id);
      commentApi.uploadCommentAttachment(path, id, formData);
    });

    return data;
  },
);
