import React from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import dialogTitle from 'assets/svg/warning_dialog_title.svg';

const useStyles = makeStyles({
  dialogQuestionText: {
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '28px',
    marginBottom: '12px',
  },
  dialogMessageText: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
  },
  dialogTitle: {
    padding: '28px 28px 12px 28px',
  },
  dialogContent: {
    padding: '0px 28px',
    marginBottom: '36px',
  },
  dialogActions: {
    padding: '0px 28px',
    marginBottom: '28px',
  },
});

interface IPageLeaveDialogProps {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export const TicketCloseDialog: React.FC<IPageLeaveDialogProps> = ({ open, onConfirm, onCancel }) => {
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle className={classes.dialogTitle}>
        <img src={dialogTitle} alt="" />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.dialogQuestionText}>Close ticket?</div>
        <div className={classes.dialogMessageText}>This action cannot be undone.</div>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button variant="contained" onClick={onCancel} color="secondary">
          Cancel
        </Button>
        <Button variant="contained" onClick={onConfirm} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
