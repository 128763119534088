import React, { useEffect, useState } from 'react';

import { Button, Divider, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowForward, Done, LocationOn } from '@material-ui/icons';
import { Link } from 'react-router-dom';

import styles from './BuyPage.module.less';

import { productApi } from 'api/productApi';
import Spinner from 'components/common/Spinner/Spinner';
import { ROUTER_PATHS } from 'constants/routerPaths';
import { colors } from 'constants/ui';
import { useAppDispatch } from 'store';
import { getCart } from 'store/actions/cart';
import { useAppSelector } from 'store/store';

const useStyles = makeStyles({
  locationRoot: {
    color: colors.GREY,
  },
});

export const AddToCartPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { productTemplate, productVariant, selectedPricing } = useAppSelector((state) => state.product);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (productVariant && selectedPricing) {
      productApi.addToCart(selectedPricing.id).then(() => {
        setLoading(false);
        dispatch(getCart());
      });
    }
  }, [dispatch, productVariant, selectedPricing]);

  if (loading)
    return (
      <div className={styles.pageContainer}>
        <Spinner />
      </div>
    );

  return (
    <div className={styles.pageContainer}>
      <div className={styles.doneIcon}>
        <Done fontSize="inherit" />
      </div>
      <div className={styles.productTitle}>
        <Typography variant="h2">{productTemplate.name}</Typography>
      </div>
      <div className={styles.pageDescription}>
        <Typography variant="h4">is added to your Cart</Typography>
      </div>
      <Paper>
        <div className={styles.pricingContent}>
          {productTemplate.variants.length > 1 && (
            <>
              <div className={styles.item}>
                {productTemplate.attributes.map((attr) => (
                  <div key={attr.id} className={styles.locationData}>
                    <span className={`${styles.pricePrefix} ${styles.tinyText}`}>{attr.name}:</span>
                    <span className={`${styles.priceValue} ${styles.tinyText}`}>
                      {productVariant.values.find((v) => v.attribute_id === attr.id).name}
                    </span>
                  </div>
                ))}
              </div>
              <Divider className={styles.divider} />
            </>
          )}
          <div className={styles.item}>
            {selectedPricing.locations.map((location) => (
              <div key={location.id} className={styles.locationData}>
                <LocationOn classes={{ root: classes.locationRoot }} />
                <span className={`${styles.address} ${styles.tinyText}`}>{location.complete_address}</span>
                {!productTemplate.is_non_quantitative && (
                  <div className={`${styles.quantity} ${styles.textMedium}`}>{location.quantity}</div>
                )}
              </div>
            ))}
          </div>
          <Divider className={styles.divider} />
          <div className={styles.item}>
            {productTemplate.duration_terms.length === 0 ? (
              <>
                <span className={`${styles.pricePrefix} ${styles.tinyText}`}>Total:</span>
                <span className={`${styles.priceValue} ${styles.tinyText}`}>${selectedPricing.price_total}</span>
              </>
            ) : (
              <>
                <span className={`${styles.pricePrefix} ${styles.tinyText}`}>Contract type:</span>
                <span className={`${styles.priceValue} ${styles.tinyText}`}>
                  {selectedPricing.duration_term} Months, ${selectedPricing.price_total}/month
                </span>
              </>
            )}
          </div>
          <div className={styles.checkoutButton}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              component={Link}
              to={ROUTER_PATHS.shoppingCart}
            >
              Check out
            </Button>
          </div>
        </div>
      </Paper>
      <div className={styles.shopButton}>
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          endIcon={<ArrowForward />}
          component={Link}
          to={ROUTER_PATHS.shopByProduct}
        >
          Continue shopping
        </Button>
      </div>
    </div>
  );
};
