import { Store } from '@reduxjs/toolkit';

class StoreRegistry {
  store: Store = null;

  getStore = () => this.store;

  register = (store: Store) => {
    this.store = store;
  };
}

export default new StoreRegistry();
