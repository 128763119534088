import React, { useEffect, useState } from 'react';

import { Location } from 'history';
import { Prompt, useHistory } from 'react-router-dom';

import { PageLeaveDialog } from 'components/index';

interface IRouteLeavingProps {
  when?: boolean | undefined;
  shouldBlockNavigation: (location: Location) => boolean;
}

const RouteLeaving: React.FC<IRouteLeavingProps> = ({ when, shouldBlockNavigation }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const history = useHistory();

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      history.push(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation, history]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <PageLeaveDialog open={modalVisible} onConfirm={handleConfirmNavigationClick} onCancel={closeModal} />
    </>
  );
};
export default RouteLeaving;
