import React, { FC, useEffect } from 'react';

import { Button, Card, CardContent, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';

import styles from './AccceptAndSignPage.module.less';

import { AcceptAndSignDialog, BreadCrumbs, FullScreenLoader, GridC } from 'components';
import { useAppDispatch } from 'store';
import { getAcceptAndSignData } from 'store/actions/order';
import { breadcrumbSlice } from 'store/reducers/breadcrumb/breadcrumb';
import { useAppSelector } from 'store/store';

const useStyles = makeStyles({
  priceTotal: {
    textAlign: 'center',
    marginBottom: '24px',
  },
  acceptButton: {
    width: '100%',
  },
});

export const AcceptAndSignPage: FC = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const { acceptAndSignData, acceptAndSignDataLoading } = useAppSelector((state) => state.order);
  const [openDialog, setOpenDialog] = React.useState(false);
  useEffect(() => {
    if (!acceptAndSignDataLoading) {
      dispatch(getAcceptAndSignData(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (acceptAndSignData) {
      dispatch(
        breadcrumbSlice.actions.setBreadcrumbs([
          { title: 'Orders', url: '/orders' },
          {
            title: `${acceptAndSignData.name}`,
            url: `/order/${id}/accept/sign`,
          },
        ]),
      );
    }
  }, [acceptAndSignData, dispatch, id]);

  if (acceptAndSignDataLoading) {
    return <FullScreenLoader />;
  }

  return (
    <>
      {acceptAndSignData && <BreadCrumbs />}
      <div className={styles.pageContainer}>
        <GridC container>
          <Grid item xs={3}>
            <Card>
              <CardContent>
                {acceptAndSignData && (
                  <>
                    <Typography classes={{ root: classes.priceTotal }} variant="h2">
                      $ {acceptAndSignData.price_total}
                    </Typography>
                    <Button
                      disabled={acceptAndSignData.signed}
                      onClick={() => setOpenDialog(true)}
                      classes={{ root: classes.acceptButton }}
                      variant="contained"
                      color="primary"
                    >
                      Accept and sign
                    </Button>
                    <AcceptAndSignDialog
                      id={id}
                      price_total={acceptAndSignData.price_total}
                      customer={acceptAndSignData.customer}
                      open={openDialog}
                      setOpen={setOpenDialog}
                      name={acceptAndSignData.name}
                    />
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid style={{ height: 'calc(100vh - 372px)' }} item xs={9}>
            {acceptAndSignData && (
              <iframe
                width="100%"
                height="100%"
                title="report"
                src={`data:application/pdf;base64,${acceptAndSignData.pdf}`}
              />
            )}
          </Grid>
        </GridC>
      </div>
    </>
  );
};
