/* eslint-disable react/display-name */

import React, { useEffect, useState } from 'react';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Table } from 'components';
import { BillAmount, BillBalance, BillExpand, BillFilters, BillRowActions, BillStateIndicator } from 'components/bills';
import {
  COLUMN_BILL_AMOUNT_DUE,
  COLUMN_BILL_AMOUNT_TOTAL,
  COLUMN_BILL_COMPANY_NAME,
  COLUMN_BILL_NAME,
  COLUMN_BILL_PAYMENT_STATE,
  COLUMN_BILL_PERIOD,
} from 'constants/bill';
import { useAppDispatch } from 'store';
import { getBills } from 'store/actions/bill';
import { billSlice } from 'store/reducers/bill/bill';
import { useAppSelector } from 'store/store';
import { IBill } from 'types/bill';
import { ITableColumn } from 'types/table';

const columns: ITableColumn<IBill>[] = [
  { displayName: 'Bill #', field: COLUMN_BILL_NAME, align: 'left', isSortable: true },
  { displayName: 'Delivery address', field: 'partner_address', align: 'left', isSortable: true },
  { displayName: 'Period', field: COLUMN_BILL_PERIOD, align: 'left', isSortable: true },
  {
    displayName: 'Invoice amount',
    field: COLUMN_BILL_AMOUNT_TOTAL,
    align: 'right',
    render: (item) => <>${item.amount_total}</>,
  },
  {
    displayName: 'Company Name',
    field: COLUMN_BILL_COMPANY_NAME,
    align: 'left',
  },
  {
    displayName: 'Status',
    field: COLUMN_BILL_PAYMENT_STATE,
    align: 'left',
    render: (item) => <BillStateIndicator item={item} />,
    isSortable: true,
  },
  {
    displayName: 'Amount due',
    field: COLUMN_BILL_AMOUNT_DUE,
    align: 'right',
    render: (item) => <BillAmount item={item} />,
  },
];

const useStyles = makeStyles({
  pageContainer: {
    padding: '72px 66px 64px',
  },
  headContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
  },
});

export const BillsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { loading, billData, filters, pagination, sort } = useAppSelector((state) => state.bill);
  const [opened, setOpened] = useState<Set<number>>(new Set());

  const updatePagination = (data: { rowsPerPage?: number; page?: number }) => {
    dispatch(billSlice.actions.setPagination({ ...pagination, ...data }));
  };

  const updateSort = (column: string) => {
    dispatch(billSlice.actions.updateSort(column));
  };

  useEffect(() => {
    if (!loading) {
      dispatch(getBills({ filters, pagination, sort }));
    }
  }, [dispatch, filters, pagination, sort]);

  return (
    <div className={classes.pageContainer}>
      <Box className={classes.headContainer}>
        <Typography variant="h1">Bills</Typography>
        <Box flexGrow={1} />
        <BillBalance />
      </Box>
      <Table
        name="bills"
        loading={loading}
        columns={columns}
        data={(billData && billData.invoices) || []}
        FilterComponent={BillFilters}
        renderExpand={(item: IBill) => <BillExpand item={item} />}
        renderActions={(item: IBill) => <BillRowActions item={item} />}
        openItemIds={opened}
        openItemUpdate={setOpened}
        updatePagination={updatePagination}
        pagination={pagination}
        sort={sort}
        updateSort={updateSort}
        count={(billData && billData.count) || 0}
      />
    </div>
  );
};
