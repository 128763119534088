import { createTheme } from '@material-ui/core/styles';

import { ApplicationFonts } from './assets/fonts/applicationFonts';
import { colors } from './constants/ui';

export const applicationThemeInfonetworks = createTheme({
  palette: {
    primary: {
      main: colors.PURPLE,
      contrastText: colors.WHITE,
    },
    secondary: {
      main: colors.PURPLE_10,
      contrastText: colors.PURPLE,
    },
    background: {
      default: colors.LIGHT_PURPLE,
    },
  },
  typography: {
    fontFamily: 'Montserrat',
    h1: {
      fontSize: '36px',
      fontWeight: 700,
      lineHeight: '60px',
    },
    h2: {
      fontSize: '28px',
      fontWeight: 700,
      lineHeight: '40px',
    },
    h3: {
      fontSize: '24px',
      fontWeight: 400,
      lineHeight: '40px',
    },
    h4: {
      fontSize: '20px',
      fontWeight: 400,
      lineHeight: '36px',
    },
    caption: {
      fontSize: '10px',
      fontWeight: 600,
      lineHeight: '20px',
    },
    body1: {
      fontWeight: 400,
      fontSize: '20px',
      lineHeight: '36px',
    },
    allVariants: {
      color: colors.DARK,
    },
  },
  props: {
    MuiButton: {
      disableElevation: true,
    },
    MuiPaper: {
      elevation: 2,
    },
    MuiCard: {
      elevation: 2,
    },
  },
  overrides: {
    MuiTypography: {
      root: {
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '24px',
      },
      paragraph: {
        marginBottom: '20px',
      },
    },
    MuiCssBaseline: {
      '@global': {
        '@font-face': ApplicationFonts,
      },
    },
    MuiTabs: {
      indicator: {
        'background-color': colors.VIOLET,
      },
    },
    MuiTableCell: {
      root: {
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '24px',
      },
      head: {
        fontWeight: 600,
      },
      body: {
        color: colors.DARK_GREY,
      },
    },
    MuiFormControl: {
      root: {
        width: '100%',
        'margin-bottom': '32px',
      },
    },
    MuiTouchRipple: {
      root: {
        color: colors.WHITE,
      },
    },
    MuiButton: {
      root: {
        'text-transform': 'none',
        boxShadow: 'none',
        padding: '10px 32px',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '28px',
        color: colors.PURPLE,
      },
      containedPrimary: {
        '&:hover': {
          backgroundColor: colors.PURPLE_HOV,
        },
        '&:focus': {
          backgroundColor: colors.PURPLE_FOC,
        },
        '&.Mui-disabled': {
          color: colors.DARK_40,
          backgroundColor: colors.LIGHT_GREY,
        },
      },
      containedSecondary: {
        '&:hover': {
          backgroundColor: colors.PURPLE_18,
        },
        '&:focus': {
          backgroundColor: colors.PURPLE_26,
        },
        '&.Mui-disabled': {
          color: colors.DARK_40,
          backgroundColor: colors.WHITE_GREY,
        },
      },
      sizeLarge: {
        padding: '14px 32px',
        fontWeight: 500,
        fontSize: '18px',
        lineHeight: '28px',
      },
      text: {
        padding: '10px 20px',
        '&:hover': {
          backgroundColor: colors.PURPLE_5,
        },
        '&:focus': {
          backgroundColor: colors.PURPLE_10,
        },
        '& .MuiTouchRipple-root': {
          color: colors.PURPLE_8,
        },
      },
    },
    MuiPaper: {
      // Small Shadow
      elevation1: {
        boxShadow:
          '0px 0px 2px rgba(54, 123, 236, 0.1), 0px 2px 2px rgba(54, 123, 236, 0.08), 0px 1px 3px rgba(54, 123, 236, 0.16)',
      },
      // Card Panels
      elevation2: {
        boxShadow:
          '0px 24px 38px rgba(100, 140, 206, 0.06), 0px 9px 46px rgba(100, 140, 206, 0.04), 0px 0px 15px rgba(100, 140, 206, 0.08)',
      },
      // Tooltip
      elevation5: {
        boxShadow:
          '0px 5px 8px rgba(100, 111, 206, 0.07), 0px 6px 16px rgba(100, 111, 206, 0.12), 0px 0px 4px rgba(100, 111, 206, 0.2)',
      },
      // Drop down
      elevation7: {
        boxShadow: '0px 6px 6px rgba(0, 0, 0, 0.08), 0px 10px 20px rgba(0, 0, 0, 0.06)',
      },
    },
    MuiCard: {
      root: {
        '&:hover': {
          // Cards Hover
          boxShadow:
            '0px 32px 38px rgba(100, 140, 206, 0.1), 0px 16px 46px rgba(100, 140, 206, 0.08), 0px 0px 15px rgba(100, 140, 206, 0.08)',
        },
      },
    },
    MuiStepLabel: {
      label: {
        fontWeight: 500,
        fontSize: '18px',
        lineHeight: '28px',
        '&$active': {
          fontWeight: 700,
        },
      },
      iconContainer: {
        paddingRight: '28px',
      },
    },
    MuiStepContent: {
      root: {
        marginLeft: '20px',
        paddingLeft: '48px',
        paddingBottom: '0px',
      },
    },
    MuiStepConnector: {
      vertical: {
        marginLeft: '20px',
      },
      lineVertical: {
        minHeight: '84px',
      },
    },
    MuiChip: {
      root: {
        backgroundColor: colors.WHITE_GREY,
        color: colors.DARK_GREY,
        fontWeight: 500,
        fontSize: '13px',
        lineHeight: '20px',
        '&.Mui-disabled': {
          color: colors.DARK_40,
          backgroundColor: colors.WHITE_GREY,
        },
        height: '40px',
        padding: '10px 20px',
        marginRight: '8px',
        borderRadius: '20px',
      },
      label: {
        paddingLeft: 0,
        paddingRight: 0,
      },
      sizeSmall: {
        height: '32px',
        padding: '12px 6px',
      },
      clickable: {
        '&:hover': {
          backgroundColor: colors.LIGHT_GREY,
        },
      },
      colorPrimary: {
        backgroundColor: colors.LINES_PURPLE,
        color: colors.DARK_VIOLET,
        '$clickableColorPrimary&:focus': {
          backgroundColor: colors.LINES_PURPLE,
        },
        '$clickableColorPrimary&:hover': {
          backgroundColor: colors.MIDDLE_PURPLE,
        },
      },
    },
    MuiInputAdornment: {
      root: {
        color: colors.GREY,
      },
      positionStart: {
        marginLeft: '8px',
      },
    },
    MuiInputLabel: {
      outlined: {
        zIndex: 0,
      },
    },
  },
});

export const applicationThemeCybernet = createTheme({
  palette: {
    primary: {
      main: colors.BLUE,
      contrastText: colors.WHITE,
    },
    secondary: {
      main: colors.BLUE_10,
      contrastText: colors.BLUE,
    },
    background: {
      default: colors.WHITE_BLUE,
    },
  },
  typography: {
    fontFamily: 'Montserrat',
    h1: {
      fontSize: '36px',
      fontWeight: 700,
      lineHeight: '60px',
    },
    h2: {
      fontSize: '28px',
      fontWeight: 700,
      lineHeight: '40px',
    },
    h3: {
      fontSize: '24px',
      fontWeight: 400,
      lineHeight: '40px',
    },
    h4: {
      fontSize: '20px',
      fontWeight: 400,
      lineHeight: '36px',
    },
    caption: {
      fontSize: '10px',
      fontWeight: 600,
      lineHeight: '20px',
    },
    body1: {
      fontWeight: 400,
      fontSize: '20px',
      lineHeight: '36px',
    },
    allVariants: {
      color: colors.DARK,
    },
  },
  props: {
    MuiButton: {
      disableElevation: true,
    },
    MuiPaper: {
      elevation: 2,
    },
    MuiCard: {
      elevation: 2,
    },
  },
  overrides: {
    MuiTypography: {
      root: {
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '24px',
      },
      paragraph: {
        marginBottom: '20px',
      },
    },
    MuiCssBaseline: {
      '@global': {
        '@font-face': ApplicationFonts,
      },
    },
    MuiTabs: {
      indicator: {
        'background-color': colors.BRIGHT_BLUE,
      },
    },
    MuiTableCell: {
      root: {
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '24px',
      },
      head: {
        fontWeight: 600,
      },
      body: {
        color: colors.DARK_GREY,
      },
    },
    MuiFormControl: {
      root: {
        width: '100%',
        'margin-bottom': '32px',
      },
    },
    MuiTouchRipple: {
      root: {
        color: colors.WHITE,
      },
    },
    MuiButton: {
      root: {
        'text-transform': 'none',
        boxShadow: 'none',
        padding: '10px 32px',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '28px',
        color: colors.BLUE,
      },
      containedPrimary: {
        '&:hover': {
          backgroundColor: colors.BLUE_HOV,
        },
        '&:focus': {
          backgroundColor: colors.BLUE_FOC,
        },
        '&.Mui-disabled': {
          color: colors.DARK_40,
          backgroundColor: colors.LIGHT_GREY,
        },
      },
      containedSecondary: {
        '&:hover': {
          backgroundColor: colors.BLUE_18,
        },
        '&:focus': {
          backgroundColor: colors.BLUE_26,
        },
        '&.Mui-disabled': {
          color: colors.DARK_40,
          backgroundColor: colors.WHITE_GREY,
        },
      },
      sizeLarge: {
        padding: '14px 32px',
        fontWeight: 500,
        fontSize: '18px',
        lineHeight: '28px',
      },
      text: {
        padding: '10px 20px',
        '&:hover': {
          backgroundColor: colors.BLUE_5,
        },
        '&:focus': {
          backgroundColor: colors.BLUE_10,
        },
        '& .MuiTouchRipple-root': {
          color: colors.BLUE_8,
        },
      },
    },
    MuiPaper: {
      // Small Shadow
      elevation1: {
        boxShadow:
          '0px 0px 2px rgba(54, 123, 236, 0.1), 0px 2px 2px rgba(54, 123, 236, 0.08), 0px 1px 3px rgba(54, 123, 236, 0.16)',
      },
      // Card Panels
      elevation2: {
        boxShadow:
          '0px 24px 38px rgba(100, 140, 206, 0.06), 0px 9px 46px rgba(100, 140, 206, 0.04), 0px 0px 15px rgba(100, 140, 206, 0.08)',
      },
      // Tooltip
      elevation5: {
        boxShadow:
          '0px 5px 8px rgba(100, 111, 206, 0.07), 0px 6px 16px rgba(100, 111, 206, 0.12), 0px 0px 4px rgba(100, 111, 206, 0.2)',
      },
      // Drop down
      elevation7: {
        boxShadow: '0px 6px 6px rgba(0, 0, 0, 0.08), 0px 10px 20px rgba(0, 0, 0, 0.06)',
      },
    },
    MuiCard: {
      root: {
        '&:hover': {
          // Cards Hover
          boxShadow:
            '0px 32px 38px rgba(100, 140, 206, 0.1), 0px 16px 46px rgba(100, 140, 206, 0.08), 0px 0px 15px rgba(100, 140, 206, 0.08)',
        },
      },
    },
    MuiStepLabel: {
      label: {
        fontWeight: 500,
        fontSize: '18px',
        lineHeight: '28px',
        '&$active': {
          fontWeight: 700,
        },
      },
      iconContainer: {
        paddingRight: '28px',
      },
    },
    MuiStepContent: {
      root: {
        marginLeft: '20px',
        paddingLeft: '48px',
        paddingBottom: '0px',
      },
    },
    MuiStepConnector: {
      vertical: {
        marginLeft: '20px',
      },
      lineVertical: {
        minHeight: '84px',
      },
    },
    MuiChip: {
      root: {
        backgroundColor: colors.WHITE_GREY,
        color: colors.DARK_GREY,
        fontWeight: 500,
        fontSize: '13px',
        lineHeight: '20px',
        '&.Mui-disabled': {
          color: colors.DARK_40,
          backgroundColor: colors.WHITE_GREY,
        },
        height: '40px',
        padding: '10px 20px',
        marginRight: '8px',
        marginBottom: '8px',
        borderRadius: '20px',
      },
      label: {
        paddingLeft: 0,
        paddingRight: 0,
      },
      sizeSmall: {
        height: '32px',
        padding: '12px 6px',
      },
      clickable: {
        '&:hover': {
          backgroundColor: colors.LIGHT_GREY,
        },
      },
      colorPrimary: {
        backgroundColor: colors.LINES_BLUE,
        color: colors.DARK_BLUE,
        '$clickableColorPrimary&:focus': {
          backgroundColor: colors.LINES_BLUE,
        },
        '$clickableColorPrimary&:hover': {
          backgroundColor: colors.MIDDLE_BLUE,
        },
      },
    },
    MuiInputAdornment: {
      root: {
        color: colors.GREY,
      },
      positionStart: {
        marginLeft: '8px',
      },
    },
    MuiInputLabel: {
      outlined: {
        zIndex: 0,
      },
    },
  },
});
