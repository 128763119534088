import React from 'react';

import { Box } from '@material-ui/core';

import { StatusBage } from 'components/common/StatusBage/StatusBage';
import { SlotSelectButton } from 'components/dispatch/SlotSelectButton';
import { DispatchType } from 'types/dispatch';
import { ITableColumn } from 'types/table';
import { getDate } from 'utils/utils';

export const TABLE_COLUMNS_DISPATCH: ITableColumn<DispatchType>[] = [
  { displayName: 'Title', field: 'name', align: 'left' },
  {
    displayName: 'Customer',
    field: 'customer_name',
    align: 'left',
  },
  {
    displayName: 'Planned date and time',
    field: 'planned',
    align: 'left',
    render: (item) => (
      <Box display="flex" alignItems="center">
        {!item.planned.date_from && !item.planned.date_from ? (
          'Date/time is not selected'
        ) : (
          <Box>
            <Box>{(item.planned.date_from && getDate(item.planned.date_from)) || '...'}</Box>
            <Box>{(item.planned.date_to && getDate(item.planned.date_to)) || '...'}</Box>
          </Box>
        )}
        <Box>
          <SlotSelectButton dispatchId={item.id} />
        </Box>
      </Box>
    ),
  },
  {
    displayName: 'Start Date',
    field: 'start_date',
    align: 'left',
    render: (item) => (item.start_date && getDate(item.start_date)) || '...',
  },
  {
    displayName: 'End Date',
    field: 'end_date',
    align: 'left',
    render: (item) => (item.end_date && getDate(item.end_date)) || '...',
  },
  {
    displayName: 'Status',
    field: 'stage',
    align: 'left',
    render: (item) => <StatusBage name={item.stage.name} decoration={item.stage.decoration} />,
  },
  {
    displayName: 'Service Order',
    field: 'order',
    align: 'left',
    render: (item) => item.order.id !== 0 && item.order.name,
  },
];
