import React, { useEffect, useState } from 'react';

import { Button, Divider, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowForward, Done, LocationOn } from '@material-ui/icons';
import { Link } from 'react-router-dom';

import styles from './BuyPage.module.less';

import { orderApi } from 'api/orderApi';
import Spinner from 'components/common/Spinner/Spinner';
import { ROUTER_PATHS } from 'constants/routerPaths';
import { colors } from 'constants/ui';
import { useAppSelector } from 'store/store';
import { ILocationData } from 'types/product';

const useStyles = makeStyles({
  locationRoot: {
    color: colors.GREY,
  },
});

const prepareOrderData = (variant_id: number, duration_term: number, location: ILocationData) => {
  const products = [];
  if (duration_term > 0) {
    products.push({ duration_term, product_id: variant_id, quantity: location.quantity });
  } else {
    products.push({ product_id: variant_id, quantity: location.quantity });
  }
  return { products, location: location.address };
};

export const NewQuotePage: React.FC = () => {
  const classes = useStyles();
  const { productTemplate, productVariant, locations } = useAppSelector((state) => state.product);
  const { newOrder } = useAppSelector((state) => state.order);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const data: any = {
      sale_orders: newOrder.locations.map((locationData) => {
        return prepareOrderData(productVariant.id, newOrder.duration_term, locationData);
      }),
    };
    orderApi.createOrder(data).then(() => {
      setLoading(false);
    });
  }, [productVariant, newOrder]);

  if (loading || !productVariant || !productTemplate)
    return (
      <div className={styles.pageContainer}>
        <Spinner />
      </div>
    );

  return (
    <div className={styles.pageContainer}>
      <div className={styles.doneIcon}>
        <Done fontSize="inherit" />
      </div>
      <div className={styles.productTitle}>
        <Typography variant="h2">{productTemplate.name}</Typography>
      </div>
      <div className={styles.pageDescription}>
        <Typography variant="h4">is added to the list of quotes</Typography>
      </div>
      <Paper>
        <div className={styles.pricingContent}>
          {productTemplate.variants.length > 1 && (
            <>
              <div className={styles.item}>
                {productTemplate.attributes.map((attr) => (
                  <div key={attr.id} className={styles.locationData}>
                    <span className={`${styles.pricePrefix} ${styles.tinyText}`}>{attr.name}:</span>
                    <span className={`${styles.priceValue} ${styles.tinyText}`}>
                      {productVariant.values.find((v) => v.attribute_id === attr.id).name}
                    </span>
                  </div>
                ))}
              </div>
              <Divider className={styles.divider} />
            </>
          )}
          <div className={styles.item}>
            {locations.map((location) => {
              return (
                <div key={location.id} className={styles.locationData}>
                  <LocationOn classes={{ root: classes.locationRoot }} />
                  <span className={`${styles.address} ${styles.tinyText}`}>{location.address.complete_address}</span>
                  {!productTemplate.is_non_quantitative && (
                    <div className={`${styles.quantity} ${styles.textMedium}`}>{location.quantity}</div>
                  )}
                </div>
              );
            })}
          </div>
          <div className={styles.checkoutButton}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              component={Link}
              to={ROUTER_PATHS.orders}
            >
              Go to quotes page
            </Button>
          </div>
        </div>
      </Paper>
      <div className={styles.shopButton}>
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          endIcon={<ArrowForward />}
          component={Link}
          to={ROUTER_PATHS.shopByProduct}
        >
          Continue shopping
        </Button>
      </div>
    </div>
  );
};
