import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { isProductionMode } from 'constants/common';
import { rootReducer } from 'store/reducers';
import storeRegistry from 'storeRegistry';

export const store = configureStore({
  devTools: !isProductionMode,
  reducer: rootReducer,
});

storeRegistry.register(store);

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof rootReducer>;
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
