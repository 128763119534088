import React, { FC } from 'react';

import { Link } from 'react-router-dom';

import styles from './NavigationLink.module.less';

interface INavigationLinkProps {
  navigateTo: string;
  linkText: string;
}

export const NavigationLink: FC<INavigationLinkProps> = ({ navigateTo, linkText }) => (
  <Link to={navigateTo} className={styles.link}>
    {linkText}
  </Link>
);
