import React, { useEffect } from 'react';

import { ClickAwayListener, Grid, Paper } from '@material-ui/core';
import { Link } from 'react-router-dom';

import styles from './MegaMenu.module.less';

import { GridC, Spinner } from 'components/index';
import { useAppDispatch } from 'store';
import { getCategories } from 'store/actions/category';
import { useAppSelector } from 'store/store';

interface IMegaMenuProps {
  handleClose: () => void;
}

export const MegaMenu: React.FC<IMegaMenuProps> = ({ handleClose }) => {
  const dispatch = useAppDispatch();
  const { loading, categories } = useAppSelector((state) => state.category);

  useEffect(() => {
    if (!loading && categories.length === 0) {
      dispatch(getCategories());
    }
  }, [dispatch, loading, categories]);

  if (loading) return <Spinner />;

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Paper className={styles.mainCategories} square elevation={7}>
        <GridC container>
          {categories.map((category) => (
            <Grid item xs={3} key={category.id}>
              <Link to={`/shop/category/${category.id}`} onClick={handleClose}>
                <div className={styles.categoryTitle}>{category.name}</div>
              </Link>
              {category.child_categories
                .filter((item) => item.products.length > 0)
                .map((child) => (
                  <div key={child.id} className={styles.categoryName}>
                    <Link to={`/shop/category/${child.id}`} onClick={handleClose} className={styles.categoryLink}>
                      {child.name}
                    </Link>
                  </div>
                ))}
            </Grid>
          ))}
        </GridC>
      </Paper>
    </ClickAwayListener>
  );
};
