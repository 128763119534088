import React, { FC, useEffect } from 'react';

import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';

import styles from './CategoryPage.module.less';

import { BreadCrumbs, GridC, ProductCard, Spinner } from 'components';
import { useAppDispatch } from 'store';
import { getCategory } from 'store/actions/category';
import { breadcrumbSlice } from 'store/reducers/breadcrumb/breadcrumb';
import { useAppSelector } from 'store/store';

const useStyles = makeStyles({
  nestedCategoryTitle: {
    marginBottom: '24px',
  },
});

export const CategoryPage: FC = (): JSX.Element => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();

  const { category, loading } = useAppSelector((state) => state.category);

  useEffect(() => {
    dispatch(getCategory(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (!loading && category) {
      dispatch(
        breadcrumbSlice.actions.setBreadcrumbs([{ title: 'Shop by Product', url: '/shop/' }, ...category.hierarchy]),
      );
    }
  }, [dispatch, category, loading]);

  if (loading || !category) return <Spinner />;

  const rootCategoryProducts = category.products.filter((product) => product.categ_id === category.id);

  return (
    <>
      <BreadCrumbs />
      <div className={styles.pageContainer}>
        <Typography variant="h1">{category.name}</Typography>
        <div className={styles.categoryWrapper}>
          {rootCategoryProducts.length > 0 && (
            <div className={styles.productsWrapper}>
              <Typography variant="h3" className={classes.nestedCategoryTitle}>
                {category.name}
              </Typography>
              <GridC container>
                {rootCategoryProducts.map((product) => (
                  <Grid item xs={4} key={product.id}>
                    <ProductCard product={product} />
                  </Grid>
                ))}
              </GridC>
            </div>
          )}
          {category.child_categories
            .filter((child) => child.products.length > 0)
            .map((child) => (
              <div key={child.id} className={styles.productsWrapper}>
                <Typography variant="h3" className={classes.nestedCategoryTitle}>
                  {child.name}
                </Typography>
                <GridC container>
                  {category.products
                    .filter((product) => product.categ_id === child.id)
                    .map((product) => (
                      <Grid item xs={4} key={product.id}>
                        <ProductCard product={product} />
                      </Grid>
                    ))}
                </GridC>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};
