import React from 'react';

import { Box } from '@material-ui/core';

interface IStatusBageProps {
  name: string;
  decoration: 'variant_a' | 'variant_b' | 'variant_c' | 'variant_d';
}

export const StatusBage: React.FC<IStatusBageProps> = ({ name, decoration }) => {
  let bgcolor = '#4E2FC81A';
  let color = '#4E2FC8';

  if (decoration === 'variant_b') {
    bgcolor = '#FF7A001A';
    color = '#FF7A00';
  } else if (decoration === 'variant_c') {
    bgcolor = '#0093B41A';
    color = '#0093B4';
  } else if (decoration === 'variant_d') {
    bgcolor = '#009D811A';
    color = '#009D81';
  }

  return (
    <Box
      bgcolor={bgcolor}
      color={color}
      paddingLeft="10px"
      paddingRight="10px"
      paddingTop="5px"
      paddingBottom="5px"
      borderRadius="4px"
      width="auto"
      display="inline"
    >
      {name}
    </Box>
  );
};
