import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IBreadCrumbItem } from 'types/breadcrumbs';

export const breadcrumbSlice = createSlice({
  name: '@breadcrumb',
  initialState: [],
  reducers: {
    setBreadcrumbs: (state: IBreadCrumbItem[], action: PayloadAction<IBreadCrumbItem[]>) => action.payload,
    addBreadcrumb: (state: IBreadCrumbItem[], action: PayloadAction<IBreadCrumbItem>) => {
      if (state.filter((item) => item.title === action.payload.title).length === 0) state.push(action.payload);
      return state;
    },
  },
});
