import React from 'react';

import {
  COLUMN_TICKET_ADDRESS,
  COLUMN_TICKET_COMPANY_NAME,
  COLUMN_TICKET_ID,
  COLUMN_TICKET_REPORTED_BY,
  COLUMN_TICKET_REPORTED_ON,
  COLUMN_TICKET_STATUS,
  COLUMN_TICKET_SUBJECT,
  COLUMN_TICKET_TYPE,
} from './support';

import { StateIndicator } from 'components/common/StateIndicator/StateIndicator';
import { ITableColumn } from 'types/table';
import { Ticket } from 'types/ticket';
import { getDate } from 'utils/utils';

export const TABLE_COLUMNS_TICKET: ITableColumn<Ticket>[] = [
  { displayName: '#', align: 'left', field: COLUMN_TICKET_ID, isSortable: true, render: (item) => item.id },
  {
    displayName: 'Type',
    align: 'left',
    field: COLUMN_TICKET_TYPE,
    isSortable: true,
    render: (item) => item.ticket_type,
  },
  { displayName: 'Subject', align: 'left', field: COLUMN_TICKET_SUBJECT, render: (item) => item.subject },
  {
    displayName: 'Service address',
    align: 'left',
    field: COLUMN_TICKET_ADDRESS,
    render: (item) => item.service_address,
  },
  {
    displayName: 'Company',
    align: 'left',
    field: COLUMN_TICKET_COMPANY_NAME,
    render: (item) => item.company_name,
  },
  {
    displayName: 'Status',
    align: 'left',
    field: COLUMN_TICKET_STATUS,
    isSortable: true,
    render: (item) => <StateIndicator state={item.status} />,
  },
  {
    displayName: 'Reported by',
    align: 'left',
    field: COLUMN_TICKET_REPORTED_BY,
    isSortable: true,
    render: (item) => item.reported_by,
  },
  {
    displayName: 'Reported on',
    align: 'left',
    field: COLUMN_TICKET_REPORTED_ON,
    isSortable: true,
    render: (item) => getDate(item.reported_on),
  },
];
