import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { colors } from 'constants/ui';
import { IBill } from 'types/bill';

const useStyles = makeStyles({
  label: {
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: '10px',
    lineHeight: '16px',
    letterSpacing: '1px',
  },
  marker: {
    fontSize: '16px',
  },
});

interface IBillStateIndicatorProps {
  item: IBill;
}

export const BillStateIndicator: React.FC<IBillStateIndicatorProps> = ({ item }) => {
  const classes = useStyles();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  let text_label: string = item.stage;
  let color = '';
  switch (item.stage) {
    case 'overdue': {
      if (item.stage_description === 1) {
        text_label = '1 day overdue';
      } else {
        text_label = `${item.stage_description} days overdue`;
      }
      color = colors.RED;
      break;
    }
    case 'due': {
      if (item.stage_description === 0) {
        text_label = 'due today';
      } else if (item.stage_description === 1) {
        text_label = 'due in 1 day';
      } else {
        text_label = `due in ${item.stage_description} days`;
      }
      color = colors.PURPLE_HEART;
      break;
    }
    case 'credit': {
      color = colors.DODGER_BLUE;
      break;
    }
    default: {
      text_label = item.stage.replace('_', ' ');
      color = colors.GREEN;
      break;
    }
  }
  return (
    <div className={classes.label} style={{ color }}>
      <span className={classes.marker}>●</span> {text_label}
    </div>
  );
};
