import React, { FC, useState } from 'react';

import { ClickAwayListener, IconButton, Paper, Popper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Help } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { withWhiteLabelContext } from 'react-whitelabel';

import { ROUTER_PATHS } from 'constants/routerPaths';
import { colors } from 'constants/ui';

const useStyles = makeStyles({
  billingHelpIcon: {
    color: colors.GREY,
    marginLeft: '12px',
  },
  tooltip: {
    padding: '6px 16px',
    color: colors.DARK_GREY,
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '20px',
    marginBottom: '8px',
  },
});

const HelpBillingIcon: FC<{ label: any }> = (props): JSX.Element => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openHelp, setOpenHelp] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenHelp((prev) => !prev);
  };

  const { label } = props;
  const { emailPostfix, categoryLinkColor } = label;

  return (
    <>
      <ClickAwayListener onClickAway={() => setOpenHelp(false)}>
        <IconButton className={classes.billingHelpIcon} onClick={handleClick} size="small">
          <Help />
        </IconButton>
      </ClickAwayListener>
      <Popper open={openHelp} anchorEl={anchorEl} placement="top-start">
        <Paper className={classes.tooltip} elevation={5}>
          You can change billing address via{' '}
          <Link style={{ color: categoryLinkColor }} target="_blank" to={ROUTER_PATHS.supportTickets}>
            HelpDesk ticket
          </Link>{' '}
          or by{' '}
          <a style={{ color: categoryLinkColor }} href={`mailto:support@${emailPostfix}.com`}>
            contact
          </a>{' '}
          our support team
        </Paper>
      </Popper>
    </>
  );
};

export default withWhiteLabelContext(HelpBillingIcon);
