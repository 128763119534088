import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { getDispatches } from 'store/actions/dispatch';
import { getDispatchSlots } from 'store/actions/dispatch/dispatch';
import { DispatchResponse, DispatchSlot, DispatchStateType } from 'types/dispatch';

export const initialState: DispatchStateType = {
  records: [],
  recordsLoading: false,
  availableSlots: [],
  slotsLoading: false,
};

export const dispatchSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getDispatches.fulfilled, (state: DispatchStateType, action: PayloadAction<DispatchResponse>) => ({
      ...state,
      records: action.payload.records,
      recordsLoading: false,
    }));
    builder.addCase(getDispatches.pending, (state: DispatchStateType) => ({
      ...state,
      records: [],
      recordsLoading: true,
    }));
    builder.addCase(getDispatches.rejected, (state: DispatchStateType) => ({
      ...state,
      records: [],
      recordsLoading: false,
    }));
    builder.addCase(getDispatchSlots.fulfilled, (state: DispatchStateType, action: PayloadAction<DispatchSlot[]>) => ({
      ...state,
      availableSlots: action.payload,
      slotsLoading: false,
    }));
    builder.addCase(getDispatchSlots.pending, (state: DispatchStateType) => ({
      ...state,
      availableSlots: [],
      slotsLoading: true,
    }));
    builder.addCase(getDispatchSlots.rejected, (state: DispatchStateType) => ({
      ...state,
      availableSlots: [],
      slotsLoading: false,
    }));
  },
  initialState,
  name: '@dispatch',
  reducers: {},
});
