import React, { FC } from 'react';

import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import logoNotFound from 'assets/svg/404_image.svg';
import { colors } from 'constants/ui';
import { NavigationService } from 'services/navigationService';

const useStyles = makeStyles({
  rootBackground: {
    background: `url(${logoNotFound})`,
    height: 'calc(100vh - 80px - 210px)',
    backgroundAttachment: 'fixed',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% auto',
  },
  messageBlock: {
    textAlign: 'center',
    position: 'relative',
    top: '64%',
  },
  text: {
    color: colors.WHITE,
  },
  buttonRoot: {
    marginTop: '32px',
  },
});

export const NotFoundLayout: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.rootBackground}>
      <Box className={classes.messageBlock}>
        <Typography variant="h4" className={classes.text}>
          The page you looking for could not be found
        </Typography>
        <Button
          onClick={() => NavigationService.navigateToShopByProduct()}
          className={classes.buttonRoot}
          variant="contained"
          color="primary"
        >
          Go home
        </Button>
      </Box>
    </div>
  );
};
