import React, { useMemo } from 'react';

import { FormControl, InputAdornment, OutlinedInput, debounce } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles({
  inputForm: {
    width: '256px',
    marginBottom: '0px',
  },
  outlinedInput: {
    padding: '12px 16px',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
  },
});

interface ISearchFieldProps {
  value: any;
  onChange: (value: string | undefined) => void;
}

export const SearchField: React.FC<ISearchFieldProps> = ({ value, onChange }) => {
  const classes = useStyles();

  const getDelayedUpdate = useMemo(
    () =>
      debounce((input: string | undefined) => {
        onChange(input);
      }, 300),
    [onChange],
  );

  const handleSearchInput = () => (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValueLength = event.target.value.length;
    if (searchValueLength === 0 || searchValueLength > 2) {
      getDelayedUpdate(event.target.value);
    }
  };

  return (
    <FormControl className={classes.inputForm} variant="outlined">
      <OutlinedInput
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
        defaultValue={value}
        onChange={handleSearchInput()}
        placeholder="Search"
        classes={{ input: classes.outlinedInput }}
      />
    </FormControl>
  );
};
