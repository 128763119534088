import React, { FC } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import { checkFieldError } from './helper';

import { ICommonInputFieldProps } from 'types/inputField';

const useStyles = makeStyles({
  inputRoot: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 500,
  },
  inputRootLabel: {
    fontSize: '12px',
    lineHeight: '20px',
    fontWeight: 500,
  },
});

export const TextAreaField: FC<ICommonInputFieldProps> = ({
  variant = 'outlined',
  id,
  label,
  placeholder,
  // maxLength,
  input,
  meta,
  // helper,
}) => {
  const classes = useStyles();
  const isErrorField = checkFieldError(meta);
  // const CHARACTER_LIMIT = 1000;
  // const [value, setValue] = React.useState('');
  // const handleChange = () => (event: any) => {
  //   setValue(event.target.value);
  // };

  return (
    <TextField
      {...input}
      // value={value}
      // onChange={handleChange()}
      InputProps={{ classes: { root: classes.inputRoot } }}
      InputLabelProps={{ classes: { root: classes.inputRootLabel } }}
      id={id}
      rows={4}
      rowsMax={4}
      variant={variant}
      placeholder={placeholder}
      label={label}
      error={isErrorField}
      helperText={isErrorField && (meta.error || meta.submitError)}
      // helperText={
      //   helper && (
      //     <Typography align="right" classes={{ root: classes.inputRootLabel }}>
      //       {value.length}/{CHARACTER_LIMIT}
      //     </Typography>
      //   )
      // }
      multiline
    />
  );
};
