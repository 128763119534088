import React from 'react';

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TimePicker } from '@material-ui/pickers';

import { checkFieldError } from './helper';

import { ICommonInputFieldProps } from 'types/inputField';

const useStyles = makeStyles({
  inputRoot: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 500,
  },
  inputRootLabel: {
    fontSize: '12px',
    lineHeight: '20px',
    fontWeight: 500,
  },
});

export const TimePickerInput: React.FC<ICommonInputFieldProps> = ({ id, label, placeholder, input, meta }) => {
  const isErrorField = checkFieldError(meta);
  const classes = useStyles();
  const initialValue = '2021-01-01 00:00:00';

  return (
    <Box>
      <TimePicker
        {...input}
        id={id}
        autoOk
        placeholder={placeholder}
        label={label}
        error={isErrorField}
        initialFocusedDate={initialValue}
        minutesStep={5}
        InputProps={{ className: classes.inputRoot }}
        InputLabelProps={{ className: classes.inputRootLabel }}
        value={input.value === '' ? null : input.value}
      />
    </Box>
  );
};
