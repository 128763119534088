import React from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  makeStyles,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';

import { StateField } from '../StateField/StateField';

import styles from './SupportTicketFilters.module.less';

import { CompanyField } from 'components/common/CompanyField/CompanyField';
import { SelectField } from 'components/common/controlledComponents/SelectField';
import Placeholder from 'components/common/Placeholder/Placeholder';
import { SearchField } from 'components/common/SearchField/SearchField';
import {
  PERIOD_TICKET_ANY,
  PERIOD_TICKET_CUSTOM,
  PERIOD_TICKET_FILTERS,
  PERIOD_TICKET_TWO_WEEKS,
  PERIOD_TICKET_WEEK,
} from 'constants/support';
import { useAppDispatch } from 'store';
import { ticketDataSlice } from 'store/reducers/ticket/ticket';
import { useAppSelector } from 'store/store';
import { TicketFilters } from 'types/ticket';
import { getDateRangeString } from 'utils/utils';

const useStyles = makeStyles({
  filtersContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '12px 20px',
  },
  spacer: {
    marginLeft: '12px',
  },
});

export const SupportTicketsFilters: React.FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { filters } = useAppSelector((state) => state.ticketData);
  const [dateFrom, setDateFrom] = React.useState<Date | null>(null);
  const [dateTo, setDateTo] = React.useState<Date | null>(null);
  const [openedDatesSelectorDialog, setOpenedDatesSelectorDialog] = React.useState(false);

  const updateFilter = (newFilter: TicketFilters) => {
    dispatch(ticketDataSlice.actions.setFilters({ ...filters, ...newFilter }));
  };

  const handleChangePeriodFilter = (newValue: string) => {
    if (newValue !== PERIOD_TICKET_CUSTOM) {
      const newFilters: TicketFilters = { ...filters, to_date: undefined, from_date: undefined, period: newValue };
      const today = new Date();
      if (newValue === PERIOD_TICKET_WEEK) {
        const fromDate = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        newFilters.from_date = fromDate.toISOString();
      } else if (newValue === PERIOD_TICKET_TWO_WEEKS) {
        const fromDate = new Date(today.getTime() - 14 * 24 * 60 * 60 * 1000);
        newFilters.from_date = fromDate.toISOString();
      }
      updateFilter(newFilters);
      setDateFrom(null);
      setDateTo(null);
    } else setOpenedDatesSelectorDialog(true);
  };

  function setCustomDateRange(periodFrom: Date | null, periodTo: Date | null) {
    dispatch(
      ticketDataSlice.actions.setFilters({
        ...filters,
        from_date: (periodFrom && periodFrom.toISOString()) || undefined,
        to_date: (periodTo && periodTo.toISOString()) || undefined,
        period: PERIOD_TICKET_CUSTOM,
      }),
    );
    setOpenedDatesSelectorDialog(false);
  }

  const periodOptions = PERIOD_TICKET_FILTERS.map((value) =>
    filters.period === PERIOD_TICKET_CUSTOM && value.key === PERIOD_TICKET_CUSTOM
      ? {
          ...value,
          label: getDateRangeString(filters.from_date, filters.to_date),
          onClick: () => setOpenedDatesSelectorDialog(true),
        }
      : value,
  );

  return (
    <>
      <div className={classes.filtersContainer}>
        <Dialog open={openedDatesSelectorDialog} onClose={() => setOpenedDatesSelectorDialog(false)}>
          <DialogTitle>
            <span className={styles.largeTextBold}>Custom range</span>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Box display="flex">
                <KeyboardDatePicker
                  disableToolbar
                  disableFuture
                  variant="inline"
                  inputVariant="outlined"
                  format="MMM dd yyyy"
                  value={dateFrom}
                  onChange={setDateFrom}
                  className={styles.dateTimePicker}
                  style={{ marginRight: '16px' }}
                  label="From"
                  PopoverProps={{
                    className: styles.calendar,
                  }}
                />
                <KeyboardDatePicker
                  disableToolbar
                  disableFuture
                  variant="inline"
                  inputVariant="outlined"
                  format="MMM dd yyyy"
                  value={dateTo}
                  onChange={setDateTo}
                  className={styles.dateTimePicker}
                  label="To"
                  PopoverProps={{
                    className: styles.calendar,
                  }}
                />
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenedDatesSelectorDialog(false)} color="primary" autoFocus>
              <span className={styles.mediumText}>Cancel</span>
            </Button>
            <Button onClick={() => setCustomDateRange(dateFrom, dateTo)} variant="contained" color="primary">
              <span className={styles.mediumText}>Apply</span>
            </Button>
          </DialogActions>
        </Dialog>
        <SearchField value={filters.search} onChange={(value: string) => updateFilter({ ...filters, search: value })} />
        <div className={classes.spacer} />
        <CompanyField
          value={filters.company_id}
          onChange={(value: number) => updateFilter({ ...filters, company_id: value })}
        />
        <div className={classes.spacer} />
        <SelectField
          value={filters.period}
          options={periodOptions}
          onChange={handleChangePeriodFilter}
          renderValue={filters.period !== PERIOD_TICKET_ANY ? undefined : () => <Placeholder>Any Period</Placeholder>}
        />
        <div className={classes.spacer} />
        <StateField value={filters.stage} onChange={(value: string) => updateFilter({ ...filters, stage: value })} />
      </div>
      <Divider />
    </>
  );
};
