import { AxiosResponse } from 'axios';

import { ICommentsResponse } from '../../types/comment';

import { privateApi } from 'api/api';

const commentApi = {
  getComments: (path: string, id: string, page: number): Promise<AxiosResponse<ICommentsResponse>> =>
    privateApi.get(`/${path}/${id}/comments`, { params: { page } }),

  sendComment: (path: string, id: string, data: any): Promise<AxiosResponse> =>
    privateApi.post(`/${path}/${id}/comment`, data),

  uploadCommentAttachment: (path: string, id: string, formData: FormData): Promise<AxiosResponse> =>
    privateApi.post(`/${path}/${id}/comment/attachment`, formData),
};

export default commentApi;
