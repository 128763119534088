import React, { FC, ReactNode } from 'react';

import { makeStyles } from '@material-ui/core';

const usePlaceholderStyles = makeStyles({
  placeholder: {
    color: '#aaa',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
  },
});

const Placeholder: FC<{ children: ReactNode }> = ({ children }) => {
  const classes = usePlaceholderStyles();

  return <div className={classes.placeholder}>{children}</div>;
};

export default Placeholder;
