import MontserratBold from './Montserrat-Bold.ttf';
import MontserratMedium from './Montserrat-Medium.ttf';
import MontserratRegular from './Montserrat-Regular.ttf';
import MontserratSemiBold from './Montserrat-SemiBold.ttf';
import PinyonScriptRegular from './PinyonScript-Regular.ttf';

const montserratRegular = {
  fontFamily: 'Montserrat',
  src: `url(${MontserratRegular})`,
  fontWeight: 400,
};

const montserratMedium = {
  fontFamily: 'Montserrat',
  src: `url(${MontserratMedium})`,
  fontWeight: 500,
};

const montserratSemiBold = {
  fontFamily: 'Montserrat',
  src: `url(${MontserratSemiBold})`,
  fontWeight: 600,
};

const montserratBold = {
  fontFamily: 'Montserrat',
  src: `url(${MontserratBold})`,
  fontWeight: 700,
};

const pinyonScriptRegular = {
  fontFamily: 'PinyonScript',
  src: `url(${PinyonScriptRegular})`,
  fontWeight: 400,
};

export const ApplicationFonts = [
  montserratRegular,
  montserratMedium,
  montserratSemiBold,
  montserratBold,
  pinyonScriptRegular,
];
