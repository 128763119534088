import React, { FC } from 'react';

import Button from '@material-ui/core/Button';
import DoneIcon from '@material-ui/icons/Done';
import { Link, useLocation } from 'react-router-dom';

import styles from './AuthSuccessPage.module.less';
import { getSuccessPageContext } from './helper';

export const AuthSuccessPage: FC = () => {
  const { state } = useLocation() as Record<string, any>;

  const contextConfig = getSuccessPageContext(state);

  return (
    <div className={styles.authPageContainer}>
      <div className={styles.authPageContent}>
        <DoneIcon className={styles.doneIcon} fontSize="large" />
        <div className={styles.title}>{contextConfig.title}</div>
        <div className={styles.body}>{contextConfig.body}</div>
        <Link to={contextConfig.navigateTo} className={styles.navigation}>
          <Button color="primary" variant="contained" className={styles.submitButton}>
            {contextConfig.buttonText}
          </Button>
        </Link>
      </div>
    </div>
  );
};
