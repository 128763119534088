import React from 'react';

import { Grid, GridProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  'spacing-xs-1': {
    margin: '-12px',
    width: `calc(100% + 24px)`,
    [theme.breakpoints.down(1600)]: {
      margin: '-10px',
      width: `calc(100% + 20px)`,
    },
    '& > .MuiGrid-item': {
      padding: '12px',
      [theme.breakpoints.down(1600)]: {
        padding: '10px',
      },
    },
  },
}));

export const GridC: React.FC<GridProps> = (props) => {
  const classes = useStyles();
  return <Grid {...props} spacing={1} classes={{ ...classes }} />;
};
