import React, { FC, useEffect, useState } from 'react';

import { Card, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { withWhiteLabelContext } from 'react-whitelabel';

import { GridC, Spinner } from 'components';
import { colors } from 'constants/ui';
import { useAppDispatch } from 'store';
import { getCategories } from 'store/actions/category';
import { useAppSelector } from 'store/store';
import { ICategory } from 'types/category';

import './ShopByProductPage.css';

interface IShopCategory {
  logo: string;
  mouseOn: boolean;
  categoryData: ICategory | null;
}

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    marginBottom: '56px',
  },
  categoryCard: {
    display: 'flex',
    flexDirection: 'column',
    color: colors.WHITE,
    height: '402px',
    padding: '24px 36px 0px 36px',
    [theme.breakpoints.down(1361)]: {
      height: '328px',
      padding: '24px 24px 0px',
    },
  },
}));

const ShopByProductPage: FC<{ label: any }> = (props): JSX.Element => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { loading, categories } = useAppSelector((state) => state.category);
  const [pageCategories, setPageCategories] = useState<IShopCategory[]>([]);
  const [width, setWidth] = useState(window.innerWidth);

  const { label } = props;
  const logos: string[] = label.shopByLogos;
  const { name } = label;

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [width]);

  useEffect(() => {
    if (!loading && categories.length === 0) {
      dispatch(getCategories());
    }
  }, [dispatch, loading, categories]);

  useEffect(() => {
    if (categories.length > 0) {
      const newShopCategories: IShopCategory[] = logos.map((logo, index) => ({
        logo,
        mouseOn: false,
        categoryData: categories[index],
      }));
      setPageCategories(newShopCategories);
    }
  }, [logos, categories, setPageCategories]);

  function mouseEnter(index: number) {
    setPageCategories(
      pageCategories.map((pageCategory, i) =>
        index === i ? { ...pageCategory, mouseOn: true } : { ...pageCategory, mouseOn: false },
      ),
    );
  }

  function mouseLeave(index: number) {
    setPageCategories(
      pageCategories.map((pageCategory, i) => (index === i ? { ...pageCategory, mouseOn: false } : pageCategory)),
    );
  }

  if (pageCategories.some((categ) => !categ.categoryData)) return <Spinner />;

  const maxChildCategories = width > 1360 ? 7 : 5;

  return (
    <div className="pageContainer">
      <Typography className={classes.pageTitle} variant="h1">
        Shop by Product
      </Typography>
      <GridC container>
        {pageCategories.map((category, index) => (
          <Grid
            item
            xs={3}
            key={category.categoryData.id}
            onMouseEnter={() => mouseEnter(index)}
            onMouseLeave={() => mouseLeave(index)}
          >
            <Card className={classes.categoryCard}>
              {category.mouseOn ? (
                <>
                  <div className="categoryListTitle">
                    <Link to={`/shop/category/${category.categoryData.id}`}>
                      <Typography variant="h4">{category.categoryData.name}</Typography>
                    </Link>
                  </div>
                  {category.categoryData.child_categories
                    .filter((item) => item.products.length >= 0)
                    .slice(0, maxChildCategories)
                    .map((child) => (
                      <Link key={child.id} to={`/shop/category/${child.id}`} className={`categoryLink${name}`}>
                        {child.name}
                      </Link>
                    ))}
                  {category.categoryData.child_categories.filter((item) => item.products.length >= 0).length >
                    maxChildCategories && (
                    <Link to={`/shop/category/${category.categoryData.id}`} className={`showAllLink${name}`}>
                      Show all
                    </Link>
                  )}
                </>
              ) : (
                <>
                  <img src={category.logo} alt="" />
                  <div className="categoryLogoTitle">
                    <Typography variant="h4">{category.categoryData.name}</Typography>
                  </div>
                </>
              )}
            </Card>
          </Grid>
        ))}
      </GridC>
    </div>
  );
};

export default withWhiteLabelContext(ShopByProductPage);
