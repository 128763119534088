import { AxiosResponse } from 'axios';

import { privateApi, publicApi } from 'api/api';
import {
  ILoginResponse,
  PortalData,
  ResetPasswordConfirmData,
  ResetPasswordData,
  SignUpData,
  VerifyResetTokenData,
} from 'types/auth';
import { IUserResponse } from 'types/user';

const AuthApi = {
  login: (email: string, password: string): Promise<AxiosResponse<ILoginResponse>> =>
    publicApi.post('/accounts/login/', { email, password }, { withCredentials: true }),

  signUp: (signUpData: SignUpData): Promise<AxiosResponse<ILoginResponse>> =>
    publicApi.post('/accounts/request/', signUpData, { withCredentials: true }),

  resetPassword: (resetPasswordData: ResetPasswordData): Promise<AxiosResponse> =>
    publicApi.post('/accounts/password/reset/', resetPasswordData, { withCredentials: true }),

  resetPasswordConfirm: (resetPasswordConfirmData: ResetPasswordConfirmData): Promise<AxiosResponse> =>
    publicApi.post('/accounts/password/reset/confirm/', resetPasswordConfirmData, { withCredentials: true }),

  getUser: (): Promise<AxiosResponse<IUserResponse>> => privateApi.get('/accounts/user/', {}),

  refreshToken: () => publicApi.post('/accounts/token/refresh/', {}, { withCredentials: true }),

  logout: (): Promise<AxiosResponse> => publicApi.post('/accounts/logout/', {}),

  verifyResetToken: (tokenData: VerifyResetTokenData): Promise<AxiosResponse> =>
    publicApi.post('/accounts/password/reset/verify/', tokenData, { withCredentials: true }),

  getPortalData: (): Promise<AxiosResponse<PortalData>> => publicApi.get('/accounts/portal/data/', {}),
};

export default AuthApi;
