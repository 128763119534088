import React, { useEffect } from 'react';

import { Table } from 'components';
import { TablePageContainer } from 'components/common/PageContainer/PageContainer';
import { TABLE_COLUMNS_DISPATCH } from 'constants/dispatch_column';
import { getDispatches } from 'store/actions/dispatch/dispatch';
import { useAppDispatch, useAppSelector } from 'store/store';

export const DispatchPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { records, recordsLoading } = useAppSelector((state) => state.dispatch);

  useEffect(() => {
    if (!recordsLoading) {
      dispatch(getDispatches());
    }
  }, [dispatch]);

  return (
    <TablePageContainer title="Dispatches">
      <Table name="dispatches" loading={recordsLoading} columns={TABLE_COLUMNS_DISPATCH} data={records} />
    </TablePageContainer>
  );
};
