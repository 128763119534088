import React, { useEffect } from 'react';

import { Box, Button, Card, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LocalOffer, ShoppingCart } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

import Spinner from 'components/common/Spinner/Spinner';
import { ROUTER_PATHS } from 'constants/routerPaths';
import { colors } from 'constants/ui';
import { useAppDispatch } from 'store';
import { getCart } from 'store/actions/cart';
import { createPriceRequest, updatePriceRequest } from 'store/actions/product';
import { orderSlice } from 'store/reducers/order/order';
import { productSlice } from 'store/reducers/product/product';
import { useAppSelector } from 'store/store';
import { IPriceRequest } from 'types/product';

const useStyles = makeStyles({
  root: {
    padding: '36px',
    width: '274px',
    height: '216px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  priceButton: {
    width: '100%',
    marginTop: 'auto',
  },
});

export const Contract: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { productVariant, extraAttributeValues, productTemplate, pricing, locations, priceRequested } = useAppSelector(
    (state) => state.product,
  );

  // Create price requests
  useEffect(() => {
    if (!priceRequested) {
      dispatch(
        createPriceRequest({
          variantId: productVariant.id,
          extraAttributeValues,
          durationTerms: productTemplate.duration_terms,
          locations: locations.map((loc) => ({ quantity: loc.quantity, ...loc.address })),
        }),
      );
    }
  }, [dispatch, productVariant, extraAttributeValues, productTemplate, locations]);

  // Update price requests
  useEffect(() => {
    let pricingCheckDone = false;

    const interval = setInterval(() => {
      const requested = pricing.filter((price) => price.status !== 'DN');
      if (pricing.length > 0 && requested.length === 0) {
        pricingCheckDone = true;
        clearInterval(interval);
      } else {
        dispatch(updatePriceRequest(requested));
      }
    }, 2000);
    return () => {
      if (!pricingCheckDone) clearInterval(interval);
    };
  }, [dispatch, pricing]);

  if (pricing.length === 0) return <Spinner />;

  const newQuote = (duration_term: number) => {
    dispatch(orderSlice.actions.addNewOrder({ duration_term, locations, variant: productVariant }));
    history.push(ROUTER_PATHS.newQuotePage);
  };

  const addToCart = (selectedPricing: IPriceRequest) => {
    dispatch(productSlice.actions.setPricing(selectedPricing));
    dispatch(getCart());
    history.push(ROUTER_PATHS.addToCartPage);
  };

  return (
    <Box display="flex" mt="12px">
      <Grid container spacing={2}>
        {pricing.map((price) => (
          <Grid key={price.id} item>
            <Card classes={{ root: classes.root }}>
              <Typography>{price.duration_term} Months</Typography>
              {price.status === 'RQ' && (
                <Box m="auto">
                  <Spinner />
                </Box>
              )}
              {price.status === 'DN' && !price.price_total && (
                <>
                  <Box mt="4px">
                    <Box display="inline" fontWeight={500} fontSize={14} lineHeight="24px" color={colors.MIDDLE_GREY}>
                      More info required
                    </Box>
                  </Box>
                  <Button
                    classes={{ root: classes.priceButton }}
                    variant="text"
                    startIcon={<LocalOffer />}
                    onClick={() => {
                      newQuote(price.duration_term);
                    }}
                  >
                    Get Quote
                  </Button>
                </>
              )}
              {price.status === 'DN' && Boolean(price.price_total) && (
                <>
                  <Box mt="4px">
                    <Box display="inline" fontWeight="fontWeightBold" fontSize={18} lineHeight="28px" mr="4px">
                      {price.price_total}
                    </Box>
                    <Box display="inline" fontWeight={500} fontSize={12} lineHeight="20px">
                      /month
                    </Box>
                  </Box>
                  <Button
                    classes={{ root: classes.priceButton }}
                    color="primary"
                    variant="contained"
                    startIcon={<ShoppingCart />}
                    onClick={() => {
                      addToCart(price);
                    }}
                  >
                    Add to cart
                  </Button>
                </>
              )}
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
