import { AxiosResponse } from 'axios';

import { privateApi } from 'api/api';
import { FILTER_STATE_DEFAULT } from 'constants/order';
import {
  IAcceptAndSignData,
  IAcceptAndSignPostData,
  IOrder,
  IOrderFilters,
  IOrderResponse,
  ISaleOrderLine,
} from 'types/order';
import { IPagination, ISort } from 'types/table';

const OrderApi = {
  getOrders: (filters: IOrderFilters, pagination: IPagination, sort: ISort): Promise<AxiosResponse<IOrderResponse>> => {
    const limit = pagination.rowsPerPage;
    const offset = pagination.page * limit;
    const newFilters = { ...filters };
    if (newFilters.state === FILTER_STATE_DEFAULT) {
      newFilters.state = undefined;
    }
    return privateApi.get('/order/', { params: { ...newFilters, limit, offset, ...sort } });
  },

  getAcceptAndSignData: (id: string): Promise<AxiosResponse<IAcceptAndSignData>> =>
    privateApi.get(`/order/${id}/sign`, { timeout: 10000 }),

  acceptAndSign: (id: string, data: IAcceptAndSignPostData): Promise<AxiosResponse<IOrder>> =>
    privateApi.put(`/order/${id}/sign`, data),

  orderCancel: (id: string): Promise<AxiosResponse<IOrder>> => privateApi.put(`/order/${id}/cancel`, {}),

  createOrder: (data: any): Promise<AxiosResponse> => privateApi.post('/order/', data),

  getOrderDetails: (id: string): Promise<AxiosResponse<ISaleOrderLine>> => privateApi.get(`/order/product/${id}`, {}),
};

export default OrderApi;
