import React from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { WhiteLabelProvider } from 'react-whitelabel';

import App from './App';
import { applicationThemeCybernet, applicationThemeInfonetworks } from './applicationTheme';
import { store } from './store';

import logoAuthCybernet from 'assets/svg/cybernet/authorization_image.svg';
import logoDataCybernet from 'assets/svg/cybernet/data_services.svg';
import logoHelpCybernet from 'assets/svg/cybernet/help_dialog_title.svg';
import logoInfraCybernet from 'assets/svg/cybernet/infastr_service.svg';
import logoHeaderCybernet from 'assets/svg/cybernet/logo.svg';
import logoManagedCybernet from 'assets/svg/cybernet/managed_services.svg';
import logoNoItemsCybernet from 'assets/svg/cybernet/no_items.svg';
import logoNoResultsCybernet from 'assets/svg/cybernet/no_results.svg';
import logoEmptyOrderCybernet from 'assets/svg/cybernet/order_empty.svg';
import logoVoiceCybernet from 'assets/svg/cybernet/voice_services.svg';
import logoAuthInfonetworks from 'assets/svg/infonetworks/authorization_image.svg';
import logoDataInfonetworks from 'assets/svg/infonetworks/data_services.svg';
import logoHelpInfonetworks from 'assets/svg/infonetworks/help_dialog_title.svg';
import logoInfraInfonetworks from 'assets/svg/infonetworks/infastr_service.svg';
import logoHeaderInfonetworks from 'assets/svg/infonetworks/logo.svg';
import logoManagedInfonetworks from 'assets/svg/infonetworks/managed_services.svg';
import logoNoItemsInfonetworks from 'assets/svg/infonetworks/no_items.svg';
import logoNoResultsInfonetworks from 'assets/svg/infonetworks/no_results.svg';
import logoEmptyOrderInfonetworks from 'assets/svg/infonetworks/order_empty.svg';
import logoVoiceInfonetworks from 'assets/svg/infonetworks/voice_services.svg';
import { BRAND } from 'constants/common';
import { colors } from 'constants/ui';

import './styles/index.less';

const labels = {
  infonetworks: {
    name: 'InfoNetworks',
    emailPostfix: 'infonetworks',
    headerLogo: {
      width: '168px',
      height: '56px',
      background: `url('${logoHeaderInfonetworks}') center`,
    },
    authLogo: {
      height: '100%',
      background: `url('${logoAuthInfonetworks}') center`,
    },
    applicationTheme: applicationThemeInfonetworks,
    shopByLogos: [logoDataInfonetworks, logoInfraInfonetworks, logoManagedInfonetworks, logoVoiceInfonetworks],
    breadCrumbs: {
      defaultColor: colors.PURPLE,
      hoverColor: colors.DARK_VIOLET,
    },
    tabs: {
      selectedColor: colors.VIOLET,
    },
    helpDialogLogo: logoHelpInfonetworks,
    ordersEmptyLogo: logoEmptyOrderInfonetworks,
    categoryLinkColor: colors.PURPLE,
    noResultsLogo: logoNoResultsInfonetworks,
    noItemsLogo: logoNoItemsInfonetworks,
    attachUpload: {
      borderColor: colors.LINES_PURPLE,
      borderBackgroundColor: colors.WHITE_PURPLE,
      uploadIconColor: colors.MIDDLE_PURPLE,
    },
  },
  cybernet: {
    name: 'Cybernet',
    emailPostfix: 'cybernetcom',
    headerLogo: {
      width: '168px',
      height: '57px',
      background: `url('${logoHeaderCybernet}') center no-repeat`,
    },
    authLogo: {
      height: '100%',
      background: `url('${logoAuthCybernet}') center`,
    },
    applicationTheme: applicationThemeCybernet,
    shopByLogos: [logoDataCybernet, logoInfraCybernet, logoManagedCybernet, logoVoiceCybernet],
    breadCrumbs: {
      defaultColor: colors.BLUE,
      hoverColor: colors.BRIGHT_BLUE,
    },
    tabs: {
      selectedColor: colors.BRIGHT_BLUE,
    },
    helpDialogLogo: logoHelpCybernet,
    ordersEmptyLogo: logoEmptyOrderCybernet,
    categoryLinkColor: colors.BLUE,
    noResultsLogo: logoNoResultsCybernet,
    noItemsLogo: logoNoItemsCybernet,
    attachUpload: {
      borderColor: colors.LINES_BLUE,
      borderBackgroundColor: colors.WHITE_BLUE,
      uploadIconColor: colors.MIDDLE_BLUE,
    },
  },
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <WhiteLabelProvider labels={labels} selectedLabel={BRAND}>
          <App />
        </WhiteLabelProvider>
      </MuiPickersUtilsProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
