import React, { FC, useEffect } from 'react';

import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from 'react-router-dom';

import styles from './SupportTicketsPage.module.less';

import { TablePageContainer } from 'components/common/PageContainer/PageContainer';
import Table from 'components/common/Table/Table';
import { NewTicketDialog } from 'components/support/NewTicketDialog/NewTicketDialog';
import { SupportTicketsFilters } from 'components/support/SupportTicketsFilters/SupportTicketsFilters';
import { TABLE_COLUMNS_TICKET } from 'constants/support_columns';
import { useAppDispatch } from 'store';
import { getTickets } from 'store/actions/ticket';
import { breadcrumbSlice } from 'store/reducers/breadcrumb/breadcrumb';
import { ticketDataSlice } from 'store/reducers/ticket/ticket';
import { useAppSelector } from 'store/store';

export const SupportTicketsPage: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [newTicketFormOpened, setNewTicketFormOpened] = React.useState(false);
  const { ticketsLoading, tickets, filters, pagination, sort } = useAppSelector((state) => state.ticketData);

  const updatePagination = (paginationData: { rowsPerPage?: number; page?: number }) => {
    dispatch(ticketDataSlice.actions.setPagination({ ...pagination, ...paginationData }));
  };

  useEffect(() => {
    dispatch(breadcrumbSlice.actions.setBreadcrumbs([{ title: 'Support Tickets', url: '/support' }]));
  }, [dispatch]);

  useEffect(() => {
    if (!ticketsLoading) {
      dispatch(getTickets({ filters, pagination, sort }));
    }
  }, [dispatch, filters, pagination, sort]);

  const createButton = (
    <Button
      onClick={() => setNewTicketFormOpened(true)}
      style={{ maxWidth: '181px', maxHeight: '48px' }}
      variant="contained"
      color="primary"
      startIcon={<AddIcon />}
    >
      <span className={styles.textMedium}>Create ticket</span>
    </Button>
  );

  return (
    <TablePageContainer title="Support Tickets" controls={createButton}>
      {newTicketFormOpened && <NewTicketDialog open={newTicketFormOpened} setOpen={setNewTicketFormOpened} />}
      <Table
        name="tickets"
        loading={ticketsLoading}
        columns={TABLE_COLUMNS_TICKET}
        data={(tickets && tickets.helpdesk_ticket) || []}
        FilterComponent={SupportTicketsFilters}
        updatePagination={updatePagination}
        pagination={pagination}
        sort={sort}
        updateSort={(column: string) => dispatch(ticketDataSlice.actions.updateSort(column))}
        count={(tickets && tickets.count) || 0}
        onRowClick={(ticketId: number) => history.push(`/support/${ticketId}`)}
      />
    </TablePageContainer>
  );
};
