import React from 'react';

import { Route, RouteProps } from 'react-router-dom';
import { withWhiteLabelContext } from 'react-whitelabel';

type RouteItemProps = RouteProps & { pageTitle: string; label: any };

const RouterItem: React.FC<RouteItemProps> = ({
  component,
  path,
  exact,
  pageTitle,
  label: { name },
}: RouteItemProps) => {
  document.title = `${pageTitle} | ${name}`;

  return <Route component={component} path={path} exact={exact} />;
};

export default withWhiteLabelContext(RouterItem);
