import React from 'react';

import { Box, IconButton, Typography, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Clear, CloudUpload } from '@material-ui/icons';
import PublishIcon from '@material-ui/icons/Publish';
import { nanoid } from '@reduxjs/toolkit';
import { withWhiteLabelContext } from 'react-whitelabel';

import { ATTACHMENT_SIZE_LIMIT } from 'constants/common';
import { colors } from 'constants/ui';
import { IAttachFile } from 'types/common';

interface IAttachUploadProps {
  attachments: IAttachFile[];
  setAttachments: (files: IAttachFile[]) => void;
  minimalized?: boolean;
  label: any;
}

const fileSizeToString = (size: number) => {
  const displaySize = (size / (1024 * 1024)).toFixed(3);
  return `${displaySize} MB`;
};

const preventDefault = (e: React.DragEvent<HTMLDivElement>) => {
  e.preventDefault();
};

const AttachUpload: React.FC<IAttachUploadProps> = ({ attachments, setAttachments, minimalized, label }) => {
  const useStyles = makeStyles({
    attachOverview: {
      width: '100%',
    },
    attachBlock: {
      backgroundColor: colors.WHITE_GREY,
      padding: '10px 16px',
      display: 'flex',
      alignItems: 'center',
      marginBottom: '8px',
    },
    attachName: {
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '20px',
      color: colors.DARK,
    },
    attachSize: {
      marginLeft: '4px',
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '20px',
      color: colors.MIDDLE_GREY,
    },
    attachError: {
      fontSize: '12px',
      lineHeight: '20px',
      color: colors.RED,
    },
    attachRemove: {
      color: colors.DARK_24,
    },
    attachUpload: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyItems: 'center',
      border: `2px dashed ${label.attachUpload.borderColor}`,
      borderRadius: '4px',
      paddingTop: '24px',
      paddingBottom: '32px',
      backgroundColor: label.attachUpload.borderBackgroundColor,
    },
    uploadIcon: {
      color: label.attachUpload.uploadIconColor,
      marginBottom: '8px',
    },
    messageOne: {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '24px',
      color: colors.DARK,
      marginBottom: '8px',
    },
    messageTwo: {
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '20px',
      color: colors.MIDDLE_GREY,
      marginBottom: '20px',
    },
    minimalizedButton: {
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '24px',
      color: label.categoryLinkColor,
    },
    input: {
      display: 'none',
    },
  });

  const classes = useStyles();
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const handleFiles = (newFiles: File[]) => {
    const newAttachments: IAttachFile[] = newFiles.map((file) => ({
      id: nanoid(),
      file,
      isValid: file.size <= ATTACHMENT_SIZE_LIMIT,
    }));
    setAttachments(attachments.concat(newAttachments));
  };

  const fileDrop = (e: React.DragEvent<HTMLDivElement>) => {
    preventDefault(e);
    if (e.dataTransfer.files.length) {
      handleFiles(Array.from(e.dataTransfer.files));
    }
  };

  const filesSelected = () => {
    if (fileInputRef.current.files.length) {
      handleFiles(Array.from(fileInputRef.current.files));
    }
  };

  const fileInputClicked = () => {
    if (fileInputRef) {
      fileInputRef.current.click();
    }
  };

  const removeFile = (id: string) => {
    const index = attachments.findIndex((attach) => attach.id === id);
    attachments.splice(index, 1);
    setAttachments([...attachments]);
  };

  return (
    <div>
      <div className={classes.attachOverview}>
        {attachments.map((attach) => (
          <div className={classes.attachBlock} key={attach.id}>
            <div>
              <div>
                <span className={classes.attachName}>{attach.file.name}</span>
                <span className={classes.attachSize}>({fileSizeToString(attach.file.size)})</span>
              </div>
              {!attach.isValid && <div className={classes.attachError}>File size exceeds 50 MB</div>}
            </div>
            <Box flexGrow={1} />
            <IconButton className={classes.attachRemove} size="small" onClick={() => removeFile(attach.id)}>
              <Clear />
            </IconButton>
          </div>
        ))}
      </div>
      <div
        className={!minimalized && classes.attachUpload}
        onDragOver={!minimalized && preventDefault}
        onDragEnter={!minimalized && preventDefault}
        onDragLeave={!minimalized && preventDefault}
        onDrop={!minimalized && fileDrop}
      >
        {!minimalized && (
          <>
            <CloudUpload className={classes.uploadIcon} fontSize="large" />
            <div className={classes.messageOne}>Drag and drop files here</div>
            <div className={classes.messageTwo}>OR</div>
            <Button variant="contained" color="secondary" onClick={fileInputClicked}>
              Browse
            </Button>
          </>
        )}
        {minimalized && (
          <Button startIcon={<PublishIcon />} color="primary" onClick={fileInputClicked}>
            <Typography classes={{ root: classes.minimalizedButton }}>Upload files</Typography>
          </Button>
        )}
        <input ref={fileInputRef} className={classes.input} type="file" multiple onChange={filesSelected} />
      </div>
    </div>
  );
};

export default withWhiteLabelContext(AttachUpload);
