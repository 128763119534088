import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import { productApi } from 'api/productApi';
import { IDurationTerm, IPriceAddress, IPriceRequest } from 'types/product';

export type PriceRequestData = {
  variantId: number;
  extraAttributeValues: number[];
  durationTerms: IDurationTerm[];
  locations: IPriceAddress[];
};

export const fetchProduct = createAsyncThunk('@product/fetchProduct', async (product_id: number) => {
  const { data } = await productApi.getProduct(product_id);

  return data;
});

export const createPriceRequest = createAsyncThunk(
  '@product/createPriceRequest',
  async ({ variantId, extraAttributeValues, durationTerms, locations }: PriceRequestData) => {
    const promises: Promise<AxiosResponse<IPriceRequest>>[] = [];
    if (durationTerms.length > 0) {
      durationTerms.forEach((term) => {
        promises.push(productApi.newPriceRequest(variantId, extraAttributeValues, term.value, locations));
      });
    } else {
      promises.push(productApi.newPriceRequest(variantId, extraAttributeValues, 0, locations));
    }

    const priceRequestsResp = await Promise.all(promises);
    const result = priceRequestsResp.map((resp) => resp.data);
    return result;
  },
);

export const updatePriceRequest = createAsyncThunk(
  '@product/updatePriceRequest',
  async (priceRequests: IPriceRequest[]) => {
    const promises: Promise<AxiosResponse<IPriceRequest>>[] = [];
    priceRequests.forEach((price) => {
      promises.push(productApi.getPriceRequest(price.id));
    });
    const priceRequestsResp = await Promise.all(promises);
    const result = priceRequestsResp.map((resp) => resp.data);
    return result;
  },
);
