import { RouteProps } from 'react-router-dom';

import { NotFoundLayout } from '../components';

import { AUTH_BASE_ROUTE, AUTH_SUCCESS_INDEX_ROUTE, ROUTER_PATHS } from './routerPaths';

import { AuthSuccessPage, ForgotPassword, SetPasswordPage, SignIn, SignUp } from 'pages/auth';
import { BillsPage } from 'pages/bills';
import { DispatchPage } from 'pages/dispatch/DispatchPage';
import { AcceptAndSignPage, OrderDetailPage, OrdersPage } from 'pages/orders';
import { AddToCartPage, CategoryPage, NewQuotePage, ProductPage, ShoppingCartPage } from 'pages/shop';
import ShopByProductPage from 'pages/shop/ShopByProductPage/ShopByProductPage';
import { SupportTicketDetailsPage, SupportTicketsPage } from 'pages/support';
import { UserDetails } from 'pages/user';

export interface IRoute extends RouteProps {
  path: string;
  pageTitle: string;
}

export const publicRoutes: IRoute[] = [
  {
    component: SignIn,
    path: ROUTER_PATHS.signIn,
    exact: true,
    pageTitle: 'Login',
  },
  {
    component: ForgotPassword,
    path: ROUTER_PATHS.forgotPassword,
    exact: true,
    pageTitle: 'Login',
  },
  {
    component: SignUp,
    path: ROUTER_PATHS.signUp,
    exact: true,
    pageTitle: 'Login',
  },
  {
    component: AuthSuccessPage,
    path: `${AUTH_BASE_ROUTE}/*${AUTH_SUCCESS_INDEX_ROUTE}`,
    pageTitle: 'Login',
  },
];

export const mainRoutes: IRoute[] = [
  {
    component: ShopByProductPage,
    exact: true,
    path: ROUTER_PATHS.shopByProduct,
    pageTitle: 'Services',
  },
  {
    component: OrdersPage,
    exact: true,
    path: ROUTER_PATHS.orders,
    pageTitle: 'Orders',
  },
  {
    component: OrderDetailPage,
    exact: true,
    path: ROUTER_PATHS.orderDetails,
    pageTitle: 'Orders',
  },
  {
    component: AcceptAndSignPage,
    exact: true,
    path: ROUTER_PATHS.acceptAndSign,
    pageTitle: 'Orders | InfoNetworks',
  },
  {
    component: DispatchPage,
    exact: true,
    path: ROUTER_PATHS.dispatchRoot,
    pageTitle: 'Dispatches',
  },
  {
    component: BillsPage,
    exact: true,
    path: ROUTER_PATHS.bills,
    pageTitle: 'Billing',
  },
  {
    component: SupportTicketsPage,
    exact: true,
    path: ROUTER_PATHS.supportTickets,
    pageTitle: 'Support',
  },
  {
    component: SupportTicketDetailsPage,
    exact: true,
    path: ROUTER_PATHS.supportTicketDetailsPage,
    pageTitle: 'Support',
  },
  {
    component: UserDetails,
    exact: true,
    path: ROUTER_PATHS.profile,
    pageTitle: 'My profile',
  },
  {
    component: CategoryPage,
    exact: true,
    path: ROUTER_PATHS.category,
    pageTitle: 'Services',
  },
  {
    component: ProductPage,
    exact: true,
    path: ROUTER_PATHS.productPage,
    pageTitle: 'Placing an Order',
  },
  {
    component: NewQuotePage,
    exact: true,
    path: ROUTER_PATHS.newQuotePage,
    pageTitle: 'Placing an Order',
  },
  {
    component: AddToCartPage,
    exact: true,
    path: ROUTER_PATHS.addToCartPage,
    pageTitle: 'Placing an Order',
  },
  {
    component: ShoppingCartPage,
    exact: true,
    path: ROUTER_PATHS.shoppingCart,
    pageTitle: 'Placing an Order',
  },
  {
    component: NotFoundLayout,
    exact: true,
    path: ROUTER_PATHS.notFoundPage,
    pageTitle: 'Page not found',
  },
];

export const createPasswordRoute: IRoute = {
  component: SetPasswordPage,
  exact: true,
  path: ROUTER_PATHS.createPassword,
  pageTitle: 'Placing an Order',
};

export const resetPasswordRoute: IRoute = {
  component: SetPasswordPage,
  exact: true,
  path: ROUTER_PATHS.resetPassword,
  pageTitle: 'Login',
};
