import React, { FC } from 'react';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import styles from './StateIndicator.module.less';

import { colors } from 'constants/ui';

interface IIndicatorData {
  title: string;
  color: string;
}

function getIndicatorData(state: string): IIndicatorData {
  switch (state) {
    case 'quote': {
      return {
        title: 'QUOTE',
        color: colors.QUOTE,
      };
    }

    case 'new_quote': {
      return {
        title: 'QUOTE',
        color: colors.QUOTE,
      };
    }
    case 'sale': {
      return {
        title: 'SUBMITTED',
        color: colors.SUBMITTED,
      };
    }
    case 'new_order': {
      return {
        title: 'SUBMITTED',
        color: colors.SUBMITTED,
      };
    }
    case 'cancel': {
      return {
        title: 'CANCELLED',
        color: colors.CANCELLED,
      };
    }
    case 'order': {
      return {
        title: 'PROCESSING',
        color: colors.PROCESSING,
      };
    }
    case 'process': {
      return {
        title: 'PROCESSING',
        color: colors.PROCESSING,
      };
    }
    case 'issue': {
      return {
        title: 'CONTACT SALES',
        color: colors.CONTACT_SALES,
      };
    }
    case 'active': {
      return {
        title: 'COMPLETED',
        color: colors.COMPLETED,
      };
    }
    case 'confirmed': {
      return {
        title: 'CONFIRMED',
        color: colors.CONFIRMED,
      };
    }
    case 'progress': {
      return {
        title: 'IN PROCESS',
        color: colors.IN_PROCESS,
      };
    }
    case 'dispatch': {
      return {
        title: 'SENT TO DISPATCH',
        color: colors.TO_DISPATCH,
      };
    }
    case 'done': {
      return {
        title: 'DONE',
        color: colors.SUBMITTED,
      };
    }
    case 'Opened': {
      return {
        title: 'OPENED',
        color: colors.QUOTE,
      };
    }
    case 'Assigned': {
      return {
        title: 'ASSIGNED',
        color: colors.GREEN,
      };
    }
    case 'Pending Customer Feedback': {
      return {
        title: 'PENDING CUSTOMER FEEDBACK',
        color: colors.PROCESSING,
      };
    }
    case 'Pending Resolution': {
      return {
        title: 'PENDING RESOLUTION',
        color: colors.COMPLETED,
      };
    }
    case 'Cancelled': {
      return {
        title: 'CANCELLED',
        color: colors.RED,
      };
    }
    case 'Closed': {
      return {
        title: 'CLOSED',
        color: colors.MIDDLE_GREY,
      };
    }

    default: {
      return {
        title: state,
        color: 'rgba(28,31,37,0.4)',
      };
    }
  }
}

export const StateIndicator: FC<{ state: string; titleVisible?: boolean }> = ({
  state,
  titleVisible = true,
}): JSX.Element => {
  const indicatorData = getIndicatorData(state);
  const useStyles = makeStyles({
    indicator: {
      color: indicatorData.color,
    },
  });
  const classes = useStyles();
  return (
    <Typography style={{ letterSpacing: '1px' }} variant="caption" classes={{ root: classes.indicator }}>
      <span style={{ fontSize: titleVisible ? '16px' : '22px' }}>●</span>{' '}
      <span className={styles.orderIndicator}>{titleVisible && indicatorData.title.toUpperCase()}</span>
    </Typography>
  );
};
