import React, { FC } from 'react';

import { Breadcrumbs } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import BreadCrumbItem from '../BreadCrumbItem/BreadCrumbItem';

import { useAppSelector } from 'store/store';
import { IBreadCrumbItem } from 'types/breadcrumbs';

const useStyles = makeStyles({
  breadCrumbs: {
    marginLeft: '120px',
    marginTop: '32px',
  },
});

export const BreadCrumbs: FC = (): JSX.Element => {
  const classes = useStyles();
  const breadcrumbs: IBreadCrumbItem[] = useAppSelector((state) => state.breadcrumb);

  return (
    <Breadcrumbs classes={{ root: classes.breadCrumbs }} color="primary">
      {breadcrumbs &&
        breadcrumbs.map((item) => (
          <BreadCrumbItem
            key={item.url}
            title={item.title}
            url={item.url}
            expandable={item.expandable}
            expandedItems={item.expanded_items}
          />
        ))}
    </Breadcrumbs>
  );
};
