import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { getCategories, getCategory } from 'store/actions/category';
import { CategoryStateType, ICategory } from 'types/category';

export const initialState: CategoryStateType = {
  categories: [],
  category: null,
  loading: false,
};

export const categorySlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getCategories.pending, (state: CategoryStateType) => ({
      ...state,
      categories: [],
      loading: true,
    }));
    builder.addCase(getCategories.rejected, (state: CategoryStateType) => ({
      ...state,
      categories: [],
      loading: false,
    }));
    builder.addCase(getCategories.fulfilled, (state: CategoryStateType, action: PayloadAction<ICategory[]>) => ({
      ...state,
      categories: action.payload.sort((a: ICategory, b: ICategory) => {
        return a.id - b.id;
      }),
      loading: false,
    }));
    builder.addCase(getCategory.fulfilled, (state: CategoryStateType, action: PayloadAction<ICategory>) => ({
      ...state,
      category: action.payload,
      loading: false,
    }));
    builder.addCase(getCategory.pending, (state: CategoryStateType) => ({
      ...state,
      category: null,
      loading: true,
    }));
    builder.addCase(getCategory.rejected, (state: CategoryStateType) => ({
      ...state,
      category: null,
      loading: false,
    }));
  },
  initialState,
  name: '@category',
  reducers: {
    resetCategory: (state: CategoryStateType) => ({ ...state, ...initialState }),
  },
});
