import { PHONE_NUMBER_REGEXP } from './constants';

export const phoneNumberValidator = (value: string | undefined): string | undefined => {
  let error;

  if (value && !PHONE_NUMBER_REGEXP.test(value)) {
    error = 'Please enter a valid phone number';
  }

  return error;
};
