export const ORDER_STATE_QUOTE = 'new_quote';
export const ORDER_STATE_ORDER = 'new_order';
export const ORDER_STATE_PROCESS = 'process';
export const ORDER_STATE_DONE = 'done';
export const ORDER_STATE_ISSUE = 'issue';
export const ORDER_STATE_CANCEL = 'cancel';

export const FILTER_STATE_DEFAULT = 'all';

export const COLUMN_ORDER_NAME = 'name';
export const COLUMN_ORDER_SUM = 'price_total';
export const COLUMN_ORDER_CREATE_DATE = 'create_date';
export const COLUMN_ORDER_UPDATE_DATE = 'write_date';
export const COLUMN_ORDER_COMPANY_NAME = 'company_name';
export const COLUMN_ORDER_CREATED_BY = 'create_by_name';
export const COLUMN_ORDER_STATUS = 'state';

export const STATE_ORDER_FILTERS = [
  { key: FILTER_STATE_DEFAULT, label: 'All' },
  { key: ORDER_STATE_QUOTE, label: 'Quotes' },
  { key: ORDER_STATE_ORDER, label: 'Submitted' },
  { key: ORDER_STATE_PROCESS, label: 'Processing' },
  { key: ORDER_STATE_ISSUE, label: 'Contact sales' },
  { key: ORDER_STATE_DONE, label: 'Completed' },
  { key: ORDER_STATE_CANCEL, label: 'Cancelled' },
];
