import { AxiosResponse } from 'axios';

import { privateApi } from 'api/api';
import { STATE_BILL_ALL } from 'constants/bill';
import { IBillFilers, IBillResponse } from 'types/bill';
import { IPagination, ISort } from 'types/table';
import { extractDateFromIso } from 'utils/utils';

const BillApi = {
  getBills: (filters: IBillFilers, pagination: IPagination, sort: ISort): Promise<AxiosResponse<IBillResponse>> => {
    const limit = pagination.rowsPerPage;
    const offset = pagination.page * limit;
    const newFilters = {
      ...filters,
      stage: filters.stage === STATE_BILL_ALL ? undefined : filters.stage,
      from_date: (filters.from_date && extractDateFromIso(filters.from_date)) || undefined,
      to_date: (filters.to_date && extractDateFromIso(filters.to_date)) || undefined,
    };
    newFilters.period = undefined;
    return privateApi.get('/bill/', { params: { ...newFilters, limit, offset, ...sort } });
  },
};

export default BillApi;
