import React from 'react';

import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import styles from './StepButtons.module.less';

const useStyles = makeStyles({
  backRoot: {
    width: '120px',
  },
  nextRoot: {
    width: '120px',
    marginLeft: '16px',
  },
});

interface IStepBackButtonProps {
  activeStep: number;
  onClick: () => void;
}

const StepBackButton: React.FC<IStepBackButtonProps> = ({ activeStep, onClick }) => {
  const classes = useStyles();
  return (
    <Button
      classes={{ root: classes.backRoot }}
      variant="contained"
      color="secondary"
      disabled={activeStep === 0}
      onClick={onClick}
    >
      Back
    </Button>
  );
};

interface IStepNextButtonProps {
  onClick: () => void | undefined;
  disabled: boolean;
}

const StepNextButton: React.FC<IStepNextButtonProps> = ({ onClick, disabled }) => {
  const classes = useStyles();
  return (
    <Button
      classes={{ root: classes.nextRoot }}
      variant="contained"
      color="primary"
      onClick={onClick}
      disabled={disabled}
    >
      Next
    </Button>
  );
};

interface IStepButtonProps {
  activeStep: number;
  onBackClick: () => void;
  onNextClick?: () => void;
  nextDisabled?: boolean;
}

const StepButtons: React.FC<IStepButtonProps> = ({ activeStep, onBackClick, onNextClick, nextDisabled }) => {
  return (
    <div className={styles.container}>
      <StepBackButton activeStep={activeStep} onClick={onBackClick} />
      {onNextClick && <StepNextButton onClick={onNextClick} disabled={Boolean(nextDisabled)} />}
    </div>
  );
};

StepButtons.defaultProps = { nextDisabled: false };

export default StepButtons;
