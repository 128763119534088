import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { authApi } from 'api/authApi';
import { ACCESS_TOKEN_KEY } from 'constants/common';
import { AuthService } from 'services/authService';
import { CookieService } from 'services/cookieService';
import { NavigationService } from 'services/navigationService';
import { ResetPasswordConfirmData, ResetPasswordData, SignUpData, VerifyResetTokenData } from 'types/auth';

export type AuthData = {
  email: string;
  password: string;
};

export const login = createAsyncThunk('@auth/login', async ({ email, password }: AuthData, { rejectWithValue }) => {
  try {
    const { data } = await authApi.login(email, password);
    CookieService.setCookie(ACCESS_TOKEN_KEY, data.access_token, {
      expires: data.access_token_expiration,
      path: '/',
    });
    return data;
  } catch (err: unknown) {
    if (axios.isAxiosError(err) && err.response) {
      return rejectWithValue(err.response.data);
    }
    return rejectWithValue(err);
  }
});

export const checkAuth = createAsyncThunk('@auth/auth', async () => {
  if (AuthService.getAccessToken()) {
    return true;
  }
  try {
    await AuthService.refreshTokens();
    NavigationService.navigateToMainPage();
    return true;
  } catch (err) {
    return false;
  }
});

export const getUserAction = createAsyncThunk('@auth/user-details', async () => {
  const { data } = await authApi.getUser();

  return data;
});

export const requestForSignUp = createAsyncThunk('@auth/request-for-sign-up', async (signUpData: SignUpData) => {
  const { data } = await authApi.signUp(signUpData);

  return data;
});

export const resetPassword = createAsyncThunk('@auth/reset-password', async (resetPasswordEmail: string) => {
  const requestData: ResetPasswordData = { email: resetPasswordEmail };
  const { data } = await authApi.resetPassword(requestData);
  return data;
});

export const resetPasswordConfirm = createAsyncThunk(
  '@auth/reset-password-confirm',
  async (resetPasswordConfirmData: ResetPasswordConfirmData) => {
    const { data } = await authApi.resetPasswordConfirm(resetPasswordConfirmData);
    return data;
  },
);

export const verifyResetToken = createAsyncThunk(
  '@auth/verify-reset-token',
  async (tokenData: VerifyResetTokenData) => {
    const { data } = await authApi.verifyResetToken(tokenData);
    return data;
  },
);

export const getPortalData = createAsyncThunk('@auth/portal-data', async () => {
  const { data } = await authApi.getPortalData();
  return data;
});
