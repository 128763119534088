import React, { FC, useCallback } from 'react';

import Button from '@material-ui/core/Button';
import { FORM_ERROR } from 'final-form';
import { Field, Form } from 'react-final-form';
import { withWhiteLabelContext } from 'react-whitelabel';

import styles from './SignIn.module.less';

import { AuthSwitcher, NavigationLink } from 'components';
import { PasswordField } from 'components/common/uncontrolledComponents/PasswordField';
import { TextFieldInput as TextField } from 'components/common/uncontrolledComponents/TextField';
import { ROUTER_PATHS } from 'constants/routerPaths';
import { trimFormatter } from 'services/formatters';
import { NavigationService } from 'services/navigationService';
import { useAppDispatch } from 'store';
import { login } from 'store/actions/auth';
import { ILoginError } from 'types/auth';
import { composeValidators, emailValidator, requiredValidator } from 'validators';
import { TEXT_FIELD_NORMAL_MAX_LENGTH } from 'validators/constants';

const SignIn: FC<{ label: any }> = (props) => {
  const dispatch = useAppDispatch();

  const { label } = props;
  const { name } = label;

  const handleFormSubmit = useCallback(
    async (formValues) => {
      let errors = {};
      const resultAction = await dispatch(login(formValues));
      if (login.fulfilled.match(resultAction)) {
        NavigationService.navigateToMainPage();
      } else if (login.rejected.match(resultAction)) {
        const loginError: ILoginError = resultAction.payload as ILoginError;
        errors = { ...loginError, [FORM_ERROR]: loginError.non_field_errors };
      }
      return errors;
    },
    [dispatch],
  );

  return (
    <div className={styles.authPageContainer}>
      <AuthSwitcher />
      <div className={styles.authPageContent}>
        <div className={styles.authPageTitle}>Welcome to {name}!</div>
        <Form onSubmit={handleFormSubmit} className={styles.authForm}>
          {({ handleSubmit, submitError }) => (
            <form onSubmit={handleSubmit}>
              <Field
                id="email"
                name="email"
                label="Email"
                variant="outlined"
                component={TextField}
                maxLength={TEXT_FIELD_NORMAL_MAX_LENGTH}
                formatOnBlur
                defaultValue=""
                format={trimFormatter}
                validate={composeValidators(requiredValidator('Email'), emailValidator)}
              />
              <Field
                id="password"
                name="password"
                label="Password"
                component={PasswordField}
                maxLength={TEXT_FIELD_NORMAL_MAX_LENGTH}
                formatOnBlur
                defaultValue=""
                format={trimFormatter}
                validate={requiredValidator('Password')}
              />
              {submitError && <div className={styles.errorMessage}>{submitError}</div>}
              <div className={styles.actions}>
                <Button color="primary" variant="contained" type="submit" className={styles.submitButton}>
                  Sign in
                </Button>
              </div>
            </form>
          )}
        </Form>
        <NavigationLink navigateTo={ROUTER_PATHS.forgotPassword} linkText="Forgot password?" />
      </div>
    </div>
  );
};

export default withWhiteLabelContext(SignIn);
