// eslint-disable-next-line @typescript-eslint/naming-convention
export enum colors {
  // Greyscale
  DARK = '#282827',
  DARK_GREY = '#434342',
  MIDDLE_GREY = '#707274',
  DARK_40 = 'rgba(28, 31, 37, 0.4)',
  DARK_24 = 'rgba(28, 31, 37, 0.24)',
  DARK_14 = 'rgba(28, 31, 37, 0.14)',
  LIGHT_GREY = '#E8EAED',
  WHITE_GREY = 'rgba(52, 61, 134, 0.05)',
  WHITE = '#FFFFFF',
  GREY = '#DADADA',

  // Violet
  DARK_VIOLET = '#371977',
  VIOLET = '#584E9D',
  PURPLE = '#7A59A1',
  PURPLE_HOV = '#956BC6',
  PURPLE_FOC = '#AD80E2',
  MIDDLE_PURPLE = '#BCB3F5',
  LINES_PURPLE = '#E5DDFB',
  PURPLE_5 = 'rgba(114, 65, 255, 0.05)',
  PURPLE_8 = 'rgba(114, 65, 255, 0.08)',
  PURPLE_10 = 'rgba(114, 65, 255, 0.1)',
  PURPLE_18 = 'rgba(114, 65, 255, 0.18)',
  PURPLE_26 = 'rgba(114, 65, 255, 0.26)',
  LIGHT_PURPLE = '#F4F5FF',
  WHITE_PURPLE = '#F9FAFF',
  PURPLE_HEART = '#883FE3',

  // Blue
  DARK_BLUE = '#193C77',
  BRIGHT_BLUE = '#5897FF',
  BRIGHT_BLUE_TEXT = '#3E84F5',
  WHITE_BLUE = '#F4F8FF',
  ICE_BLUE = '#F8FAFF',
  BLUE = '#4165A0',
  MIDDLE_BLUE = '#ABCAFF',
  LINES_BLUE = '#D6E4FC',
  BLUE_HOV = '#4273C3',
  BLUE_FOC = '#5188E3',
  BLUE_5 = 'rgba(0, 97, 255, 0.05)',
  BLUE_8 = 'rgba(0, 97, 255, 0.08)',
  BLUE_10 = 'rgba(0, 97, 255, 0.1)',
  BLUE_18 = 'rgba(0, 97, 255, 0,18)',
  BLUE_26 = 'rgba(0, 97, 255, 0,26)',

  // Colors
  RED = '#C43932',
  ORANGE_TEXT = '#E84500',
  ORANGE = '#FF7A00',
  ORANGE_LIGHT = '#FFEDDD',
  GREEN = '#1DB498',
  DODGER_BLUE = '#3E84F5',

  // State Colors
  QUOTE = '#4E2FC8',
  SUBMITTED = GREEN,
  CANCELLED = RED,
  PROCESSING = '#0050C9',
  CONTACT_SALES = '#B825C5',
  COMPLETED = '#0093B4',
  CONFIRMED = '#C582FA',
  IN_PROCESS = '#897EFF',
  TO_DISPATCH = '#4FB9F4',
}

// font-sizes
export const FONT_SIZE_MD = '14px';
export const FONT_SIZE_LG = '18px';
export const FONT_SIZE_TITLE = '28px';
