import React from 'react';

import { Divider, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Business, Email, LocationOn, Person, Phone } from '@material-ui/icons';

import styles from './UserDetails.module.less';

import Spinner from 'components/common/Spinner/Spinner';
import { colors } from 'constants/ui';
import { useAppSelector } from 'store/store';

const useStyles = makeStyles({
  divider: {
    backgroundColor: colors.LIGHT_GREY,
  },
});

export const UserDetails: React.FC = () => {
  const classes = useStyles();
  const { user } = useAppSelector((state) => state.auth);

  return (
    <div className={styles.pageContainer}>
      {!user ? (
        <Spinner />
      ) : (
        <div>
          <Typography variant="h1">Account Details</Typography>
          <Paper className={styles.details}>
            <div className={styles.detailsList}>
              <div className={styles.detailsRow}>
                <div className={`${styles.detailsCell} ${styles.detailsDescription} ${styles.tinyText}`}>
                  <Person className={styles.detailsIcon} />
                  Full name
                </div>
                <div className={`${styles.detailsCell} ${styles.text}`}>{user.first_name}</div>
              </div>
              <Divider className={classes.divider} />
              <div className={styles.detailsRow}>
                <div className={`${styles.detailsCell} ${styles.detailsDescription} ${styles.tinyText}`}>
                  <LocationOn className={styles.detailsIcon} />
                  Address
                </div>
                <div className={`${styles.detailsCell} ${styles.text}`}>{user.address}</div>
              </div>
              <Divider className={classes.divider} />
              <div className={styles.detailsRow}>
                <div className={`${styles.detailsCell} ${styles.detailsDescription} ${styles.tinyText}`}>
                  <Business className={styles.detailsIcon} />
                  Company name
                </div>
                <div className={`${styles.detailsCell} ${styles.text}`}>{user.company_name}</div>
              </div>
              <Divider className={classes.divider} />
              <div className={styles.detailsRow}>
                <div className={`${styles.detailsCell} ${styles.detailsDescription} ${styles.tinyText}`}>
                  <Email className={styles.detailsIcon} />
                  Email
                </div>
                <div className={`${styles.detailsCell} ${styles.text}`}>{user.email}</div>
              </div>
              <Divider className={classes.divider} />
              <div className={styles.detailsRow}>
                <div className={`${styles.detailsCell} ${styles.detailsDescription} ${styles.tinyText}`}>
                  <Phone className={styles.detailsIcon} />
                  Phone number
                </div>
                <div className={`${styles.detailsCell} ${styles.text}`}>{user.phone}</div>
              </div>
            </div>
          </Paper>
        </div>
      )}
    </div>
  );
};
