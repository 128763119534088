interface ICookieOptions extends Record<string, any> {
  expires?: string | Date;
  path?: string;
}

export class CookieService {
  static getCookie(name: string): string | undefined {
    const matches = document.cookie.match(
      new RegExp(`(?:^|; )${name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}=([^;]*)`),
    );
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  static setCookie(name: string, value: string, options: ICookieOptions): void {
    let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

    Object.keys(options).forEach((optionKey) => {
      updatedCookie += `; ${optionKey}`;
      let optionValue = options[optionKey];
      if (optionKey === 'expires') {
        optionValue = new Date(optionValue).toUTCString();
      }
      if (optionValue !== true) {
        updatedCookie += `=${optionValue}`;
      }
    });

    document.cookie = updatedCookie;
  }

  static deleteCookie(name: string): void {
    CookieService.setCookie(name, '', { 'max-age': -1, path: '/' });
  }
}
