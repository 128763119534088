// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AjfO57XAuWu9yWjn6U6o {\n  color: #4165A0;\n  text-decoration: none;\n}\n", "",{"version":3,"sources":["webpack://./src/components/common/NavigationLink/NavigationLink.module.less"],"names":[],"mappings":"AAEA;EACE,cAAA;EACA,qBAAA;AADF","sourcesContent":["@import \"styles/variables.less\";\n\n.link {\n  color: @blue;\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "AjfO57XAuWu9yWjn6U6o"
};
export default ___CSS_LOADER_EXPORT___;
