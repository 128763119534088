import axios, { AxiosError, AxiosRequestConfig } from 'axios';

import { AuthService } from 'services/authService';

const { CancelToken } = axios;
const source = CancelToken.source();

let isRefreshing = false;
let refreshSubscribers: { (newToken: string): void }[] = [];

function subscribeTokenRefresh(callback: (newToken: string) => void) {
  refreshSubscribers.push(callback);
}

function onRefreshed(newToken: string) {
  isRefreshing = false;
  refreshSubscribers = refreshSubscribers.filter((callback) => callback(newToken));
}

export const onAuthRequestInterceptor = async (request: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  const accessToken = AuthService.getAccessToken();
  request.cancelToken = source.token;

  if (accessToken) {
    request.headers.authorization = AuthService.getBearer(accessToken);
  } else {
    try {
      if (!isRefreshing) {
        isRefreshing = true;
        AuthService.refreshTokens()
          .then(() => {
            const newToken = AuthService.getAccessToken();
            onRefreshed(newToken);
          })
          .catch(() => {
            AuthService.deleteAccessToken();
          });
      }
    } catch (e) {
      isRefreshing = false;
      AuthService.deleteAccessToken();
    }

    const retryRequest = new Promise((resolve) => {
      subscribeTokenRefresh((newToken) => {
        request.headers.authorization = AuthService.getBearer(newToken);
        resolve(request);
      });
    });
    return retryRequest;
  }
  return request;
};

export const onAuthErrorRequestInterceptor = (error: AxiosError): Promise<AxiosError> => Promise.reject(error);
