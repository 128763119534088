import React, { FC } from 'react';

import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { withWhiteLabelContext } from 'react-whitelabel';

import { NavigationService } from 'services/navigationService';

const Logo: FC<{ label: any }> = (props) => {
  const { label } = props;
  const { headerLogo } = label;
  const useStyles = makeStyles({
    headerLogo,
  });
  const classes = useStyles();

  return (
    <div>
      <Button classes={{ root: classes.headerLogo }} onClick={() => NavigationService.navigateToMainPage()} />
    </div>
  );
};

export default withWhiteLabelContext(Logo);
