import { LocationState } from 'history';

import { ROUTER_PATHS } from 'constants/routerPaths';
import { history } from 'navigation/history';

export class NavigationService {
  static navigateToSignIn(hard = false): void {
    if (hard) {
      window.location.replace(ROUTER_PATHS.signIn);
    } else {
      history.push(ROUTER_PATHS.signIn);
    }
  }

  static navigateToMainPage(): void {
    history.push(ROUTER_PATHS.shopByProduct);
  }

  static navigateToSuccessAuthPage(path: string, params: LocationState): void {
    history.push(path, params);
  }

  static navigateToShopByProduct(): void {
    history.push(ROUTER_PATHS.shopByProduct);
  }
}
