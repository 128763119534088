import React, { useCallback, useEffect } from 'react';

import {
  AppBar,
  Box,
  Button,
  Chip,
  Dialog,
  Grid,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Typography,
  makeStyles,
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import { Calendar } from '@material-ui/pickers';

import Spinner from 'components/common/Spinner/Spinner';
import { useAppDispatch } from 'store';
import { getDispatchSlots, reserveDispatchSlot } from 'store/actions/dispatch/dispatch';
import { useAppSelector } from 'store/store';
import { DispatchSlot } from 'types/dispatch';
import { extractTime } from 'utils/utils';

const useStyles = makeStyles({
  gridContainer: {
    padding: '12px',
  },
  paperContainer: {
    height: '100%',
    marginLeft: '8px',
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingBottom: '12px',
  },
  tab: {
    minWidth: '130px',
  },
  popup: {
    paddingLeft: '0px',
    paddingRight: '0px',
    maxWidth: '635px',
  },
});

export const SlotSelectButton: React.FC<{
  dispatchId: number;
}> = ({ dispatchId }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [slotDialogOpened, setSlotDialogOpened] = React.useState(false);
  const [slotDate, setSlotDate] = React.useState(new Date());
  const [currentTab, setCurrentTab] = React.useState('on_site');
  const { availableSlots, slotsLoading } = useAppSelector((state) => state.dispatch);
  const [currentSlot, setCurrentSlot] = React.useState(0);

  useEffect(() => {
    if (slotDialogOpened) {
      dispatch(getDispatchSlots({ dispatchId, date: slotDate.toISOString().slice(0, 10) }));
    }
  }, [dispatch, dispatchId, slotDate, slotDialogOpened]);

  useEffect(() => {
    // Reset popup values
    if (!slotDialogOpened) {
      setSlotDate(new Date());
      setCurrentTab('on_site');
      if (currentSlot !== 0) {
        dispatch(reserveDispatchSlot({ dispatchId, slotId: currentSlot }));
        setCurrentSlot(0);
      }
    }
  }, [dispatch, slotDialogOpened]);

  const visibleSlots = availableSlots.filter((x) => x.type === currentTab);

  const slotContent =
    visibleSlots.length !== 0 ? (
      <Box paddingTop="5px">
        {visibleSlots.length !== 0 &&
          visibleSlots.map((slot) => (
            <Chip key={slot.id} onClick={() => setCurrentSlot(slot.id)} label={extractTime(slot.start_datetime)} />
          ))}
      </Box>
    ) : (
      <Box display="flex" height="90%" alignItems="center" textAlign="center">
        <Typography>There are no slots available for this date.</Typography>
      </Box>
    );

  return (
    <>
      <Dialog open={slotDialogOpened} onClose={() => setSlotDialogOpened(false)} classes={{ paper: classes.popup }}>
        <Grid container classes={{ root: classes.gridContainer }}>
          <Grid item xs={6}>
            <Calendar disablePast date={slotDate} onChange={(date) => setSlotDate(date)} />
          </Grid>
          <Grid item xs={6}>
            <Paper elevation={1} classes={{ root: classes.paperContainer }}>
              <Tabs value={currentTab} onChange={(event, value) => setCurrentTab(value)} variant="fullWidth">
                <Tab label="On site" value="on_site" classes={{ root: classes.tab }} />
                <Tab label="Remote" value="remote" classes={{ root: classes.tab }} />
              </Tabs>
              {slotsLoading ? <Spinner /> : slotContent}
            </Paper>
          </Grid>
        </Grid>
      </Dialog>
      <IconButton aria-label="delete" onClick={() => setSlotDialogOpened(true)}>
        <CreateIcon />
      </IconButton>
    </>
  );
};
