import React, { ReactElement } from 'react';

import { ROUTER_PATHS } from 'constants/routerPaths';
import { AuthSuccessPageType } from 'enums/auth';

interface IContextConfig {
  title: string;
  body: string | ReactElement;
  buttonText: string;
  navigateTo: string;
}

interface IPageContextProps {
  type: string;
  email?: string;
}

const successPageContextMapper = (email: string): Record<string, IContextConfig> => ({
  [AuthSuccessPageType.SignUp]: {
    title: 'Your request has been successfully submitted!',
    body: 'Please check your email',
    buttonText: 'Return to login',
    navigateTo: ROUTER_PATHS.signIn,
  },
  [AuthSuccessPageType.ForgotPassword]: {
    title: 'Check your email',
    body: (
      <>
        <p>
          We’ve sent an email to <strong>{email}</strong> with password reset instructions.
        </p>
        <p>If you didn’t find an email, check your spam folder.</p>
      </>
    ),
    buttonText: 'Return to login',
    navigateTo: ROUTER_PATHS.signIn,
  },
  [AuthSuccessPageType.ResetPassword]: {
    title: 'Password is successfully updated!',
    body: '',
    buttonText: 'Go to login',
    navigateTo: ROUTER_PATHS.signIn,
  },
  [AuthSuccessPageType.CreatePassword]: {
    title: 'Password is created',
    body: 'Now you can continue creating your account',
    buttonText: 'Continue',
    navigateTo: '/',
  },
});

export const getSuccessPageContext = ({ type, email = '' }: IPageContextProps): IContextConfig => {
  return successPageContextMapper(email)[type];
};
