import axios from 'axios';

import {
  onAuthErrorRequestInterceptor,
  onAuthErrorResponseInterceptor,
  onAuthRequestInterceptor,
  onAuthResponseInterceptor,
} from './interceptors';

import { API_TIMEOUT } from 'constants/common';

const axiosConfig = {
  baseURL: '/api/v1',
  timeout: API_TIMEOUT,
};

const publicApi = axios.create(axiosConfig);

const privateApi = axios.create(axiosConfig);

privateApi.interceptors.request.use(onAuthRequestInterceptor, onAuthErrorRequestInterceptor);

privateApi.interceptors.response.use(onAuthResponseInterceptor, onAuthErrorResponseInterceptor);

export { privateApi, publicApi };
