import React, { FC, useCallback, useEffect } from 'react';

import { useParams, useRouteMatch } from 'react-router-dom';

import { ExpiredLinkPage } from '../ExpiredLinkPage/ExpiredLinkPage';

import { PasswordConfirmationForm } from 'components';
import { SetPasswordContextMapper } from 'components/auth/PasswordConfirmationForm/helper';
import { NavigationService } from 'services/navigationService';
import { useAppDispatch } from 'store';
import { resetPasswordConfirm, verifyResetToken } from 'store/actions/auth';
import { useAppSelector } from 'store/store';

export const SetPasswordPage: FC = () => {
  const { path } = useRouteMatch();
  const dispatch = useAppDispatch();
  const { title, buttonText, navigateTo, successType } = SetPasswordContextMapper[path];
  const { uid = '', token = '' } = useParams<{ uid: string; token: string }>();
  const [expired, setExpired] = React.useState<boolean>(false);
  const tokenExpired = useAppSelector((state) => state.verifyToken);

  useEffect(() => {
    dispatch(verifyResetToken({ uid, token }));
  }, [dispatch, uid, token]);

  useEffect(() => {
    setExpired(tokenExpired.email === null);
  }, [tokenExpired]);

  const handleFormSubmit = useCallback(
    async (formValues) => {
      const requestData = { ...formValues, uid, token };
      await dispatch(resetPasswordConfirm(requestData));
      NavigationService.navigateToSuccessAuthPage(navigateTo, {
        type: successType,
      });
    },
    [dispatch, uid, token, navigateTo, successType],
  );

  return expired ? (
    <ExpiredLinkPage />
  ) : (
    <PasswordConfirmationForm
      handleFormSubmit={handleFormSubmit}
      buttonText={buttonText}
      title={title}
      email={tokenExpired.email ? tokenExpired.email : ''}
    />
  );
};
