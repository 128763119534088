import { AxiosError, AxiosResponse } from 'axios';

import { HttpStatus } from 'enums/http';
import { AuthService } from 'services/authService';

export const onAuthResponseInterceptor = (response: AxiosResponse): AxiosResponse => response;

export const onAuthErrorResponseInterceptor = (error: AxiosError): Promise<AxiosError> => {
  if (error?.response?.status === HttpStatus.Unauthorized) {
    AuthService.deleteAccessToken();
  }

  return Promise.reject(error);
};
