import { ROUTER_PATHS } from 'constants/routerPaths';
import { AuthSuccessPageType } from 'enums/auth';

export const SetPasswordContextMapper = {
  [ROUTER_PATHS.createPassword]: {
    title: 'Create Password',
    buttonText: 'Create password',
    navigateTo: ROUTER_PATHS.createPasswordSuccess,
    successType: AuthSuccessPageType.CreatePassword,
  },
  [ROUTER_PATHS.resetPassword]: {
    title: 'Reset your Password',
    buttonText: 'Reset password',
    navigateTo: ROUTER_PATHS.resetPasswordSuccess,
    successType: AuthSuccessPageType.ResetPassword,
  },
};
