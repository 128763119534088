import React, { FC } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import { checkFieldError } from './helper';

import { ICommonInputFieldProps } from 'types/inputField';

const useStyles = makeStyles({
  inputRoot: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 500,
  },
  inputRootLabel: {
    fontSize: '12px',
    lineHeight: '20px',
    fontWeight: 500,
  },
});

export const TextFieldInput: FC<ICommonInputFieldProps> = ({
  variant = 'outlined',
  id,
  label,
  placeholder,
  input,
  meta,
}) => {
  const isErrorField = checkFieldError(meta);
  const classes = useStyles();

  return (
    <TextField
      {...input}
      InputProps={{ classes: { root: classes.inputRoot } }}
      InputLabelProps={{ classes: { root: classes.inputRootLabel } }}
      id={id}
      variant={variant}
      placeholder={placeholder}
      label={label}
      error={isErrorField}
      helperText={isErrorField && (meta.error || meta.submitError)}
    />
  );
};
