import React from 'react';

import { SelectField } from 'components/common/controlledComponents/SelectField';
import Placeholder from 'components/common/Placeholder/Placeholder';
import { STATE_TICKET_ANY } from 'constants/support';
import { useAppSelector } from 'store/store';

interface IStateFieldProps {
  value: string;
  onChange: (value: string) => void;
}

export const StateField: React.FC<IStateFieldProps> = ({ value, onChange }) => {
  const { user } = useAppSelector((state) => state.auth);

  const stages = (user && user.helpdesk_stages) || [];
  const stageOptions = [
    { key: STATE_TICKET_ANY, label: 'Any State' },
    ...stages.map((stage) => ({ key: stage[0], label: stage[1] })),
  ];

  return (
    <SelectField
      value={value}
      options={stageOptions}
      onChange={onChange}
      renderValue={value !== STATE_TICKET_ANY ? undefined : () => <Placeholder>Any State</Placeholder>}
    />
  );
};
