import React, { useMemo } from 'react';

import { InputAdornment, Typography, debounce } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { LocationOn } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';

import styles from './AddressAutocompleteField.module.less';

import { productApi } from 'api/productApi';
import { IAddress } from 'types/product';

const useStyles = makeStyles({
  locationIcon: {
    marginRight: '12px',
    color: '#DADADA',
  },
});

interface IAddressAutocompleteFieldProps {
  address: IAddress | null;
  setAddress: (address: IAddress | null) => void;
}

export const LocationOnIcon = (
  <InputAdornment position="start">
    <LocationOn />
  </InputAdornment>
);

export const AddressAutocompleteField: React.FC<IAddressAutocompleteFieldProps> = ({ address, setAddress }) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = React.useState('');
  const [suggested, setSuggested] = React.useState<IAddress[]>([]);

  const getOptionsDelayed = useMemo(
    () =>
      debounce((text: string) => {
        productApi.searchAddress(text).then(({ data }) => {
          setSuggested(data);
        });
      }, 500),
    [],
  );

  React.useEffect(() => {
    if (inputValue.length < 3) {
      setSuggested(address ? [address] : []);
    } else if (!(address && address.complete_address === inputValue)) {
      getOptionsDelayed(inputValue);
    }
  }, [inputValue, address, getOptionsDelayed]);

  return (
    <Autocomplete
      autoComplete
      getOptionLabel={(option: IAddress) => option.complete_address}
      getOptionSelected={(option: IAddress, val: IAddress) =>
        val ? option.complete_address === val.complete_address : false
      }
      value={address}
      onChange={(event: any, newValue: any) => {
        setSuggested(newValue ? [newValue, ...suggested] : suggested);
        setAddress(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => {
        // eslint-disable-next-line no-param-reassign
        params.InputProps.startAdornment = LocationOnIcon;
        return <TextField {...params} label="Location" variant="outlined" />;
      }}
      options={suggested}
      renderOption={(option: IAddress) => (
        <div className={styles.suggestion}>
          <LocationOn classes={{ root: classes.locationIcon }} />
          <Typography>{option.complete_address}</Typography>
        </div>
      )}
    />
  );
};
