// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wRU9HRvPrqbsFklfTsky {\n  margin-top: 24px;\n}\n._VsKlu5QEHA8YRCxJ1Sl:not(:last-child) {\n  margin-bottom: 24px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/shop/ShoppingWizard/ServiceParameters/ServiceParameters.module.less"],"names":[],"mappings":"AACA;EACE,gBAAA;AAAF;AAGA;EACE,mBAAA;AADF","sourcesContent":["// Should be 32px, but StemContent margin-top: 8px\n.container {\n  margin-top: 24px;\n}\n\n.attribute:not(:last-child) {\n  margin-bottom: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "wRU9HRvPrqbsFklfTsky",
	"attribute": "_VsKlu5QEHA8YRCxJ1Sl"
};
export default ___CSS_LOADER_EXPORT___;
