// regExp
export const PASSWORD_REGEXP =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!@#$%^ &*_+=(){}|/\\?.',:";`-])[A-Za-z\d~!@#$%^ &*_+=(){}|/\\?.',:";`-]{8,}$/s;
export const PHONE_NUMBER_REGEXP = /^(\+1)(\d{10})$/i;
export const ONLY_LETTERS_REGEXP = /^[A-Za-z ]+$/i;
export const ONLY_DIGITS_REGEXP = /^\d+$/i;

// length
export const TEXT_FIELD_NORMAL_MAX_LENGTH = 50;
export const TEXT_FIELD_HUGE_MAX_LENGTH = 250;
export const CHARACTERS_MIN_LENGTH = 3;
