import { AxiosResponse } from 'axios';

import { privateApi } from 'api/api';
import { DispatchResponse, DispatchSlot, DispatchType } from 'types/dispatch';

const DispatchApi = {
  getDispatches: (): Promise<AxiosResponse<DispatchResponse>> => {
    return privateApi.get(`/dispatch`);
  },
  getDispatchSlots: (dispatchId: number, date: string): Promise<AxiosResponse<DispatchSlot[]>> =>
    privateApi.get(`/dispatch/${dispatchId}`, { params: { date } }),

  reserveSlot: (dispatchId: number, slotId: number): Promise<AxiosResponse<DispatchType>> =>
    privateApi.post(`/dispatch/${dispatchId}/slot/${slotId}/reserve`),
};

export default DispatchApi;
