import React from 'react';

import { Box, Dialog, DialogContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  popupWindow: {
    width: '80%',
    height: '80%',
    maxWidth: '80%',
  },
  iframeBlock: {
    width: '100%',
    height: '99%',
  },
});

interface IPopupFormProps {
  url: string;
  opened: boolean;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
  actionAfterClosing?: () => void;
}

export const PopupForm: React.FC<IPopupFormProps> = ({ url, opened, setOpened, actionAfterClosing }) => {
  const classes = useStyles();

  return (
    <Dialog
      PaperProps={{ classes: { root: classes.popupWindow } }}
      open={opened}
      onClose={() => {
        setOpened(false);
        if (actionAfterClosing) actionAfterClosing();
      }}
    >
      <DialogContent>
        <Box position="absolute" top="5px" bottom="5px" left="5px" right="5px">
          <iframe width="100%" height="100%" src={url} title="Popup" />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
