export const COLUMN_TICKET_ID = 'id';
export const COLUMN_TICKET_TYPE = 'ticket_type_name';
export const COLUMN_TICKET_SUBJECT = 'subject';
export const COLUMN_TICKET_ADDRESS = 'service_address';
export const COLUMN_TICKET_COMPANY_NAME = 'company_name';
export const COLUMN_TICKET_STATUS = 'stage_id';
export const COLUMN_TICKET_REPORTED_BY = 'partner_id';
export const COLUMN_TICKET_REPORTED_ON = 'create_date';

export const PERIOD_TICKET_ANY = 'any';
export const PERIOD_TICKET_WEEK = 'week';
export const PERIOD_TICKET_TWO_WEEKS = 'two_weeks';
export const PERIOD_TICKET_CUSTOM = 'custom';

export const PERIOD_TICKET_FILTERS = [
  { key: PERIOD_TICKET_ANY, label: 'Any Period' },
  { key: PERIOD_TICKET_WEEK, label: 'Last week' },
  { key: PERIOD_TICKET_TWO_WEEKS, label: 'Last two weeks' },
  { key: PERIOD_TICKET_CUSTOM, label: 'Custom range...' },
];

export const STATE_TICKET_ANY = 'any';
