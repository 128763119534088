// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".v84KmsR9hTTF1QpfrX4Q {\n  margin-top: 24px;\n}\n.N6OBNf0vkiZiHGrbJkb4 {\n  margin-right: 16px;\n  width: 586px;\n  display: inline-block;\n}\n.vpKYL80Ih62_07sEP1tv {\n  width: 124px;\n  margin-right: 16px;\n  display: inline-block;\n}\n.Fwpx5O5TSIljIF_jcWC0 {\n  display: inline-block;\n}\n", "",{"version":3,"sources":["webpack://./src/components/shop/ShoppingWizard/ServiceLocation/ServiceLocation.module.less"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AAEA;EACE,kBAAA;EACA,YAAA;EACA,qBAAA;AAAF;AAGA;EACE,YAAA;EACA,kBAAA;EACA,qBAAA;AADF;AAIA;EACE,qBAAA;AAFF","sourcesContent":[".container {\n  margin-top: 24px;\n}\n\n.locationInput {\n  margin-right: 16px;\n  width: 586px;\n  display: inline-block;\n}\n\n.quantityInput {\n  width: 124px;\n  margin-right: 16px;\n  display: inline-block;\n}\n\n.deleteIcon {\n  display: inline-block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "v84KmsR9hTTF1QpfrX4Q",
	"locationInput": "N6OBNf0vkiZiHGrbJkb4",
	"quantityInput": "vpKYL80Ih62_07sEP1tv",
	"deleteIcon": "Fwpx5O5TSIljIF_jcWC0"
};
export default ___CSS_LOADER_EXPORT___;
