// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HEoHOSImdtUrrjyTHKdE {\n  padding: 60px 142px;\n  z-index: 2;\n}\n.JvCDBx23N6CdWmXmEzz5 {\n  font-weight: 700;\n  font-size: 18px;\n  line-height: 28px;\n  margin-bottom: 20px;\n  color: #282827;\n}\n.dDakzkQjs1S0n3xIDdjw:not(:last-child) {\n  margin-bottom: 12px;\n}\n.YhQj8GlDKM8k0mwCzrg0 {\n  font-weight: 500;\n  font-size: 14px;\n  line-height: 24px;\n  color: #282827;\n}\n", "",{"version":3,"sources":["webpack://./src/components/common/MegaMenu/MegaMenu.module.less"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,UAAA;AACF;AAEA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;EACA,cAAA;AAAF;AAGA;EACE,mBAAA;AADF;AAIA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAFF","sourcesContent":[".mainCategories {\n  padding: 60px 142px;\n  z-index: 2;\n}\n\n.categoryTitle {\n  font-weight: 700;\n  font-size: 18px;\n  line-height: 28px;\n  margin-bottom: 20px;\n  color: #282827;\n}\n\n.categoryName:not(:last-child) {\n  margin-bottom: 12px;\n}\n\n.categoryLink {\n  font-weight: 500;\n  font-size: 14px;\n  line-height: 24px;\n  color: #282827;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainCategories": "HEoHOSImdtUrrjyTHKdE",
	"categoryTitle": "JvCDBx23N6CdWmXmEzz5",
	"categoryName": "dDakzkQjs1S0n3xIDdjw",
	"categoryLink": "YhQj8GlDKM8k0mwCzrg0"
};
export default ___CSS_LOADER_EXPORT___;
