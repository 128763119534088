import React from 'react';

import {
  COLUMN_ORDER_COMPANY_NAME,
  COLUMN_ORDER_CREATED_BY,
  COLUMN_ORDER_CREATE_DATE,
  COLUMN_ORDER_NAME,
  COLUMN_ORDER_STATUS,
  COLUMN_ORDER_SUM,
  COLUMN_ORDER_UPDATE_DATE,
} from './order';

import { OrderStateIndicator } from 'components';
import { IBulkOrder } from 'types/order';
import { ITableColumn } from 'types/table';
import { getDate } from 'utils/utils';

export const TABLE_COLUMNS_ORDER: ITableColumn<IBulkOrder>[] = [
  { displayName: 'Item #', field: COLUMN_ORDER_NAME, align: 'left' },
  {
    displayName: 'Sum',
    field: COLUMN_ORDER_SUM,
    align: 'right',
    render: (item) => (item.price_total === 0 ? '─' : `$ ${item.price_total}`),
  },
  {
    displayName: 'Created',
    field: COLUMN_ORDER_CREATE_DATE,
    align: 'left',
    isSortable: true,
    render: (item) => <>{getDate(item.create_date, false)}</>,
  },
  {
    displayName: 'Updated',
    field: COLUMN_ORDER_UPDATE_DATE,
    align: 'left',
    isSortable: true,
    render: (item) => <>{getDate(item.write_date, false)}</>,
  },
  { displayName: 'Company', field: COLUMN_ORDER_COMPANY_NAME, align: 'left' },
  { displayName: 'Created by', field: COLUMN_ORDER_CREATED_BY, align: 'left', isSortable: true },
  {
    displayName: 'Status',
    field: COLUMN_ORDER_STATUS,
    align: 'left',
    isSortable: true,
    render: (item) => <OrderStateIndicator state={item.state} />,
  },
];
