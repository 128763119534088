import React from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { withWhiteLabelContext } from 'react-whitelabel';

const useStyles = makeStyles({
  dialogQuestionText: {
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '28px',
    marginBottom: '12px',
  },
  dialogMessageText: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
  },
  dialogTitle: {
    padding: '28px 28px 12px 28px',
  },
  dialogContent: {
    padding: '0px 28px',
    marginBottom: '36px',
  },
  dialogActions: {
    padding: '0px 28px',
    marginBottom: '28px',
  },
});

interface IPageLeaveDialogProps {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  label: any;
}

const PageLeaveDialog: React.FC<IPageLeaveDialogProps> = ({ open, onConfirm, onCancel, label }) => {
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle className={classes.dialogTitle}>
        <img src={label.helpDialogLogo} alt="" />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.dialogQuestionText}>Do you want to leave this page?</div>
        <div className={classes.dialogMessageText}>All the information you filled in will not be saved.</div>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button variant="contained" onClick={onCancel} color="secondary">
          Stay here
        </Button>
        <Button variant="contained" onClick={onConfirm} color="primary">
          Leave
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withWhiteLabelContext(PageLeaveDialog);
