import React, { useCallback } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import styles from './OrderRowActions.module.less';

import logoOrderCancel from 'assets/svg/warning_dialog_title.svg';
import { ORDER_STATE_QUOTE } from 'constants/order';
import { colors } from 'constants/ui';
import { useAppDispatch } from 'store';
import { cancelOrder, getOrders } from 'store/actions/order/order';
import { useAppSelector } from 'store/store';
import { IBulkOrder } from 'types/order';

const useStyles = makeStyles({
  cancelButton: {
    background: 'rgba(196, 57, 50, 0.1)',
    color: colors.RED,
  },
  confirmCancelButton: {
    background: colors.RED,
    color: colors.WHITE,
  },
  submitButton: {
    background: 'rgba(29, 180, 152, 0.1)',
    color: colors.GREEN,
    marginRight: '8px',
  },
});

interface IOrderRowActionsProps {
  item: IBulkOrder;
}

const OrderRowActions: React.FC<IOrderRowActionsProps> = ({ item }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [openedCloseDialog, setOpenedCloseDialog] = React.useState(false);
  const { filters, pagination, sort } = useAppSelector((state) => state.order);

  const confirmCancelOrder = useCallback(
    async (id: number) => {
      await dispatch(cancelOrder(id.toString()));
      setOpenedCloseDialog(false);
      dispatch(getOrders({ filters, pagination, sort }));
    },
    [dispatch, filters, pagination, sort],
  );

  const openAcceptAndSign = (id: number) => {
    history.push(`/order/${id}/accept/sign`);
  };

  return (
    <Box display="flex">
      {item.state === ORDER_STATE_QUOTE && (
        <Box display="flex" marginLeft={1}>
          <Button
            className={classes.submitButton}
            variant="contained"
            color="secondary"
            onClick={() => openAcceptAndSign(item.id)}
          >
            <span className={styles.textMedium}>Submit</span>
          </Button>
          <Button
            className={classes.cancelButton}
            variant="contained"
            color="secondary"
            onClick={() => setOpenedCloseDialog(true)}
          >
            <span className={styles.textMedium}>Cancel</span>
          </Button>
          <Dialog
            open={openedCloseDialog}
            onClose={() => setOpenedCloseDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              <img src={logoOrderCancel} alt="" />
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Typography>
                  <span className={styles.largeTextBold}>Cancel order?</span>
                </Typography>
                <Typography>
                  <span className={styles.text}>Order {item.name} will be cancelled.</span>
                </Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenedCloseDialog(false)} color="primary" autoFocus>
                Keep order
              </Button>
              <Button
                className={classes.confirmCancelButton}
                onClick={() => confirmCancelOrder(item.id)}
                color="primary"
              >
                Cancel order
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      )}
    </Box>
  );
};

export default OrderRowActions;
