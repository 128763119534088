// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fMjsZoflgTR8fR7gkfWi {\n  padding-top: 90px;\n  margin-left: 120px;\n  margin-right: 120px;\n}\n.HD4VPfA9AjDPwDMLJIF3 {\n  margin-top: 48px;\n}\n.hEUS0KddxXy4thdguFfO {\n  margin-top: 24px;\n  margin-bottom: 80px;\n}\n.H2mv2WczUVJ2SWHlsLy2 {\n  z-index: 1;\n  margin-bottom: 32px !important;\n}\n.p1a1Chk1sCuss3kkXgbg {\n  padding: 0 !important;\n  font-style: normal;\n  font-weight: 500;\n  font-size: 14px;\n  line-height: 24px;\n  color: #584E9D !important;\n}\n.p1a1Chk1sCuss3kkXgbg:hover {\n  font-weight: bold;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/shop/CategoryPage/CategoryPage.module.less"],"names":[],"mappings":"AAEA;EACE,iBAAA;EACA,kBAAA;EACA,mBAAA;AADF;AAIA;EACE,gBAAA;AAFF;AAKA;EACE,gBAAA;EACA,mBAAA;AAHF;AAMA;EACE,UAAA;EACA,8BAAA;AAJF;AAOA;EACE,qBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,yBAAA;AALF;AAQA;EACE,iBAAA;AANF","sourcesContent":["@import \"~styles/variables.less\";\n\n.pageContainer {\n  padding-top: 90px;\n  margin-left: 120px;\n  margin-right: 120px;\n}\n\n.categoryWrapper {\n  margin-top: 48px;\n}\n\n.productsWrapper {\n  margin-top: 24px;\n  margin-bottom: 80px;\n}\n\n.breadCrumbs {\n  z-index: 1;\n  margin-bottom: 32px !important;\n}\n\n.breadCrumb {\n  padding: 0 !important;\n  font-style: normal;\n  font-weight: 500;\n  font-size: 14px;\n  line-height: 24px;\n  color: #584E9D !important;\n}\n\n.breadCrumb:hover {\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageContainer": "fMjsZoflgTR8fR7gkfWi",
	"categoryWrapper": "HD4VPfA9AjDPwDMLJIF3",
	"productsWrapper": "hEUS0KddxXy4thdguFfO",
	"breadCrumbs": "H2mv2WczUVJ2SWHlsLy2",
	"breadCrumb": "p1a1Chk1sCuss3kkXgbg"
};
export default ___CSS_LOADER_EXPORT___;
