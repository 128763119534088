import { ValidationErrors } from 'final-form';

import { PASSWORD_REGEXP } from './constants';

import { IPasswordForm } from 'types/auth';

export const passwordValidator =
  (email: string | undefined) =>
  (value: string | undefined): string | undefined => {
    let error;
    if (value && !PASSWORD_REGEXP.test(value)) error = 'Please enter a valid password';
    if (value.toLowerCase() === email.toLowerCase()) error = 'Password must not be equal to email';

    return error;
  };

export const validatePasswordsForm = (values: IPasswordForm): ValidationErrors | undefined => {
  const { new_password1: password1, new_password2: password2 } = values;
  if (!password1 || !password2 || password1 === password2) {
    return undefined;
  }
  return { new_password2: 'Passwords must be equal' };
};
